import { TimerRecordsService } from './../../../crm/service/timer-records.service';
import {AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild, HostListener, ViewChildren, QueryList} from '@angular/core';
import {LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AppComponent } from '../../../app.component';
import { CrmClient } from '../../../crm/model/base';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { UtilsService } from '../../../shared/service/utils.service';
import { NoteHistoryService } from '../../../crm/service/note-history.service';
import { ClientService } from '../../../crm/service/client.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeEventService } from '../../../crm/service/employee-event.service';
import { EventService } from '../../../crm/service/event.service';
import { NoteHistory } from '../../../crm/model/note-history';
import { EmployeePerformanceService } from '../../../employee/performance/service/employee-performance.service';
import { AllMenusService } from '../../service/all-menus/all-menus.service';
import { PopupService } from '../../service/popup.service';
import { EmployeeClockingService } from '../../../employee-clocking/service/employee-clocking.service';
import * as _ from 'lodash';
import {Constants, DOCUMENT_DETAILS, getDefaultField} from '../../../shared/model/constants';
import { EmployeeTimeLeftToClockOut } from '../../../employee/model/employee-time-left-to-clock-out.model';
import { AdminAuthService } from '../../service/calllog/admin-auth.service';
import { AwayActivity } from '../../../shared/model/away-activity.model';
import { AbsenceService } from '../../../employee/absence/service/absence.service';
import { CompanySettingServiceV2 } from '../../../company/service/company-setting.v2.service';
import { CompanyService } from '../../../company/service/company.service';
import { OvertimeConfirmationService } from '../../../employee/service/overtime-confirmation.service';
import { ToDo } from '../../../todo/model/todo.model';
import { TodoFormValuesConstant } from '../../../todo/utility/todo-form-values.constant';
import { Editor } from 'primeng/editor';
import { ToDoNotes } from '../../../todo/model/todonotes.model';
import { ToDoNotesService } from '../../../todo/service/todonotes.service';
import { ToDoService } from '../../../todo/service/todo.service';
import * as moment from 'moment-timezone';
import { Employee } from '../../../employee/model/employee.model';
import { EmployeeService } from '../../../employee/service/employee.service';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { PayrollSetting } from '../../../payroll-setting/model/payroll-setting.model';
import {ReplaySubject, of, Subscription, forkJoin, Observable} from 'rxjs';
import { PlatformAdminService } from 'app/admin/platform-admin/service/platform-admin.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { AgencyAdminService } from 'app/admin/agency-admin/service/agency-admin.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import { DeadlineReminderService } from 'app/lettermail/service/deadline-reminder.service';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { MembershipService } from 'app/membership/service/membership.service';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { CounterDirective } from 'app/shared/directive/counter.directive';
import { CounterIncreaseDirective } from 'app/shared/directive/counter-increase.directive';
import * as RecordRTC from 'recordrtc';
import { EmployeePerformance } from 'app/employee/performance/model/employee-performance';
import { PerformanceService } from 'app/employee/performance/service/performance.service';
import { SurveySetupService } from 'app/employee/performance/service/survey-setup.service';
import { HttpClient } from '@angular/common/http';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { debounceTime } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { NotificationService } from 'app/notification/service/notification.service';
import { PaymentTransactionService } from 'app/payment/service/payment-transaction.service';
import { FirebaseNotificationService } from 'app/shared/service/firebase-notification.service';
import { RoleLevel } from '../app-menu/role-level';
import { EmployeeClockingServiceV2 } from 'app/employee-clocking/service/v2/employee.clocking.v2.service';
import { EmployeeJobReportService } from 'app/employee/service/employee-job-report.service';
import { PaymentProfileService } from '../../../payment/service/payment-profile.service';
import { PaymentCardDefaultService } from '../../../payment/service/payment-card-default.service';
import { WalletTransactionService } from 'app/payment/service/wallet-transaction.service';
import { MaintenanceService } from 'app/maintenance/service/maintenance.service';
import {ComplianceDocumentService} from '../../../shared/service/compliance-document/compliance-document.service';
import {DocumentSettingsService} from '../../../company/service/document-settings.service';
import {Time} from '../../../employee/model/time.model';
import * as MOMENT from 'moment';
import {CompanyV2} from '../../../company/model/v2/company.model';
import {OverlayPanel} from 'primeng';
import {DocumentsServicev2} from '../../../shared/service/documents-upload/document.v2.service';
import { AvatarService } from 'app/shared/service/avatar.service';
import {EvvRegisterdService} from '../../../crm/service/evv-registered.service';
import { CounterTimerRecordDirective } from 'app/shared/directive/counter-timer-records.directive';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';

interface ComplianceColumn {
    field: string;
    value: string;
    label: string;
    show: boolean;
    sortOptions: string;
    sort: string;
    disabled: boolean;
    group?: string;
}
declare let $: any;

@Component({
    selector: 'app-topbar',
    templateUrl: './app-topbar.component.html',
    styleUrls: ['./app-topbar.component.scss'],
    providers: [EmployeeClockingService, CompanySettingServiceV2, OperatorService,
        FreelancerService, ClientService, PlatformAdminService,
        CounterDirective, CounterIncreaseDirective, MembershipService, EmployeeClockingServiceV2, CounterTimerRecordDirective, TimerRecordsService]
})
export class AppTopbarComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(public allMenus: AllMenusService, public app: AppComponent,
        private messageService: MessageService,
        private _http: HttpClient,
        private router: Router,
        private cookieService: CookieService,
        private authService: AuthService,
        private utilsService: UtilsService,
        private membershipService: MembershipService,
        private clientService: ClientService,
        private employeeEventService: EmployeeEventService,
        private eventService: EventService,
        private absenceService: AbsenceService,
        private noteHistoryService: NoteHistoryService,
        private popupService: PopupService,
        private operatorService: OperatorService,
        private employeePerformanceService: EmployeePerformanceService,
        private employeeClockingService: EmployeeClockingService,
        private adminAuthService: AdminAuthService,
        private deadlineReminderService: DeadlineReminderService,
        private browserNotificationService: BrowserNotificationService,
        private toDoNotesService: ToDoNotesService,
        private todoService: ToDoService,
        private paymentInvoiceService: PaymentInvoiceService,
        private employeeService: EmployeeService,
        private payrollSettingService: PayrollSettingService,
        private overtimeConfirmationService: OvertimeConfirmationService,
        private platformAdminService: PlatformAdminService,
        private agencyAdminService: AgencyAdminService,
        private companySettingServiceV2: CompanySettingServiceV2,
        private freelancerService: FreelancerService,
        private companyService: CompanyService,
        private documentService: DocumentsService,
        private counterDirective: CounterDirective,
        private counterIncreaseDirective: CounterIncreaseDirective,
        private translate: TranslateService,
        private injector: Injector,
        private taskManagementService: TaskManagementService,
        private performanceService: PerformanceService,
        private surveySetupService: SurveySetupService,
        private db: AngularFireDatabase,
        private notificationService: NotificationService,
        private paymentTransactionService: PaymentTransactionService,
        private firebaseNotificationService: FirebaseNotificationService,
        private employeeClockingServiceV2: EmployeeClockingServiceV2,
        private employeeJobReportService: EmployeeJobReportService,
        private contentService: FreelancerService,
        private paymentProfileService: PaymentProfileService,
        private paymentCardDefaultService: PaymentCardDefaultService,
        private walletTransactionService: WalletTransactionService,
        private maintainService: MaintenanceService,
        private complianceDocumentService: ComplianceDocumentService,
        private documentSettingsService: DocumentSettingsService,
        private datePipe: DatePipe,
        private translatePipe: TranslatePipe,
        private documentServicev2: DocumentsServicev2,
        private http: HttpClient,
        private avatarService: AvatarService,
        private evvRegisterdService: EvvRegisterdService,
        private timerRecordsService: TimerRecordsService,
        private counterTimerRecordDirective: CounterTimerRecordDirective,
        private monitoringDetailsService: MonitoringDetailsService
    ) {

        this.divWidth = window.innerWidth * 30 / 100;
        this.signaturePadConfirmConfig = {
            'minWidth': 1,
            'canvasWidth': this.divWidth,
            'canvasHeight': 225,
            'canvasBorder': 'none',
            'penColor': '#4a87f3'
        };

        this.username = this.authService.getUserInfo().username;
        this.userInfo = this.authService.getUserInfo();
        // this.photoURL = this.authService.getUserInfo().profilePhoto? this.authService.getUserInfo().profilePhoto:'assets/images/logo.png';
        this.currentLoggedInUserId = this.authService.getCurrentLoggedInId();
        this.role = this.authService.getRoleLevel();
        this.changeImageProfile();
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        // this.avatarService.changeAvatarObservable.subscribe((event: any) => {
        //     console.log('employee-avatar event', event);
        //     if (event) {
        //         this.photoURL = event;
        //     }
        // });
    }
    username: string;
    userInfo;
    photoURL: string;
    transferNotifications = [];
    notificationCount = 0;
    noteNotificationCount = 0;
    overttimeNotificationCount = 0;
    notificationText = [];
    newAssigneeName: string;
    filteredNotifications = [];
    duplicatesUniqueKeyArr = [];
    notiticationModel = [];
    multipleNotification = false;
    notificationCountBackgroundChange = false;
    allNotificationOfEventType;
    showPopUp = false;
    currentLoggedInUserId: number;
    popupList: any = [];
    overtimeAlerts: any = [];
    searchSelected: any;
    clientList: any = [];
    role = 0;
    checkBoxLabel: any;
    isCheckBox = false;
    count: any;
    maintain = true;
    textMaintain = '';
    contentList: any[] = [];

    isPunchInOut = false;
    timeLeftToClockOut: EmployeeTimeLeftToClockOut = new EmployeeTimeLeftToClockOut(false, -1, -1, '', false);
    shouldDisplayClockOutPopup = false;
    missingCount = 0;
    missingInOutCount = 0;
    isTimeToDisplayClock = false;
    showVolume = false;
    muteVolume = false;
    showSearchDialog = false;
    countIncreaseTimeLeft = -1;
    countTimeLeftClock = -1;
    countIncrease: any;
    menuList: any[];
    filteredMenuList: any[];
    filteredClientList: any[];
    systemSearchList: any[] = [];
    invoiceNumberFilterList: any[] = [];
    textSearch: string;
    loggedInUserMenus = [];
    selectedCompany: any;
    selectedAgency: any;
    dataChange: ReplaySubject<any>;
    agency: any;
    disable: any;
    awayFromDesk = false;
    awayOption = '';
    awayTime = 0;
    awayStarted = false;
    awayCountingInterval;
    awayOther = '';
    activityId = 0;
    isEmployee = false;
    showOkBtn = false;
    checkAgreement = false;
    disableSetting = false;
    numberChatMsgNotification = 0;
    overtimeAlert = false;
    showTakeOTBack = false;
    overtimeConfirmationAlert = false;
    isBillingDepartment = false;
    confirmationStep = 0;
    confirmation: any = {};
    popupClosable = true;
    overtimeConfirmed = false;
    clientNameUpload: any;
    interval = null;
    interval1 = null;
    reminderInterval = null;
    reminderInterval1 = null;
    overtimeConfirmationInterval = null;
    overtimeDate = '';
    isOpenClockingReminder = false;

    showPopupTotalJobUpload = false;
    eventUploadTotalJob: any = {};
    showPopupBillingReport = false;
    eventUploadBillingReport: any = {};
    showPopupBillingDetailReport = false;
    eventUploadBillingDetailReport: any = {};
    showPopupCorrectionReport = false;
    eventUploadCorrectionReport: any = {};
    showPopupLogTotalJob = false;
    eventUploadLogTotalJob: any = {};
    showPopupLogDetailReport = false;
    eventUploadLogDetailReport: any = {};

    reminderMessage = '';
    confirmationError = '';
    otIncreaseTime = '';
    show = true;
    todo: ToDo;
    toDoNotes: ToDoNotes = new ToDoNotes();
    regardings: any[];
    subjectSearchResults: any[];
    exceededMaxLength = false;
    exceededMinLength = false;
    charactersNumber = 0;
    concernMsgs = [];
    @ViewChild(Editor)
    detailsEditor: Editor;
    editor: any;
    showTakeOT = true;
    MAX_LENGTH = 1501;
    payrollSetting: PayrollSetting;
    overtimeConfirmation: any = {};
    isShowSixHoursMessage: Boolean = false;
    isTimeToDisplayClockIncrease = false;
    showOTTimer: Boolean = true;
    activeLanguage;
    languages = [
        { label: 'language_english', value: 'en', flag: 'gb' },
        { label: 'language_vietnam', value: 'vi', flag: 'vn' },
        { label: 'language_chinese_simplified', value: 'zh', flag: 'cn' },
        { label: 'language_chinese_traditional', value: 'zh2', flag: 'cn' },
        { label: 'language_spanish', value: 'es', flag: 'es' },
        { label: 'language_france', value: 'fr', flag: 'fr' },
        { label: 'language_arabic', value: 'ar', flag: 'ar' },
        { label: 'language_japanese', value: 'jp', flag: 'jp' },
        { label: 'language_korean', value: 'ko', flag: 'kr' },
        { label: 'language_russia', value: 'ru', flag: 'ru' },
        { label: 'language_hindi', value: 'hi', flag: 'in' }
    ];
    showEventsPopUp = false;
    showDeadlinesPopUp = false;
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    events: any = {};
    showAgreementDialog = false;
    showPlanAssignmentDialog = false;
    employeeFullName = '';
    todoId = 0;
    deadlineReminder: any = {};
    showPhone = false;
    showHourGlass = true;
    showEmail = true;
    tzone: any;
    timeZone: any = {};
    isGuess = false;
    taggedNotificationCount = 0;
    taggedNotificationText: any[];
    @ViewChild('video') video: any;
    private stream: MediaStream;
    private recordRTC: any;
    isRecording = false;
    showSurvey = false;
    employeePerformances: any;
    rowGroupMetadata: {};
    employee: any;
    selectedSurveySetup: any;
    showCountDown = false;
    timeLeft: number;
    displayTime: string;
    limitInterval: NodeJS.Timeout;
    countInterval: NodeJS.Timeout;
    timeLimit: number;
    showLimit: boolean;
    isVisible = false;
    dashBoardForRole = {};
    auto = false;
    isClientAccount: Boolean = false;
    contactLink = false;
    isChildAccount: boolean;
    isRefresh = false;
    showComplianceCheckPopUp = false;
    listItems = [{value: 'companyInfo', id : 1}, {value: 'employeeInfo', id: 2}];
    companyInfoPage = true;
    shortcuts = [
        { label: 'Dashboard', value: 1, link: '/app/dashboard', icon: 'fa-solid fa-table-columns text-primary mr-2'},
        { label: 'Platform Admins', value: 2, link: '/app/admin/platform-admin/list', icon: 'fa-solid fa-shield text-primary mr-2' },
        { label: 'Admins', value: 3, link: '/app/admin/agency-admin/list', icon: 'fa-solid fa-shield text-primary mr-2' },
        { label: 'Employees', value: 4, link: '/app/employee', icon: 'fa-solid fa-user-circle text-primary mr-2' },
        { label: 'Companies', value: 5, link: '/app/company/list', icon: 'fa-solid fa-building text-primary mr-2' },
        { label: 'Time-Off Requests', value: 6, link: '/app/absence/list', icon: 'fa-solid fa-calendar text-primary mr-2' },
        { label: 'Total Jobs', value: 7, link: '/app/job-follow-up/billing-total-job', icon: 'fa-solid fa-up-right-from-square text-primary mr-2' },
        { label: 'CRM', value: 8, link: '/app/crm/client/list', icon: 'fa-solid fa-handshake text-primary mr-2' },
        { label: 'Client Invoices', value: 9, link: '/app/payments/invoice', icon: 'fa-solid fa-dollar text-primary mr-2' },
        { label: 'Member Profiles', value: 10, link: '/app/membership/list', icon: 'fa-solid fa-contact-book text-primary mr-2' },
        { label: 'Guests', value: 11, link: '/app/guest/list', icon: 'fa-solid fa-contact-book text-primary mr-2' },
        { label: 'Employee Payment', value: 12, link: '/app/employee/payment', icon: 'fa-solid fa-piggy-bank text-primary mr-2' },
        { label: 'Payment Methods', value: 13, link: '/app/payment-method/list', icon: 'fa-solid fa-wallet text-primary mr-2' },
        { label: 'Departments', value: 14, link: '/app/department/list', icon: 'fa-solid fa-building-user text-primary mr-2' },
        { label: 'Billing', value: 15, link: '/app/billings/list', icon: 'fa-solid fa-file-invoice-dollar text-primary mr-2' },
        { label: 'Punch History', value: 16, link: '/app/employee-clocking/punch-history', icon: 'fa-solid fa-clock-rotate-left text-primary mr-2' },
        { label: 'Process Monitor', value: 17, link: '/app/admin/process-monitor', icon: 'fa-solid fa-desktop text-primary mr-2' },
        { label: 'Job Reports', value: 18, link: '/app/employee/employee-job-report/list', icon: 'fa-solid fa-file-export text-primary mr-2' },
        { label: 'Billing Log', value: 19, link: '/app/job-follow-up/list', icon: 'fa-solid fa-file-lines text-primary mr-2' },
        { label: 'Compliance Check', value: 20, link: ';javascript', icon: 'fa-solid fa-check-to-slot text-primary mr-2' },
        { label: 'Time Recording Timer', value: 21, link: '', icon: 'fa-solid fa-stopwatch text-primary mr-2', role: 'ADMIN,CLIENT,EMPLOYEE' }
    ];
    shortArray: any = [];
    isAdminAccnt: boolean;
    showShortArray: boolean = false;

    pendingTransaction = [];
    newPaymentTransaction = [];
    newPaymentLastSeenTime;
    newPaymentLastSeenTimeLoaded = false;
    newNotificationMessage = [];
    totalNewNotificationMessage = 0;

    subscriptions: Subscription = new Subscription();

    showPopupVideoRecording;
    videoRecordingUrl;
    totalNewVoterAssigned = 0;
    showJobReportPopupReminder;
    diffHours = 1;
    clientListData = [];
    empClockingStatus: any = 'OFF';

    @ViewChild('buttonConfirmJobReportPopupReminder') buttonConfirmJobReportPopupReminder: ElementRef;
    updateExpiredCardModal = false;

    accountId;
    accountBalance: any;
    customerBank: any;
    customerCard: any;
    defaultMethod: any;
    selectedMethod: any;
    selectedAmount;
    selectedFeeAmount;
    addAmountSummiting = false;
    changeMethodModal = false;
    changeMethodSelected;
    transferFeeCashOut;
    addAmountObj: any = {};
    showAddAmountConfirmDialog = false;

    balanceUpdateObservable: Subscription;
    isMerchantAccount = false;

    responsiveOptions;
    company: CompanyV2 = new CompanyV2();
    planList: any;
    groups: any[];

    allDocuments = [];
    originalDocuments = [];
    employeesCompliance = [];
    complianceEmployeeCount = 0;
    complianceInterPaidCount = 0;
    complianceInterUnpaidCount = 0;
    complianceVolunteerCount = 0;
    complianceContractorsCount = 0;
    punchHistoryCount = 0;
    totalEndWork = 0;
    totalJobReports = 0;
    totalClockedIn = 0;
    totalOvertime = 0;
    endDateOvertime: any;
    startDateOvertime: any;
    dayOfWeekOvertime: any;
    totalAbsence = 0;
    listInternPaid = [];
    listInternUnpaid = [];
    listEmployees = [];
    listVolunteer = [];
    listContractors = [];
    addToDoClicked;
    showPopupUserAgreement = false;
    showPopupUserPrivacy = false;
    employees: any = [];
    contractors: any = [];
    punchFromDate: any;
    punchToDate: any;
    punchFromDateDialog: any;
    punchToDateDialog: any;
    fullName: any;
    punchHistoryList: any = [];
    showPunchDialog: boolean = false;
    confirmWeeklyHoursCorrect = false;
    totalPunchRecords: any = 0;
    loadingTrackData = false;
    trackingData = [];
    showTrackingDialog = false;
    confirmedPunch = false;
    milestone_markers = []
    milestone_groups = []
    milestones = []
    routePathMilestone = []
    trackingList = []
    selectedLat = null
    selectedLong = null
    selectedName: any
    endDate: any
    startTime: any
    firstLat: any
    firstLong: any
    firstLatMilestone: any
    firstLngMilestone: any
    zoomMilestone: any
    selectedMarker: any
    fullRecordsTab = true
    dialogTabs = [{'label': 'Full Records'}, {'label': 'By Milestone'}]
    activeItem = this.dialogTabs[0]
    options = {
        zoom: 14,
        navigationControl: false
    }
    fileUploaded: any;
    showCommentPopup: boolean = false;
    showFileUpload: boolean = false;
    selectedDocumentName: any;
    selectedDocumentData: any;
    selectedData: any;
    percentage: number = 0;
    files = [];
    processing = false;
    documents = [];
    licenseNumberM;
    expDateM;
    stateM;
    sendAlertM;
    @ViewChildren('upload') uploadFileRef: QueryList<ElementRef>;

    countTimerForTimeRecording: any;

    currentPage: number = 0; // Track the current page
    paginatedDocumentDetails: any[] = []; 

    clearingDocuments = false;
    savingDocuments = false;
    isPlatformAdmin = false;
    divWidth: number;

    @ViewChild('signaturePadConfirm') signaturePadConfirm: SignaturePad;
    showSignaturePadConfirm = true;
    isLicensorSignConfirm = null;
    signaturePadConfirmConfig: Object = {
        'minWidth': 1,
        'canvasWidth': 400,
        'canvasHeight': 225,
        'canvasBorder': 'none',
        'penColor': '#4a87f3'
    };

    timeSpent = new Date();
     ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.getCardDetails(!!localStorage.getItem('login'));
        localStorage.removeItem('login');
        this.isChildAccount = this.authService.isChildClientRole() || this.authService.isContractorRole();
        this.isClientAccount = this.authService.isClientRole();
        if (this.authService.getUserType() === 'EMPLOYEE' && this.authService.getUserConfig().department) {
            this.isBillingDepartment = this.authService.getUserConfig().department.includes('Billing');
        }
        if (this.isChildAccount) {
            this.showHourGlass = false;
            this.showEmail = false;
            this.contactLink = true;
        }
        if (this.authService.isCompanyAd() || this.authService.isSubCompanyAd() || this.authService.isClientRole() || this.authService.isChildClientRole() || this.authService.isContractorRole()) {
            this.loadBalance();
            this.loadPaymentMethod();
            this.payrollSettingService.getbyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    this.transferFeeCashOut = res.data.transferFeeCashOut;
                }
            });
            if (this.balanceUpdateObservable) {
                this.balanceUpdateObservable.unsubscribe();
            }
            this.balanceUpdateObservable = this.paymentProfileService.balanceUpdateObservable.debounceTime(1000).subscribe(e => {
                if (e) {
                    this.loadBalance();
                }
            });
        }
        this.checkSytemMaintain();
        this.getClientList();
        this.saveUsersOnline();
        setInterval(() => {
            this.saveUsersOnline();
        }, 10 * 60 * 1000);
        if (this.authService.isEmployeeRole()) {
            this.dashBoardForRole = '/app/employee-dashboard';
        } else if (this.authService.adminRole()) {
            this.dashBoardForRole = '/app/dashboard';
        }
        this.isVisible = this.authService.getLoggedInUserMenuList().includes('1156');
        this.showPhoneIcon();
        this.isGuess = localStorage.getItem('_user') === 'guess';
        if (this.authService.isClientRole()) {
            this.showHourGlass = false;
            this.showEmail = false;
            this.contactLink = true;
        }
        if (this.cookieService.get('_currentLang')) {
            this.activeLanguage = this.cookieService.get('_currentLang');
        } else {
            this.activeLanguage = this.translate.currentLang;
        }
        this.checkBoxLabel = 'I agree that the OT that I work for can be take back by working less hours in next week in order to balance the Time.';
        this.switchLanguage(this.activeLanguage);
        this.todo = new ToDo();
        this.todo.status = 'ToDo';
        this.todo.priority = 'Medium';
        this.todo.todo = 'Concern';
        this.regardings = TodoFormValuesConstant.getRegardings();
        if (localStorage.getItem('_disableAll') === '1') {
            this.disable = true;
        }
        // Check display for user agreement
        const e: Employee = this.authService.getUserConfig();
        const userType: any = localStorage.getItem('_user');
        if (userType == 'client'
            || userType == 'child_client'
        ) {
            if (!e.isAgreement) {
                this.showAgreementDialog = !e.isAgreement;
            }
        } else {
            if (!e.agreement && e.agreement != undefined) {
                this.showAgreementDialog = !e.agreement;
            } else if (!e.isAgreement && e.isAgreement != undefined) {
                this.showAgreementDialog = !e.isAgreement;
            }
        }

        this.isEmployee = this.authService.isEmployeeRole();
        // const otIncreaseTime = localStorage.getItem('otIncreaseTime');
        const countTimeLeftClock = localStorage.getItem('countTimeLeftClock');
        // console.log(countTimeLeftClock);

        const countIncreaseTimeLeft = localStorage.getItem('countIncreaseTimeLeft');
        // if (otIncreaseTime === undefined || otIncreaseTime == null) {
        //     this.countIncreaseTimeLeft = -1;
        // } else {
        //     const now = new Date(otIncreaseTime);
        //     this.countIncreaseTimeLeft = Math.floor(moment.duration(moment().diff(moment(now))).asMinutes()) * 60;
        // }
        if (countIncreaseTimeLeft === undefined || countIncreaseTimeLeft == null) {
            this.countIncreaseTimeLeft = -1;
        } else {
            this.countIncreaseTimeLeft = Number(countIncreaseTimeLeft);
        }
        if (countTimeLeftClock === undefined || countTimeLeftClock == null) {
            this.countTimeLeftClock = -1;
        } else {
            this.countTimeLeftClock = Number(countTimeLeftClock);
        }
        if (this.authService.isEmployeeRole() || this.authService.isSuper()) {
            this.disableSetting = true;
        }
        if (!this.userInfo.company) {
            this.disableSetting = true;
        }
        if (localStorage.getItem('serve_notification') === 'true') {
            this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany().id : -1;
            this.selectedAgency = this.authService.getCurrentAgency() ? this.authService.getCurrentAgency().id : -1;
            const tempData = JSON.parse(localStorage.getItem('_globals'));
            if (tempData && tempData.level === 8) {
                this.isPunchInOut = true;
            }
            this.filteredNotifications = [];
            const user = this.authService.getUserInfo();
            this.username = user.username;
            if (user.firstName && user.lastName) {
                this.newAssigneeName = user.firstName + ' ' + ((user.lastName).toString().toUpperCase())[0];
            }
            this.loadTransferNotifications();
            // for save note in CRM/CLIENT
            this.getAllNotification();
            this.utilsService.notification.subscribe((data) => {
                this.getAllNotification();
            });
            this.utilsService.transferNotification.subscribe((data) => {
                this.loadTransferNotifications();
            });
            setInterval(() => {
                this.getAllNotification();
            }, 600 * 1000);
            if (this.authService.isEmployeeRole()) {
                const userConfig = this.authService.getUserConfig();
                if (userConfig.salaryType !== 'salary') {
                    this.getTimeLeftToClockOut();
                }
            }
            const options = {
                employeeId: null,
                companyId: null
            };
            if (this.authService.isEmployeeRole()) {
                options.employeeId = this.authService.getCurrentEmployee();
            } else {
                options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany().id : null;
            }
            // TODO: NEED TO ENABLE AFTER REFACTOR BACKEND
            // this.employeeClockingService.getPunchMissingCountByFilter(options).subscribe(res => {
            //     this.missingCount = res.data;
            //     this.notificationCount = this.missingCount > 0 ? this.notificationCount + 1 : this.notificationCount;
            // });

            this.menuList = this.flattenMenu(this.getMenuList());
            this.filteredClientList = [];
            this.filteredMenuList = _.filter(this.menuList, x => {
                return true;
            });

            if (localStorage.getItem('_user') === 'guess') {
                this.systemSearchList = this.filteredMenuList;
            } else {
                this.systemSearchList = _.concat(this.filteredMenuList, this.filteredClientList);
            }
            if (!this.authService.isClientRole()) {
                // add invoice number
                this.getAllBillingInvoice();
                this.getClientListData();
            } else {
                this.getBillingInvoiceByClient();
            }
            if (!this.systemSearchList || this.systemSearchList.length === 0) {
                this.systemSearchList = [{ label: 'NOT FOUND', icon: 'icon' }];
            }
            this.dataChange = new ReplaySubject(1);
            this.dataChange.pipe(
                startWith({}),
                switchMap(() => {
                    const options: any = { size: 15, page: 0 };
                    if (this.textSearch) {
                        options.search = this.textSearch;
                        options.searchColumns = 'name';
                        if (this.selectedCompany) {
                            options.company = this.selectedCompany;
                        }

                        if (this.selectedAgency) {
                            options.agency = this.selectedAgency;
                        }
                        return this.clientService.getPage(options);
                    } else {
                        return of({});
                    }
                }),
                map((data: any) => {
                    return data.data ? data.data.content : [];
                })
            ).subscribe(
                data => {
                    this.filteredClientList = _.map(data, (x: CrmClient) => {
                        return { label: x.name, routerLink: '/app/crm/client/edit/' + x.id };
                    });
                    if (localStorage.getItem('_user') === 'guess') {
                        this.systemSearchList = this.filteredMenuList;
                    } else {
                        this.systemSearchList = _.concat(this.filteredMenuList, this.filteredClientList);
                    }
                    if (!this.systemSearchList || this.systemSearchList.length === 0) {
                        this.systemSearchList = [{ label: 'NOT FOUND', icon: 'icon' }];
                    }
                }
            );
        }
        if (this.authService.isEmployeeRole()) {
            // this.setClockingReminder();
            this.checkPlanAssignmentReminder();
            this.interval1 = setInterval(() => {
                this.overtimeConfirmationService.displayOvertimeAlert(this.authService.getCurrentLoggedInId()).subscribe(res => {
                    const data = res.data;
                    if (res.data && data.displayOvertimeAlert) {
                        this.overtimeDate = data.overtimeDate;
                        this.showTakeOTBack = data.showTakeOTBack;
                        clearInterval(this.interval1);
                        this.payrollSettingService.getbyId(this.authService.getCurrentCompanyId()).subscribe((setting: any) => {
                            if (setting.data) {
                                if (setting.data.sendTime > 0 && setting.data.intervalTime > 0) {
                                    // this.overtimeAlert = true;
                                    this.confirmationError = '';
                                    // Display multiple times
                                    let count = 0;
                                    this.interval = setInterval(() => {
                                        count++;
                                        if (!this.overtimeConfirmed) {
                                            // this.overtimeAlert = true;
                                            this.confirmationError = '';
                                        }
                                        if (count >= setting.data.sendTime - 1) {
                                            this.popupClosable = false; // Don't allow close dialog at the last time
                                            clearInterval(this.interval);
                                        }
                                    }, setting.data.intervalTime * 60000);
                                } else {
                                    // Display one time
                                    // this.overtimeAlert = true;
                                    this.confirmationError = '';
                                    this.popupClosable = false;
                                }
                            }
                        });
                    }
                });
                this.surveyPopup();
            }, 60 * 1000);
            // this.overtimeConfirmationInterval = setInterval(() => {
            //     this.overtimeConfirmationService.getOvertimeConfirmationToday(this.authService.getCurrentLoggedInId()).subscribe(res => {
            //         const data = res.data;
            //         if (data && !data.notificationSent && data.status !== 'PENDING') {
            //             this.overtimeConfirmationAlert = true;
            //             this.overtimeConfirmation = data;
            //             data.notificationSent = true;
            //             this.overtimeConfirmationService.save(data, this.authService.getCurrentLoggedInId()).subscribe();
            //         } else if (data.notificationSent) {
            //             clearInterval(this.overtimeConfirmationInterval);
            //         }
            //     });
            // }, 60 * 1000);

            const employeeClockingStatusOptions: any = {
                employeeId: this.authService.getCurrentLoggedInId()
            };
            this.employeeClockingServiceV2.employeeClockingStatus(employeeClockingStatusOptions).subscribe(res => {
                // console.log('employeeClockingStatus res: ', res);
                const rsp: any = res;
                if (rsp.status === 'SUCCESS') {
                    if (rsp.data && rsp.data.code === '00') {
                        this.empClockingStatus = rsp.data.empClockingStatus;
                    }
                }
            });

            this.openComplianceEmployeeWizard()

            this.payrollSettingService.getPayrollSettings(this.authService.getCurrentCompanyId(), { companyId: this.authService.getCurrentCompanyId() }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.payrollSetting = resObj.data

                    const dayName = moment().format('dddd')
                    if (this.payrollSetting.employeeTimeConfirmation === '1' && this.payrollSetting.displayOn.includes(dayName)
                        && (!localStorage.getItem('_showConfirmationPunchInOut') || localStorage.getItem('_showConfirmationPunchInOut') !== '1')) {
                        this.getPunchHistoryList();
                    }
                }
            });
        }
        this.checkForConcern();
        this.companySurveySetup();
        this.getPerformanceCriteria();
        // this.getAllCompanies();
        this.systemSearchList.forEach(e => {
            if (e.label === 'Product Management') { e.routerLink = ['/app/crm/products/list']; }
            if (e.label === 'Product') { e.routerLink = ['/app/product/add']; }
        });
        this.isAdminAccnt = this.authService.isCompanyAd() || this.authService.isSubCompanyAd();
        this.companyOtherSetting();
        setInterval(() => {
            if (localStorage.getItem('_shortcutSetting')) { this.companyOtherSetting(); }
        }, 3000);
        const infoCompany = localStorage.getItem('_infoCompany');
        const planList = localStorage.getItem('_planList');
        if (infoCompany) {
            this.company = JSON.parse(infoCompany);
        } else {
            this.getCompany();
        }
        if (planList) {
            this.planList = JSON.parse(planList);
        } else {
            this.getPlanList();
        }
        this.getAllDocument();
        this.loadPayment();
        this.getPendingInvoice();

        const userLoginInfo = this.authService.getUserInfo();
        const accountType = userLoginInfo?.accountType;
        if (accountType === 'Merchant') {
            this.isMerchantAccount = true;
        }

        setTimeout(() => {
            // console.log('filteredNotifications: ', this.filteredNotifications);
            // console.log('notificationCount: ', this.notificationCount);
            // console.log('pendingTransaction: ', this.pendingTransaction);
            // console.log('newPaymentTransaction: ', this.newPaymentTransaction);
        }, 6000);
        this.initFirebaseEvent();

        this.getComplianceDocumentList();  // Fetch the compliance document list
        this.paginateDocumentDetails();          // Paginate the updated document details

        this.loadTimerState();
        // this.getDataTimerInFirebase();
        // this.removeDataTimerInFirebase();
    }

    initFirebaseEvent() {
        this.getNotificationMessage();
        // this.subscriptions.add(this.db.list(`/notification/${this.authService.getCurrentCompanyId()}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
        //     console.log('notification event', JSON.parse(<string>event[0]));
        //     this.getNotificationMessage();
        // }));

        this.subscriptions.add(this.db.list(`/latest-pending-transaction/${this.authService.getCurrentCompanyId()}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event) {
                if (this.authService.isCompanyAdminRole() || this.authService.isClientRole()) {
                    this.getPendingInvoice();
                }
            }
        }));

        this.subscriptions.add(this.db.list(`/latest-transaction/${this.authService.getCurrentCompanyId()}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (this.authService.isCompanyAdminRole() && this.newPaymentLastSeenTimeLoaded) {
                this.getNewPaymentInvoice();
            }
        }));

        this.subscriptions.add(this.db.list(`/notification-walker/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            // console.log('notification-walker event', event);
            if (event) {
                this.totalNewVoterAssigned = event.length;
            }
        }));

        this.subscriptions.add(this.db.object(`/latest-seen-payment/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
            if (event && event.lastSeenTime) {
                this.newPaymentLastSeenTime = event.lastSeenTime;
            } else {
                this.newPaymentLastSeenTime = null;
            }
            if (!this.newPaymentLastSeenTimeLoaded) {
                this.newPaymentLastSeenTimeLoaded = true;
            }
            this.getNewPaymentInvoice();
        }));

        this.subscriptions.add(this.db.list(`/upload_file/billing-total-job/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event && event.length > 0) {
                // console.log('Event upload: ' + event);
                if (event[2]) {
                    this.showPopupTotalJobUpload = true;
                    this.eventUploadTotalJob.createdDate = event[1];
                    this.eventUploadTotalJob.clientId = event[0];
                    this.eventUploadTotalJob.isNotification = false;
                    this.eventUploadTotalJob.uploadBy = event[3];
                    const client = this.clientList.find(e => e.value == event[0]);
                    if (client) {
                        this.clientNameUpload = client.label;
                    }
                    this.markForNofificationUploadTotalJob(`/upload_file/billing-total-job/${this.authService.getUserInfo().adminId}`, this.eventUploadTotalJob);
                }
            }
        }));

        this.subscriptions.add(this.db.list(`/upload_file/billing-report/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event && event.length > 0) {
                // console.log('Event upload: ' + event);
                if (event[2]) {
                    this.showPopupBillingReport = true;
                    this.eventUploadBillingReport.createdDate = event[1];
                    this.eventUploadBillingReport.clientId = event[0];
                    this.eventUploadBillingReport.isNotification = false;
                    this.eventUploadBillingReport.uploadBy = event[3];
                    const client = this.clientList.find(e => e.value == event[0]);
                    if (client) {
                        this.clientNameUpload = client.label;
                    }
                    this.markForNofificationUploadTotalJob(`/upload_file/billing-report/${this.authService.getUserInfo().adminId}`, this.eventUploadBillingReport);
                }
            }
        }));

        this.subscriptions.add(this.db.list(`/upload_file/billing-detail-report/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event && event.length > 0) {
                // console.log('Event upload: ' + event);
                if (event[2]) {
                    this.showPopupBillingDetailReport = true;
                    this.eventUploadLogDetailReport.createdDate = event[1];
                    this.eventUploadLogDetailReport.clientId = event[0];
                    this.eventUploadLogDetailReport.isNotification = false;
                    this.eventUploadLogDetailReport.uploadBy = event[3];
                    const client = this.clientList.find(e => e.value == event[0]);
                    if (client) {
                        this.clientNameUpload = client.label;
                    }
                    this.markForNofificationUploadTotalJob(`/upload_file/billing-detail-report/${this.authService.getUserInfo().adminId}`, this.eventUploadLogDetailReport);
                }
            }
        }));


        this.subscriptions.add(this.db.list(`/upload_file/billing-correction-report/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event && event.length > 0) {
                // console.log('Event upload: ' + event);
                if (event[2]) {
                    this.showPopupCorrectionReport = true;
                    this.eventUploadCorrectionReport.createdDate = event[1];
                    this.eventUploadCorrectionReport.clientId = event[0];
                    this.eventUploadCorrectionReport.isNotification = false;
                    this.eventUploadCorrectionReport.uploadBy = event[3];
                    const client = this.clientList.find(e => e.value == event[0]);
                    if (client) {
                        this.clientNameUpload = client.label;
                    }
                    this.markForNofificationUploadTotalJob(`/upload_file/billing-correction-report/${this.authService.getUserInfo().adminId}`, this.eventUploadCorrectionReport);
                }
            }
        }));

        this.subscriptions.add(this.db.list(`/upload_file/billing-log-total-job/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event && event.length > 0) {
                // console.log('Event upload: ' + event);
                if (event[2]) {
                    this.showPopupLogTotalJob = true;
                    this.eventUploadLogTotalJob.createdDate = event[1];
                    this.eventUploadLogTotalJob.clientId = event[0];
                    this.eventUploadLogTotalJob.isNotification = false;
                    this.eventUploadLogTotalJob.uploadBy = event[3];
                    const client = this.clientList.find(e => e.value == event[0]);
                    if (client) {
                        this.clientNameUpload = client.label;
                    }
                    this.markForNofificationUploadTotalJob(`/upload_file/billing-log-total-job/${this.authService.getUserInfo().adminId}`, this.eventUploadLogTotalJob);
                }
            }
        }));

        this.subscriptions.add(this.db.list(`/upload_file/log-detail-report/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            if (event && event.length > 0) {
                // console.log('Event upload: ' + event);
                if (event[2]) {
                    this.showPopupLogDetailReport = true;
                    this.eventUploadLogDetailReport.createdDate = event[1];
                    this.eventUploadLogDetailReport.clientId = event[0];
                    this.eventUploadLogDetailReport.isNotification = false;
                    this.eventUploadLogDetailReport.uploadBy = event[3];
                    const client = this.clientList.find(e => e.value == event[0]);
                    if (client) {
                        this.clientNameUpload = client.label;
                    }
                    this.markForNofificationUploadTotalJob(`/upload_file/log-detail-report/${this.authService.getUserInfo().adminId}`, this.eventUploadLogDetailReport);
                }
            }
        }));
        if (this.authService.isEmployeeRole()) {
            this.subscriptions.add(this.db.object(`/job-report-reminder/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(200)).subscribe((event: any) => {
                if (event && event.status === 'SENT' && event.time) {
                    this.showJobReportPopupReminder = moment(new Date(event.time)).isSame(moment(), 'hour');
                    if (this.showJobReportPopupReminder) {
                        const currentTime = new Date().getTime();
                        const diffTime = currentTime - event.time;
                        this.diffHours = Math.floor(diffTime / 3600000);
                    }
                }
            }));
            this.subscriptions.add(this.db.object(`/employee-avatar/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
                if (event) {
                    this.photoURL = event.fileUrl;
                } else {
                    this.photoURL = null;
                }
            }));
        }
    }

    getReminderMessage() {
        let msg = '';
        if (this.showJobReportPopupReminder) {
            msg = 'It has been ' + this.diffHours + (this.diffHours > 1 ? ' hours' : ' hour') + ' since you started working. Would you like to add a Job Report note?';
        }
        return msg;
    }

    showPhoneIcon() {
        const isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        // let menuList = this.authService.getLoggedInUserMenuList();
        // if (menuList.indexOf('57') >= 0 || menuList.indexOf('58') >= 0 || menuList.indexOf('68') >= 0
        //     || menuList.indexOf('63') >= 0 || menuList.indexOf('61') >= 0) this.showPhone = true;
        if (!isPlatformAdmin) {
            this.companyService.get(this.authService.getCurrentCompanyId()).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    if (resObj.data && resObj.data.serviceTypeIds.length > 0) {
                        if (resObj.data.serviceTypeIds.includes(3)) { this.showPhone = true; } else { this.showPhone = false; }
                    }
                }
            });
        } else { this.showPhone = true; }
    }

    // switchLanguage() {
    //     this.cookieService.set('_currentLang', this.activeLanguage);
    //     this.reloadLange(this.activeLanguage);
    // }

    switchLanguage(language: string) {
        this.activeLanguage = language;
        this.cookieService.set('_currentLang', this.activeLanguage);
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        this.reloadLange(language);
    }

    reloadLange(selectedLang) {
        new Promise<any>((resolve: any) => {
            const locationInitialized = this.injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );
            locationInitialized.then(() => {
                const langToSet = selectedLang;

                this.translate.use(langToSet).subscribe(
                    () => {

                        // console.log(`Successfully initialized '${langToSet}' language.'`);
                    },
                    err => {
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
    }
    changeImageProfile() {
        switch (this.role) {
            case 1:
                this.getPlatformAdmin();
                break;
            case 3:
            case 5:
                this.getAgencyAdmin();
                break;
            case 4:
            // case 6:
            //     this.router.navigate(['app/admin/company-admin/view/' + userId]);
            //     break;
            case 7:
                this.getEmployee();
                break;
            default:
                break;
        }
    }

    getPlatformAdmin() {
        this.platformAdminService.get(this.currentLoggedInUserId).subscribe(res => {
            // console.log(res);
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.profilePhoto) {
                this.getProfileImage(resObj.data.profilePhoto);
            }
        });
    }

    getAgencyAdmin() {
        this.agencyAdminService.getDetail(this.currentLoggedInUserId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.profilePhoto) {
                this.getProfileImage(resObj.data.profilePhoto);
            }
        });
    }

    getEmployee() {
        // this.employeeService.get(this.employeeId).subscribe(res => {
        this.employeeService.getEmployeeByIdCustom(this.currentLoggedInUserId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.profileImageId) {
                this.getProfileImage(resObj.data.profileImageId);
            }
        });
    }

    getProfileImage(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data) {
                // console.log(resObj.data);
                this.photoURL = resObj.data.fileUrl;
            }
        }, error => { });
    }

    updateUrl(event) {
        this.photoURL = 'assets/images/logo.png';
    }

    checkForConcern() {
        this.concernMsgs = [];
        const companyId = this.authService.getCurrentCompanyId();
        if (this.authService.isEmployeeRole()) {
            this.payrollSettingService.getPayrollSettings(companyId, { companyId: companyId }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    if (resObj.data) {
                        this.payrollSetting = resObj.data;
                    }
                    this.processConcern();
                }
            });
        }
    }

    processConcern() {
        const todayCurrentTime = new Date();
        const todayWorkEndTime = new Date();
        const employee: Employee = this.authService.getUserConfig();
        const currentDay = todayCurrentTime.getDay();
        let endTime = null;
        let timeout = null;
        if (employee.workHours) {
            employee.workHours.forEach((val) => {
                if (val.dayOfWeek === currentDay && val.endTime) {
                    endTime = new Date(val.endTime);
                }
            });
        }
        if (endTime) {
            endTime.setTime(endTime.getTime() + (endTime.getTimezoneOffset() * 60 * 1000));
            const alertEmpEndTime = (this.payrollSetting && this.payrollSetting.alertEmployeeEndTime) ? new Date(this.payrollSetting.alertEmployeeEndTime) : new Date(new Date().setHours(1, 0));
            todayWorkEndTime.setHours(endTime.getHours() - alertEmpEndTime.getHours(), endTime.getMinutes() - alertEmpEndTime.getMinutes());
            timeout = todayWorkEndTime.getTime() - todayCurrentTime.getTime();
            this.setConcernTimeout(timeout);
        } else {
            this.employeeClockingService.getFirstClockingOfDateByEmployee({
                employeId: employee.id,
                dateCheckIn: todayCurrentTime.getFullYear() + '-' + (todayCurrentTime.getMonth() + 1) + '-' + todayCurrentTime.getDate()
            }).subscribe((res: any) => {
                if (res && res.status === 'SUCCESS' && res.data) {
                    const checkInTime = new Date(res.data.checkInTime);
                    checkInTime.setTime(checkInTime.getTime() + (checkInTime.getTimezoneOffset() * 60 * 1000));
                    checkInTime.setHours(checkInTime.getHours() + 5);
                    timeout = checkInTime.getTime() - todayCurrentTime.getTime();
                    this.setConcernTimeout(timeout);
                }
            });
        }
    }

    setConcernTimeout(timeout) {
        if (timeout) {
            if (timeout >= 0) {
                setTimeout(() => {
                    this.show = true;
                }, timeout);
            } else {
                const date = new Date();
                date.setHours(0, 0, 0, 0);
                const payLoad = {
                    userId: this.authService.getCurrentLoggedInId(),
                    date: date.getTime()
                };
                this.todoService.getConcernDetails(payLoad).subscribe((res) => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS' && resObj.data.content.length === 0) {
                        this.show = true;
                    }
                });
            }
        }
    }

    setClockingReminder() {
        if (this.authService.isEmployeeRole()) {
            this.reminderInterval = setInterval(() => {
                this.isOpenClockingReminder = false;
                this.overtimeConfirmationService.displayClockingReminder(this.authService.getCurrentLoggedInId()).subscribe(res => {
                    const data = res.data;
                    if (data && data.isOpenPopup) {
                        this.isOpenClockingReminder = data.isOpenPopup;
                        this.reminderMessage = data.message;
                    }
                });
            }, 180 * 1000);
            // this.overtimeConfirmationInterval = setInterval(() => {
            //     this.overtimeConfirmationService.getOvertimeConfirmationToday(this.authService.getCurrentLoggedInId()).subscribe(res => {
            //         const data = res.data;
            //         if (data && !data.notificationSent && data.status !== 'PENDING') {
            //             this.overtimeConfirmationAlert = true;
            //             this.overtimeConfirmation = data;
            //             data.notificationSent = true;
            //             this.overtimeConfirmationService.save(data, this.authService.getCurrentLoggedInId()).subscribe();
            //         } else if (data.notificationSent) {
            //             clearInterval(this.overtimeConfirmationInterval);
            //         }
            //     });
            // }, 60 * 1000);
        }
    }

    checkPlanAssignmentReminder() {
        if (this.authService.isEmployeeRole()) {
            setInterval(() => {
                const options: any = {
                    page: 0,
                    size: 999,
                    username: this.authService.getCurrentUsername(),
                    fromDate: new Date(),
                    toDate: new Date()
                };
                this.operatorService.searchTodo(options, true).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        if (resObj.data.content.length > 0) {
                            this.showPlanAssignmentDialog = true;
                            this.employeeFullName = this.authService.getCurrentLoggedInName();
                            this.todoId = resObj.data.content[0].id;

                        }
                    }
                });
            }, 60 * 1000);
        }
    }

    getMenuList(): MenuItem[] {
        const menuList = this.allMenus.getAllMenus();

        const rtn = [];
        this.loggedInUserMenus = this.authService.getLoggedInUserMenuList();
        for (let i = 0; i < menuList.length; i++) {
            const menuGranted = this.isMenuAccessGranted(menuList[i]);
            if (menuGranted) {
                rtn.push(menuGranted);
            }
        }
        return _.compact(rtn);
    }

    flattenMenu(menus: any[]): { routerLink: string, label: string }[] {
        let rtn: { routerLink: string, label: string }[] = [];
        for (let i = 0; i < menus.length; i++) {
            const mn: { routerLink: string, label: string } = { routerLink: '', label: '' };
            mn.label = menus[i].label;
            mn.routerLink = menus[i].routerLink;
            if (mn.label && mn.routerLink) {
                rtn.push(mn);
            }

            if (menus[i].items && menus[i].items.length > 0) {
                rtn = _.concat(rtn, (this.flattenMenu(menus[i].items)));
            }

        }
        return rtn;
    }

    private isMenuAccessGranted(menuItem: any): MenuItem {
        let mni: any;
        if (menuItem.menuId) {
            if (this.loggedInUserMenus.indexOf(menuItem.menuId.toString()) > -1) {
                mni = {};
                mni.label = menuItem.label;
                mni.routerLink = menuItem.routerLink;
                mni.icon = menuItem.icon;

                if (menuItem.items && menuItem.items.length > 0) {
                    mni.items = [];
                    menuItem.items.forEach((it) => {
                        const mnii = this.isMenuAccessGranted(it);
                        if (mnii) {
                            mni.items.push(mnii);
                        }

                    });
                }

                return mni;
            }
        } else {
            mni = {};
            mni.label = menuItem.label;
            if (menuItem.command) {
                mni.command = menuItem.command;
            }
            if (menuItem.routerLink) {
                mni.routerLink = menuItem.routerLink;
            }

            mni.icon = menuItem.icon;
            if (menuItem.items && menuItem.items.length > 0) {
                mni.items = [];

                menuItem.items.forEach((it) => {
                    const mnii = this.isMenuAccessGranted(it);
                    if (mnii) {
                        mni.items.push(mnii);
                    }

                });
            }
            mni.items = _.compact(mni.items);
            if (mni.items && mni.items.length > 0) {
                return mni;
            }

        }
        return null;
    }


    instantSearch(event) {
        if (!event || !event.query) { return; }
        if (!this.authService.isClientRole()) {
            this.getEmployeesList(event.query);
            this.getContractorsList(event.query);
        }
        this.filteredMenuList = _.filter(this.menuList, x => {
            return this.includes(x.label, event.query.trim());
        });
        if (localStorage.getItem('_user') === 'guess') {
            this.systemSearchList = this.filteredMenuList;
        } else {
            this.systemSearchList = _.concat(this.filteredMenuList, this.filteredClientList);
        }
        if (!this.systemSearchList || this.systemSearchList.length === 0) {
            this.systemSearchList = [{ label: 'NOT FOUND', icon: 'icon', routerLink: '' }];
        }
        this.dataChange.next(1);
        // let filtered: any[] = [];
        // let query = event.query;
        // for (let i = 0; i < this.menuList.length; i++) {
        //   let country = this.menuList[i];
        //   if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        //     filtered.push(country);
        //   }
        // }

        // this.systemSearchList = filtered;
    }
    toRusltSearch() {
        if (this.searchSelected?.clientId && this.searchSelected?.type === 'invoice') {
            localStorage.removeItem('top-bar-invoice-number');
            const data: any = {};
            data.clientId = this.searchSelected?.clientId;
            data.invoiceNumber = this.searchSelected?.label;
            localStorage.setItem('top-bar-invoice-number', JSON.stringify(data));
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };
            this.router.onSameUrlNavigation = 'reload';
        } else if (this.searchSelected?.clientId && this.searchSelected?.type === 'client') {
            localStorage.removeItem('top-bar-client');
            const data: any = {};
            data.clientId = this.searchSelected?.clientId;
            data.name = this.searchSelected?.label;
            localStorage.setItem('top-bar-client', JSON.stringify(data));
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };
            this.router.onSameUrlNavigation = 'reload';
        } else if (this.searchSelected?.type === 'contractor' || this.searchSelected?.type === 'employee') {
            this.router.navigate(['app/user', 'view', this.searchSelected?.type, this.searchSelected?.id]);
        }
        const currentSearch = this.searchSelected;
        this.searchSelected = null;
        this.router.navigate(currentSearch?.routerLink);
    }

    includes(pstr: string, sstr: string) {
        return _.includes(_.toLower(pstr), _.toLower(sstr));
    }

    loadTransferNotifications() {
        this.noteHistoryService.getNotesToUser(this.newAssigneeName).subscribe((e: any) => {
            this.transferNotifications = [];
            (e.data).forEach((res) => {
                if (res.status === 1) {
                    this.transferNotifications.push(res);
                }
            });
            this.getNotificationsByOldAssignee();
        });
    }

    triggerLogOut() {
        this.onClickLogout();
        // if(this.utilsService.visitedPageData.pageUrl){
        //     this.utilsService.isLogoutTriggerCompleted = true;
        //     this.utilsService.isLogoutTriggered.next(true);
        // }else{
        //     this.utilsService.isLogoutTriggerCompleted = false;
        //     this.onClickLogout();
        // }
        // this.cookieService.deleteAll();
        // this.authService.logUserActivity();
        // localStorage.clear();
        // this.authService.authenticateUser();
        // this.authService.setAuthObservable();
        // this.router.navigate(['/login']);
    }

    onClickLogout() {
        this.employeeService.invoiceWarningDialog = false;
        this.employeeService.balanceWaringDialog = false;
        this.employeeService.showConfirmAutoPayReminderDialog = false;
        this.employeeService.payUnPaidInvoiceDialog = false;
        this.employeeService.showRegisterChildAccountDialog = false;
        this.employeeService.showOutstandingBalancePayDialog = false;
        this.authService.logUserActivity();
        if (localStorage.getItem('_user') === 'employee') {
            this.employeeService.updateEmployeeOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user') === 'freelancer') {
            this.freelancerService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }
        if (localStorage.getItem('_user') === 'admin') {
            this.platformAdminService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user') === 'client') {
            this.clientService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user') === 'guess') {
            this.membershipService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        localStorage.clear();
        this.authService.authenticateUser();
        this.authService.setAuthObservable();
        this.router.navigate(['/login']);
    }

    onClickNote() {
        this.router.navigate(['app/note']);
    }
    onClickFaq() {
        this.router.navigate(['app/faq']);
    }
    onClickSendEmail() {
        if (JSON.parse(localStorage.getItem('_disableAll')) === 1) {
            this.messageService.add({ severity: 'error', summary: 'Set Schedule', detail: 'Please set your schedule first.' });
        } else {
            this.router.navigate(['app/email']);
        }
    }


    async getAllNotification() {
        await this.showBrowserNotification();
        await this.showDeadlinePopUp();
        await this.getAbsenceNotification();
        await this.getTodoPlanNotification();
    }

    async getOverttimeAlertNotification() {
        let count = 0;
        const employeeIds = [];
        this.noteHistoryService.getOvertimeNotification().subscribe((e) => {
            if (e.data.length > 0) {
                this.overtimeAlerts = e.data;
                e.data.forEach((item) => {
                    if (item.type === 'overtime_alert') {
                        if (employeeIds.indexOf(item.uniqueKey) < 0) {
                            count++;
                            this.notificationCount++;
                        }
                        employeeIds.push(item.uniqueKey);
                    }
                });
            }
            this.overttimeNotificationCount = count;
            if (this.overttimeNotificationCount > 0) {
                this.notificationText.push({ text: 'OverTime Alert ' + this.overttimeNotificationCount, iconName: 'event', type: 'overtime_alert' });
            }
        });
    }

    async getAbsenceNotification() {
        this.notificationCount = 0;
        this.notificationText = [];
        const dayOfRequest = {
            items: 0,
            keys: [],
            ids: []
        };
        const options = <any>{};
        if (this.userInfo) {
            options.targetId = this.userInfo.adminId;
            options.targetRoleId = this.userInfo.role ? this.userInfo.level : null;
        }
        options.status = 1;
        this.absenceService.getAbsenceNotification(options).subscribe((res: any) => {
            if (res.status === 'SUCCESS' && res.data && res.data.length > 0) {
                res.data.forEach((item) => {
                    dayOfRequest.items++;
                    dayOfRequest.keys.push(item.absenceId);
                    dayOfRequest.ids.push(item.id);
                    this.notificationCount++;
                });
                const foundIndex = this.notificationText.findIndex(item => item.type != Constants.NOTE_DATA.SAVE_ABSENCE_REQUESTED);
                if (foundIndex < 0) {
                    this.notificationText.push({
                        text: 'Day off Request ' + dayOfRequest.items,
                        iconName: 'today',
                        type: Constants.NOTE_DATA.SAVE_ABSENCE_REQUESTED,
                        keys: dayOfRequest.keys,
                        ids: dayOfRequest.ids
                    });
                } else {
                    this.notificationText[foundIndex] = {
                        text: 'Day off Request ' + dayOfRequest.items,
                        iconName: 'today',
                        type: Constants.NOTE_DATA.SAVE_ABSENCE_REQUESTED,
                        keys: dayOfRequest.keys,
                        ids: dayOfRequest.ids
                    };
                }
            }
        });

    }
    async getEventNotification() {
        await this.eventService.getNotificationByType('save_event').subscribe((notification) => {
            this.allNotificationOfEventType = notification.data;
            this.duplicatesUniqueKeyArr = [];
            if (notification && notification.data.length > 0) {
                const uniqueKeyArr = [];
                notification.data.forEach((n) => {
                    uniqueKeyArr.push(n.uniqueKey);
                });
                this.duplicatesUniqueKeyArr = uniqueKeyArr.reduce((acc, el, i, arr) => {
                    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) { acc.push(el); }
                    return acc;
                }, []);
            }
        });
        this.employeeEventService.getByEmployeeEvent(this.userInfo.adminId).subscribe((res: any) => {
            this.notificationCountBackgroundChange = false;
            if (res.data && res.data.length > 0) {
                this.notificationCount = res.data.length;
                (res.data).forEach((item) => {
                    if (item.event && item.event.id) {
                        this.eventService.get(item.event.id).subscribe((event: any) => {
                            const today = moment();
                            const tomorrow = moment().add(1, 'day');
                            const ourDate = event.data.startDate;
                            this.multipleNotification = false;
                            this.duplicatesUniqueKeyArr.forEach((du) => {
                                if (du === item.event.id) {
                                    this.multipleNotification = true;
                                    this.notificationCountBackgroundChange = true;
                                }
                            });

                            if (moment(ourDate).isSame(today, 'day')) {
                                this.notificationText.push({
                                    text: event.data.crmEventType.name + 'Today' + ' '
                                        + moment(event.data.startDate).format('HH:mm'), iconName: 'event'
                                    , backgroundChange: this.multipleNotification, id: item.id,
                                    url: '/app/employee/event-schedule', eventType: event.data.crmEventType.name
                                });
                            } else if (moment(ourDate).isSame(tomorrow, 'day')) {
                                this.notificationText.push(
                                    {
                                        text: event.data.crmEventType.name + 'Tomorrow' + ' ' + moment(event.data.startDate).format('HH:mm'),
                                        iconName: 'event',
                                        backgroundChange: this.multipleNotification,
                                        id: item.id,
                                        url: '/app/employee/event-schedule',
                                        eventType: event.data.crmEventType.name
                                    });
                            } else {
                                this.notificationText.push({
                                    text: event.data.crmEventType.name + ' '
                                        + moment(event.data.startDate).format('MM/DD/YYYY HH:mm')
                                    , iconName: 'event', backgroundChange: this.multipleNotification
                                    , id: item.id, url: '/app/employee/event-schedule', eventType: event.data.crmEventType.name
                                });
                            }
                        });
                    }
                });
            }
            // for employee event
            this.getAllEmployeePerformance();
        });
    }
    getNoteNotification() {
        const dayOfRequest = {
            items: 0,
            keys: [],
            ids: []
        };

        const ticketNotification = {
            items: 0,
            keys: [],
            ids: []
        };

        const jobReportNotification = {
            items: 0,
            keys: [],
            ids: []
        };

        this.noteHistoryService.getNotificationByUser(this.username).subscribe((e) => {
            this.missingInOutCount = 0;
            if (e.data.length > 0) {
                // console.log(e);
                e.data.forEach((item) => {
                    if (item.type === Constants.NOTE_DATA.SAVE_NOTE) {
                        // DO NOT DELETE BELOW CODE
                        // this.noteHistoryService.getNoteById(item.uniqueKey).subscribe((res: any) => {
                        //     console.log(res);
                        //     if (res.data.clientId) {
                        //         this.clientService.get(res.data.clientId).subscribe((response: any) => {
                        //             this.notificationText.push({text: res.data.noteType ? res.data.noteType : '' + ' (' + response.data.name ? response.data.name : '' + ')', iconName: 'note'});
                        //         });
                        //     }
                        // });
                        this.notificationCount++;
                        this.noteNotificationCount++;
                    }

                    if (item.type === Constants.NOTE_DATA.SAVE_EDITED_PUNCH_IN_OUT_NOTE) {
                        // this.notificationText.push({ text: 'Check in/out edits', iconName: 'edit' });
                        this.missingInOutCount++;
                    }

                    if (item.type === Constants.NOTE_DATA.UNREAD_TICKET) {
                        ticketNotification.items++;
                        ticketNotification.ids.push(item.id);
                        ticketNotification.keys.push(item.uniqueKey);

                    }

                    if (item.type === 'job_report') {
                        jobReportNotification.items++;
                        jobReportNotification.ids.push(item.id);
                        jobReportNotification.keys.push(item.uniqueKey);
                    }

                    // if (item.type === Constants.NOTE_DATA.SAVE_ABSENCE_REQUESTED) {
                    //     dayOfRequest.items++;
                    //     dayOfRequest.keys.push(item.uniqueKey);
                    //     dayOfRequest.ids.push(item.id);
                    // }
                });
            }

            // this.notificationText.push(
            //     {
            //         text: 'Day of request ' + dayOfRequest.items,
            //         iconName: 'today',
            //         type: Constants.NOTE_DATA.SAVE_ABSENCE_REQUESTED,
            //         keys: dayOfRequest.keys,
            //         ids: dayOfRequest.ids
            //     });

            this.notificationText.push(
                {
                    text: 'Ticket ' + ticketNotification.items,
                    iconName: 'today',
                    type: Constants.NOTE_DATA.UNREAD_TICKET,
                    queryParams: { flag: 'unread' },
                    keys: ticketNotification.keys,
                    ids: ticketNotification.ids
                });

            this.notificationText.push(
                {
                    text: 'Job Report (' + jobReportNotification.items + ')',
                    iconName: 'today',
                    type: 'job_report',
                    keys: jobReportNotification.keys,
                    ids: jobReportNotification.ids
                });

            if (this.noteNotificationCount > 0) {
                this.notificationText.push(
                    {
                        text: 'New client note ' + this.noteNotificationCount,
                        iconName: 'note'
                    });
            }
        });
    }

    acceptTransfers(notification: any) {
        console.log(notification);
        const obj = this.removeDuplicates(this.transferNotifications);
        // console.log(obj);
        const transfer = {
            user: notification.user,
            oldAssignee: notification.oldAssignee,
            newAssignee: notification.newAssignee,
            note: notification.note
        };
        this.router.navigate(['/app/crm/client/transfer-acceptance', JSON.stringify(transfer)],
            { queryParams: { returnUrl: this.router.url } });
    }

    getNotificationsByOldAssignee() {
        this.filteredNotifications = this.removeDuplicates(this.transferNotifications);
    }

    occurencesMessage(key: string) {
        const num = this.countOccurences()[key];
        return num === 1 ? '1 Client assigned to you' : num + ' Clients assigned to you';
    }

    countOccurences() {
        return this.getOldAssignees().reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});
    }

    getOldAssignees() {
        return this.transferNotifications.map(notification => notification.oldAssignee);
    }

    removeDuplicates(list: NoteHistory[]) {
        const filtered = Array.from(list
            .reduce((m, o) => !m.has(o.oldAssignee)
                ? m.set(o.oldAssignee, o)
                : m, new Map)
            .values());

        // console.log(filtered);
        return filtered;
    }

    getAllEmployeePerformance() {
        this.employeePerformanceService.searchEmployeePerformances({ employeeIds: [this.userInfo.adminId] }).subscribe((res: any) => {
            if (res.data.content.length > 0) {
                (res.data.content).forEach((item, index) => {
                    this.notificationText.push(
                        {
                            text: 'New Performance ' + (index + 1),
                            iconName: 'bug_report',
                            url: 'app/performance/employees-list',
                            id: item.id
                        });
                    this.notificationCount++;
                });
            }
        });
    }

    // TODO: NEED TO ENABLE AFTER REFACTOR BACKEND
    getTimeLeftToClockOut() {
        this.employeeClockingService.getTimeLeftToClockOut(this.userInfo.adminId)
            .subscribe((res: any) => {
                const resObj = res;
                const employeeTimeToClockOut: EmployeeTimeLeftToClockOut = resObj.data;
                // const employeeTimeToClockOut = new EmployeeTimeLeftToClockOut(true, 65, 0, '', false);
                if (!employeeTimeToClockOut) {
                    return;
                }
                this.isShowSixHoursMessage = employeeTimeToClockOut.isSixHours;
                this.timeLeftToClockOut = null;
                this.timeLeftToClockOut = employeeTimeToClockOut;
                this.isTimeToDisplayClock = employeeTimeToClockOut.timeLeftToClockOut > 0;
                this.isTimeToDisplayClockIncrease = employeeTimeToClockOut.desiredTimeToAlert > 0;
                if (this.isTimeToDisplayClock) {
                    this.showVolume = true;
                    this.muteVolume = false;
                    if (this.countTimeLeftClock <= 0) {
                        this.countTimeLeftClock = employeeTimeToClockOut.timeLeftToClockOut * 60;
                    }

                } else if (this.isTimeToDisplayClockIncrease) {
                    this.showVolume = true;
                    this.muteVolume = false;
                    if (this.countIncreaseTimeLeft <= 0) {
                        this.countIncreaseTimeLeft = employeeTimeToClockOut.desiredTimeToAlert * 60;
                    }

                }
            });
    }



    updateTimeCount(event) {
        const date = new Date();
        localStorage.setItem('countTimeLeftClock', event);
        localStorage.setItem('countTime', date.getTime().toString());
    }
    updateTimeCounterIncrease(event) {
        const date = new Date();
        localStorage.setItem('countIncreaseTimeLeft', event);
        localStorage.setItem('countTime', date.getTime().toString());
    }
    lastMinuteCountdown() {
        this.shouldDisplayClockOutPopup = true;
        this.isTimeToDisplayClockIncrease = true;
        const otIncreaseTime = localStorage.getItem('otIncreaseTime');
        this.employeeService.updateOverTimeIncrease({ 'employeeId': this.authService.getCurrentLoggedInId() }).subscribe((res: any) => {
        });

        if (otIncreaseTime === undefined || otIncreaseTime == null) {
            const now = new Date().toString();
            localStorage.setItem('otIncreaseTime', now);
        }
        this.isTimeToDisplayClock = false;
    }

    toggleMuteSound() {
        this.shouldDisplayClockOutPopup = true;
    }

    userSignedOut(event) {
        this.shouldDisplayClockOutPopup = false;
        this.isTimeToDisplayClock = false;
        this.showVolume = false;
        this.muteVolume = false;
    }

    onClickNotification(item) {
        // console.log('clicked on notification', item);
        if (item.type === 'overtime_alert') {
            const employeeIds = [];
            this.overtimeAlerts.forEach(element => {
                employeeIds.push(element.uniqueKey);
            });
            localStorage.setItem('overtime_alert', employeeIds.toString());
            this.router.navigate(['/app/employee-clocking/punch-history']);
        }

        if (item.type === Constants.NOTE_DATA.SAVE_ABSENCE_REQUESTED && item.ids && item.ids.length > 0) {
            const ids = item.ids;
            this.router.navigate(['/app/absence/list'], { queryParams: { absenceIds: item.keys } });
            this.absenceService.deleteAbsenceNotification(ids).subscribe((res) => {
                this.getAbsenceNotification();
            });
        }

        if (item.type === Constants.NOTE_DATA.UNREAD_TICKET) {
            // delete notification
            this.eventService.deleteMultipleNotification(item.ids).subscribe(
                data => {
                    // console.log('delete notification', data);
                    this.router.navigate(['/app/todo/list'], { queryParams: { ids: _.join(item.keys) } });
                }
            );
            return;
        }

        if (item.type === 'job_report') {
            // delete notification
            this.eventService.deleteMultipleNotification(item.ids).subscribe(
                data => {
                    this.router.navigate(['/app/admin/job-report/list'], { queryParams: { ids: _.join(item.keys) } });
                }
            );
            return;
        }
        if (item.type === 'plan_assigned') {
            const ids = item.ids;
            this.router.navigate(['/app/workorder/list'], { queryParams: { id: item.keys } });
            this.operatorService.deleteTodoNotification(ids).subscribe((res) => {
                this.getTodoPlanNotification();
            });
            return;
        }

        if (item.url) {

            if (item.id != null) {
                this.router.navigate([`/${item.url}`, item.id]);
            } else {
                this.router.navigate([`/${item.url}`]);
            }
        }
        if (this.duplicatesUniqueKeyArr.length > 0) {
            const idsArray = [];
            this.duplicatesUniqueKeyArr.forEach((du) => {
                if (du === item.id) {
                    const tempArr = [];
                    this.allNotificationOfEventType.forEach((noti) => {
                        if (noti.uniqueKey === item.id) {
                            tempArr.push(noti);
                        }
                    });
                    for (let i = 1; i < tempArr.length; i++) {
                        idsArray.push(tempArr[i].id);
                    }
                }
            });
            this.eventService.deleteMultipleNotification(idsArray).subscribe((d) => {
                this.getAllNotification();
            });
        }
    }

    async showEventPopUp() {
        const options: any = {
            email: this.authService.getUserInfo().email
        };
        const popupData: any = await this.eventService.getEventsPopup(options).toPromise();
        this.popupList.data = [];
        if (popupData && popupData.data && popupData.data.length > 0) {
            popupData.data.forEach((item) => {
                this.getTimeDifference(item);
                this.showEventsPopUp = true;
                const startDate = new Date(item.startDate);
                this.events.eventDay = moment(startDate).format('D');
                this.events.eventDate = this.days[startDate.getDay()] + ', ' + moment(startDate).format('D') + ' ' + this.months[startDate.getMonth()];
                this.events.event = item;
            });
        }
    }

    async showDeadlinePopUp() {
        const options: any = {
            createdBy: this.authService.getCurrentUsername()
        };
        const popupData: any = await this.deadlineReminderService.getDeadlinePopup(options).toPromise();
        this.popupList.data = [];
        if (popupData && popupData.data && popupData.data.length > 0) {
            popupData.data.forEach((item) => {
                const infoCompany = localStorage.getItem('_infoCompany');
                if (infoCompany) {
                    this.company = JSON.parse(infoCompany);
                    const tzone = this.company.timezone.split(' ');
                    this.timeZone = {
                        tzone: tzone[0],
                        timezone: tzone[3]
                    };
                    this.getDeadlineTimeDifference(item);
                    this.showDeadlinesPopUp = true;
                    const deedlineDate = new Date(item.deadline);
                    const receiveDate = new Date(item.receiveDate);
                    this.deadlineReminder.deadline = moment(deedlineDate).format('D');
                    this.deadlineReminder.recieve = moment(receiveDate).format('D');
                    this.deadlineReminder.deadlineDate = this.days[deedlineDate.getDay()] + ', ' + moment(deedlineDate).format('D') + ' ' + this.months[deedlineDate.getMonth()];
                    this.deadlineReminder.receiveDate = this.days[receiveDate.getDay()] + ', ' + moment(receiveDate).format('D') + ' ' + this.months[receiveDate.getMonth()];
                    this.deadlineReminder.event = item;
                } else {
                    this.companyService.getCompanyByCompanyId(item.companyId).subscribe(res => {
                        const resObj: any = res;
                        if (resObj.status === 'SUCCESS') {
                            const tzone = resObj.data.timezone.split(' ');
                            this.timeZone = {
                                tzone: tzone[0],
                                timezone: tzone[3]
                            };
                            this.getDeadlineTimeDifference(item);
                            this.showDeadlinesPopUp = true;
                            const deedlineDate = new Date(item.deadline);
                            const receiveDate = new Date(item.receiveDate);
                            this.deadlineReminder.deadline = moment(deedlineDate).format('D');
                            this.deadlineReminder.recieve = moment(receiveDate).format('D');
                            this.deadlineReminder.deadlineDate = this.days[deedlineDate.getDay()] + ', ' + moment(deedlineDate).format('D') + ' ' + this.months[deedlineDate.getMonth()];
                            this.deadlineReminder.receiveDate = this.days[receiveDate.getDay()] + ', ' + moment(receiveDate).format('D') + ' ' + this.months[receiveDate.getMonth()];
                            this.deadlineReminder.event = item;
                        }
                    });
                }
            });
        }
    }

    async showBrowserNotification() {

        const user = this.authService.getUserInfo();
        const userType = localStorage.getItem('_user');
        if (userType !== 'freelancer' && userType !== 'admin' && userType !== 'employee' && userType !== 'client') {
            return;
        }
        const options: any = {
            status: 'New',
            entityType: userType,
            entityId: user.adminId,
            page: 0,
            size: 9999,
        };
        const popupData: any = this.browserNotificationService.filter(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                // console.log('Done getting notification');
                const notifications = resObj.data.content;
                if (notifications) {
                    if (!('Notification' in window)) {
                        alert('This browser does not support desktop notification');
                    } else if (Notification.permission === 'granted') {
                        this.showNotify(notifications);
                    } else if (Notification.permission !== 'denied') {
                        Notification.requestPermission().then(function (permission) {
                            if (permission === 'granted') {
                                this.showNotify(notifications);
                            }
                        });
                    }
                }
            }
        });
    }

    showNotify(browserNotifications: BrowserNotification[]) {
        browserNotifications.forEach(browserNotification => {
            browserNotification.status = 'Shown';
            this.browserNotificationService.update(browserNotification).subscribe(res => {

            });
            const notification = new Notification('OperrWork', {
                body: browserNotification.content,
                icon: browserNotification.logo,
            });
            notification.onclick = function () {
                window.open(browserNotification.url);
            };
        });

    }

    getDeadlineTimeDifference(item) {
        const date = new Date;
        if (item.deadline) {
            let diff = (item.deadline - date.valueOf()) / 1000;
            diff /= 60;
            const totalTime = Math.abs(Math.round(diff));
            if (totalTime >= 60) {
                const hours = Math.floor(totalTime / 60);
                const minutes = totalTime % 60;
                this.deadlineReminder.timeLeft = hours + ' h ' + minutes + ' minutes';
            } else if (totalTime === 1) {
                this.deadlineReminder.timeLeft = 0 + ' minutes';
            } else {
                this.deadlineReminder.timeLeft = totalTime + ' minutes';
            }
        }
    }

    getTimeDifference(item) {
        const date = new Date;
        if (item.startDate) {
            let diff = (item.startDate - date.getTime()) / 1000;
            diff /= 60;
            const totalTime = Math.abs(Math.round(diff));
            if (totalTime >= 60) {
                const hours = Math.floor(totalTime / 60);
                const minutes = totalTime % 60;
                this.events.timeLeft = hours + ' h ' + minutes + ' minutes';
            } else if (totalTime === 1) {
                this.events.timeLeft = 0 + ' minutes';
            } else {
                this.events.timeLeft = totalTime + ' minutes';
            }
        }
    }

    closePopUp() {
        this.showPopUp = false;
        this.isOpenClockingReminder = false;
        if (this.popupList && this.popupList.data && this.popupList.data.length) {
            this.popupList.data.forEach((e) => {
                e.status = 0;
                this.popupService.updateEntity(e).subscribe((e) => { });
            });
        }
    }

    closeReminderPopUp() {
        this.isOpenClockingReminder = false;
    }

    goToAgencySetting() {
        if (this.userInfo.company) {
            this.router.navigate(['app/company', 'setting', 'view', this.userInfo.company.id]);
        }
    }

    onClickAwayFromDesk() {
        this.awayFromDesk = true;
    }

    startCounting() {
        // console.log('Started Counting');
        if (!this.awayStarted) {
            this.awayTime = 1;
            this.awayStarted = true;
            this.awayCountingInterval = setInterval(() => {
                this.awayTime = this.awayTime + 1;
            }, 1000);
            this.createAwayFromDesk();
        }

    }

    createAwayFromDesk() {
        this.awayStarted = false;
        const awayActivity = new AwayActivity();
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }

        if (getCookieObj && getCookieObj.token) {
            awayActivity.userId = getCookieObj.adminId;
            awayActivity.name = getCookieObj.firstName + ' ' + getCookieObj.lastName;
            awayActivity.username = getCookieObj.username;
            awayActivity.type = getCookieObj.type;
            awayActivity.loginStatus = 'Login succeeded';
            awayActivity.deskStatus = this.awayOption;
            awayActivity.other = this.awayOther;
            awayActivity.duration = 0;
            this.adminAuthService.createAwayFromDesk(awayActivity).subscribe((res: any) => {
                this.activityId = res.data.id;
            });
        }
    }

    closeAwayFromDesk() {
        // console.log('Updating Away....');
        // Unfreeze when close away
        this.app.freezePageWhenAway = false;
        this.app.activeTopbarItem === null;

        this.adminAuthService.updateDeskDuration(this.activityId, this.awayTime).subscribe(() => { });
        this.awayTime = 0;
        this.awayOption = null;
        this.awayOther = '';
        this.activityId = null;
        clearInterval(this.awayCountingInterval);
    }

    getMessateNotification() {
        this.eventService.getMessateNotification(this.userInfo.username).subscribe((data) => {
            //    console.log(data);
            if (data.status === 200) {
                this.numberChatMsgNotification = data.data;
            }
        });
    }

    nextStep(step) {
        this.confirmationStep = step;
    }

    confirmAuthorizedNumber() {
        this.overtimeConfirmationService.list(null, null, null, null, this.confirmation.authorizedNumber).subscribe(res => {
            if (!res.data) {
                this.confirmationError = 'Invalid Authorized Number.';
            } else {
                this.overtimeConfirmation.authorized = true;
                this.overtimeConfirmation.lastModifiedBy = this.authService.getCurrentUsername();
                this.overtimeConfirmationService.save(this.overtimeConfirmation, this.authService.getCurrentLoggedInId()).subscribe(() => {
                    this.overtimeConfirmationAlert = false;
                });
            }
        });
    }

    confirm() {
        const body = {
            employeeId: this.authService.getCurrentLoggedInId()
        };
        if (this.confirmationStep === 1) {
            // Don't need overtime
            body['confirmAction'] = 'No';
        } else if (this.confirmationStep === 2) {
            // Need overtime but not authorized
            body['confirmAction'] = 'Yes';
            body['authorized'] = false;
        } else if (this.confirmationStep === 3) {
            // Confirm
            body['confirmAction'] = 'Yes';
            body['confirmedBy'] = this.confirmation.confirmedBy;
            body['confirmHour'] = this.confirmation.confirmHour;
            body['confirmMinute'] = this.confirmation.confirmMinute;
            body['authorizedNumber'] = this.confirmation.authorizedNumber;
            body['authorized'] = true;
            body['reason'] = this.confirmation.reason;
        } else if (this.confirmationStep === 4) {
            // Confirm
            body['confirmAction'] = 'Yes';
            body['confirmHour'] = this.confirmation.confirmHour;
            body['confirmMinute'] = this.confirmation.confirmMinute;
            body['authorized'] = false;
            body['reason'] = this.confirmation.reason;
            body['status'] = 'PENDING';
        }
        if (this.confirmationStep === 3) {
            this.overtimeConfirmationService.list(null, null, null, null, this.confirmation.authorizedNumber).subscribe(res => {
                if (!res.data) {
                    this.confirmationError = 'Invalid Authorized Number.';
                } else {
                    this.overtimeConfirmationService.save(body, this.authService.getCurrentLoggedInId()).subscribe();
                    this.overtimeAlert = false;
                    this.confirmationError = '';
                    this.confirmationStep = 0;
                    this.confirmation = {};
                    this.overtimeConfirmed = true;
                }
            });
        } else {
            if (this.confirmationStep === 4) {
                this.overtimeConfirmationService.save(body, this.authService.getCurrentLoggedInId()).subscribe(() => {
                    this.messageService.add({ severity: 'success', summary: 'Overtime request', detail: 'Your request has been successfully submitted' });
                });
            }
            this.overtimeAlert = false;
            this.confirmationError = '';
            this.confirmationStep = 0;
            this.confirmation = {};
            this.overtimeConfirmed = true;
        }
    }

    disabledConfirm() {
        return this.confirmationStep === 3 && (
            !this.confirmation.confirmedBy
            || this.confirmation.confirmedBy === ''
            || this.confirmation.confirmHour === null
            || this.confirmation.confirmHour === undefined
            || this.confirmation.confirmHour === ''
            || this.confirmation.confirmMinute === null
            || this.confirmation.confirmMinute === undefined
            || this.confirmation.confirmMinute === ''
            || !this.confirmation.authorizedNumber
            || this.confirmation.authorizedNumber === ''
            || !this.confirmation.reason
            || this.confirmation.reason === '');
    }

    disabledRequestOT() {
        return this.confirmationStep === 4 && (
            this.confirmation.confirmHour === null
            || this.confirmation.confirmHour === undefined
            || this.confirmation.confirmHour === ''
            || this.confirmation.confirmMinute === null
            || this.confirmation.confirmMinute === undefined
            || this.confirmation.confirmMinute === ''
            || !this.confirmation.reason
            || this.confirmation.reason === '');
    }

    isSubmitDisabled() {
        return !this.todo.regarding || (this.todo.startsDate === undefined);
    }

    filterRegardingSelect(event) {
        this.subjectSearchResults = [];
        for (let i = 0; i < this.regardings.length; i++) {
            const subject = this.regardings[i];
            if (subject.label.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
                this.subjectSearchResults.push(subject.value);
            }
        }
    }


    setEditor($event: any) {
        this.editor = $event.editor;
    }

    textChanged(event) {
        this.exceededMaxLength = false;
        this.exceededMinLength = false;
        this.concernMsgs = [];
        if (this.editor.getLength() <= 25) {
            this.exceededMinLength = true;
            this.charactersNumber = this.editor.getLength() - 1;
            return;
        } else {
            this.charactersNumber = this.editor.getLength() - 1;
        }
        if (this.editor.getLength() > this.MAX_LENGTH) {
            this.exceededMaxLength = true;
            this.editor.deleteText(this.MAX_LENGTH - 1, this.editor.getLength());
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: 'Error', detail: `You have exceeded the maximum text limit!` });
        }
        this.charactersNumber = this.editor.getLength() - 1;
    }

    disableEditor() {
        this.detailsEditor.readonly = true;
    }

    enableEditor() {
        this.detailsEditor.readonly = false;
    }
    addToDo() {
        this.concernMsgs = [];
        if (this.editor.getLength() <= 25) {
            this.messageService.add({ severity: 'info', summary: 'Note', detail: 'Please enter minimum 25 characters in \'Note\'!' });
            return;
        }
        if (this.addToDoClicked) { return; }
        this.addToDoClicked = true;
        const usernames = [];
        usernames.push(this.authService.getCurrentUsername());
        this.todo.scheduleTo = usernames.join(',');
        this.todo.companyId = this.authService.getCurrentCompanyId();
        const todoData: any = this.todo;
        this.addOffset();
        this.todoService.createTodo(todoData).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.addToDoNotes(resObj.data.id, this.todo.details);
                this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Concern added successfully!' });
                this.todoService.createConcernDetails({
                    userId: this.authService.getCurrentLoggedInId(),
                    date: new Date().setHours(0, 0, 0, 0),
                    hasConcern: true
                }).subscribe(() => {
                });
                this.show = false;
                this.addToDoClicked = false;
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error adding', detail: 'Error adding Concern, try again later!' });
                this.show = false;
                this.addToDoClicked = false;
            }
        }, error => {
            this.addToDoClicked = false;
        });
    }
    addOffset() {
        const timezone = moment.tz.guess();
        this.todo.timezone = timezone;
    }

    addToDoNotes(todoId: number, todoNote: string) {
        this.toDoNotes.todoId = todoId;
        this.toDoNotes.createdByUsr = this.authService.getUserInfo().username;
        this.toDoNotes.notes = todoNote;
        this.toDoNotesService.create(this.toDoNotes).subscribe(res => {

        });
    }

    handleNoAction() {
        this.show = false;
        this.todoService.createConcernDetails({
            userId: this.authService.getCurrentLoggedInId(),
            date: new Date().setHours(0, 0, 0, 0),
            hasConcern: false
        }).subscribe((res) => {
            if (res && res.status === 'SUCCESS') {
                // console.log('No concern audited.');
            }
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
        clearInterval(this.interval1);
        clearInterval(this.overtimeConfirmationInterval);
        this.counterIncreaseDirective.ngOnDestroy();
        this.counterDirective.ngOnDestroy();
        this.counterTimerRecordDirective.ngOnDestroy();
        this.subscriptions.unsubscribe();
        if (this.balanceUpdateObservable) {
            this.balanceUpdateObservable.unsubscribe();
        }

        this.resetTimerRecords(); 
    }

    toggleSound($event) {
        this.timeLeftToClockOut.timeLeftToClockOut = this.convertHourToMinute(this.count.toString());
        if (this.showVolume === true) {
            this.muteVolume = true;
            this.showVolume = false;
        } else {
            this.muteVolume = false;
            this.showVolume = true;
        }
    }

    toggleSoundInCrease($event) {
        this.countIncreaseTimeLeft = this.convertHourToMinute(this.countIncrease.toString());
        if (this.showVolume === true) {
            this.muteVolume = true;
            this.showVolume = false;
        } else {
            this.muteVolume = false;
            this.showVolume = true;
        }
    }


    convertHourToMinute(time: any) {
        if (time) {
            const temp = time.replace('.', ':').split(':');
            const minute = parseInt(temp[0], 10) * 60 * 60 + parseInt(temp[1], 10) * 60 + parseInt(temp[2], 10);
            return minute;
        }
        return 0;
    }

    keyDown(event) {
        if (event.key === '-') {
            event.preventDefault();
        }
    }

    submitAgreement() {
        const type = localStorage.getItem('_user');
        if (type === 'freelancer') {
            this.freelancerService.updateAgreement(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
                this.updateAgreement();
            });
        }
        if (type === 'admin') {
            this.platformAdminService.updateAgreement(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
                this.updateAgreement();
            });
        }
        if (type === 'employee') {
            this.employeeService.updateAgreement(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
                this.updateAgreement();
            });
        }

        if (type === 'client') {
            this.clientService.updateAgreement(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
                this.updateAgreement();
            });
        }

        if (type === 'guess') {
            this.membershipService.updateAgreement(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
                this.updateAgreement();
            });
        }
    }

    updateAgreement() {
        const userConfig = this.authService.getUserConfig();
        this.showAgreementDialog = false;
        userConfig.agreement = true;
        localStorage.removeItem('_config');
        localStorage.setItem('_config', JSON.stringify(userConfig));
    }

    showOnlyOkBtn() {
        if (this.checkAgreement) {
            this.showOkBtn = true;
        } else {
            this.showOkBtn = false;
        }
    }

    updateSupportTeamApproval(status: any) {
        const options = {
            id: this.todoId,
            status: status
        };
        this.operatorService.updateSupportTeamApproval(options).subscribe(res => {
            this.showPlanAssignmentDialog = false;
        });

    }

    async getTodoPlanNotification() {
        this.notificationCount = 0;
        this.notificationText = [];
        this.taggedNotificationCount = 0;
        this.taggedNotificationText = [];
        const todoPlans = {
            items: 0,
            keys: [],
            ids: []
        };
        const options = <any>{};
        if (this.userInfo) {
            options.targetId = this.userInfo.adminId;
            options.targetRoleId = this.userInfo.role ? this.userInfo.level : null;
        }
        options.status = 1;
        this.operatorService.getTodoPlanNotification(options).subscribe((res: any) => {
            if (res.status === 'SUCCESS' && res.data && res.data.length > 0) {
                res.data.forEach((item) => {
                    if (item.type === 'task_tagged' || item.type === 'crm_task_tagged' || item.type === 'crm_task_comment_tagged') {
                        this.taggedNotificationText.push({
                            text: `${item?.createdByUsr} @mentioned you :`,
                            message: item.message,
                            usr: item.createdByUsr,
                            type: item.type,
                            key: item.tableId,
                            id: item.id,
                            time: item.createdAt,
                            taskInternalId: item.taskInternalId ? item.taskInternalId : null,
                            projectId: item.projectId ? item.projectId : null,
                            taskName: item.taskName ? item.taskName : ''
                        });
                        this.taggedNotificationCount++;
                    } else if (item.type === 'follow_up_note') {
                        this.taggedNotificationText.push({
                            text: `${item?.createdByUsr} @mentioned you :`,
                            message: item.message,
                            usr: item.createdByUsr,
                            type: item.type,
                            id: item.id,
                            time: item.createdAt,
                            projectId: item.projectId ? item.projectId : null,
                            urlLink: item.urlLink ? item.urlLink : ''
                        });
                        if (item.status !== 1) {
                            this.taggedNotificationCount++;
                        }
                    } else {
                        todoPlans.items++;
                        todoPlans.keys.push(item.tableId);
                        todoPlans.ids.push(item.id);
                        this.notificationCount++;
                    }
                });
                const foundIndex = this.notificationText.findIndex(item => item.type != 'plan_assigned');
                if (foundIndex < 0) {
                    this.notificationText.push({
                        text: 'Work Order ' + todoPlans.items,
                        iconName: 'today',
                        type: 'plan_assigned',
                        keys: todoPlans.keys,
                        ids: todoPlans.ids
                    });
                }  else {
                    this.notificationText[foundIndex] = {
                        text: 'Work Order ' + todoPlans.items,
                        iconName: 'today',
                        type: 'plan_assigned',
                        keys: todoPlans.keys,
                        ids: todoPlans.ids
                    };
                }
                this.notificationText = _.uniqBy((this.notificationText || []), 'text');
            }
        });

    }

    displayName(value) {
        if (value) { return value.match(/\b(\w)/g).join('').slice(0, 2).toUpperCase(); }
    }

    routeTodoPlan(value) {
        if (value.type === 'crm_task_tagged' || value.type === 'crm_task_comment_tagged') {
            const slug = `${value.taskInternalId}-${value.taskName.replace(/ /g, '-')}`;
            const queryParams = { t: slug };
            this.router.navigate(['/app/tm',
                this.taskManagementService.encodeId(value.projectId)], {
                queryParams
            });
            this.operatorService.deleteMentionedNotification(value.id).subscribe((res) => {
                this.getTodoPlanNotification();
            });
        } else if (value.type === 'task_tagged') {
            this.router.navigate([`/app/todo/view/${value.key}`]);
            this.operatorService.deleteMentionedNotification(value.id).subscribe((res) => {
                this.getTodoPlanNotification();
            });
        } else if (value.type === 'follow_up_note') {
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };
            this.operatorService.updateStatusMentionedNotification(value.id, 1).toPromise();
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([`${value.urlLink}`]);
        }
    }

    //
    ngAfterViewInit() {
        // set the initial state of the video
        if (this.video) {
            const video: HTMLVideoElement = this.video.nativeElement;
            video.muted = false;
            video.controls = true;
            video.autoplay = true;
        }
    }

    successCallback(stream: MediaStream) {
        stream.addEventListener('ended', () => { this.stopRecording(); }, false);
        stream.addEventListener('inactive', () => { this.stopRecording(); this.download(); }, false);
        stream.getTracks().forEach(value => {
            value.addEventListener('ended', () => this.stopRecording(), false);
            value.addEventListener('inactive', () => this.stopRecording(), false);
        });
        this.isRecording = true;
        const options = {
            mimeType: 'video/webm;codecs=h264',
            frameRate: 30
        };
        this.stream = stream;
        this.recordRTC = RecordRTC(stream, options);
        this.startTimer();
        // this.recordRTC.startRecording();
        // const video: HTMLVideoElement = this.video.nativeElement;
        // video.srcObject = stream;
    }

    errorCallback() {

    }

    processVideo(audioVideoWebMURL) {
        // const video: HTMLVideoElement = this.video.nativeElement;
        // const recordRTC = this.recordRTC;
        // video.src = video.srcObject = null;
        // video.src = audioVideoWebMURL;
        // const recordedBlob = recordRTC.getBlob();
        // recordRTC.getDataURL(function (dataURL) { });
    }

    startRecordingScreen() {
        const mediaConstraints = {
            audio: true,
            video: { width: 1920, height: 1080 }
        };
        const media: any = navigator.mediaDevices;
        media.getDisplayMedia(mediaConstraints)
            .then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }

    stopRecording() {
        this.isRecording = false;
        const recordRTC = this.recordRTC;
        recordRTC.stopRecording(this.processVideo.bind(this));
        const stream = this.stream;
        stream.getAudioTracks().forEach(track => track.stop());
        stream.getVideoTracks().forEach(track => track.stop());
        this.showLimit = false;
        clearInterval(this.limitInterval);
    }

    download() {
        // this.recordRTC.save('Operr' + new Date().getTime() + '.webm');
        const file = new File([this.recordRTC.getBlob()], 'Operr' + new Date().getTime() + '.webm', { type: 'video/webm', lastModified: new Date().getTime() });
        // console.log('stop file: ' + file.name);
        // this.documentService.convertStreams(file, 'record-screen', 1, 'record-screen', 'Operr' + new Date().getTime() + '.webm')
        //     .subscribe(res => {
        //         importedSaveAs(res,  'Operr' + new Date().getTime() + '.mp4');
        //     });
        // console.log('upload file: ' + file.name);
        this.documentService.saveVideo(file, 'record-screen', 1, 'record-screen', 'Operr' + new Date().getTime() + '.webm', 'Operr-work')
            .subscribe((res: any) => {
                if (res.status === 'SUCCESS' && res.data && res.data.fileUrl) {
                    if (this.auto) {
                        this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'Video Automatically Saved' });
                    } else {
                        this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'Video Saved' });
                    }
                    const popUp = window.open(res.data.fileUrl);
                    if (popUp == null || typeof (popUp) == 'undefined') {
                        // alert('Please disable your pop-up blocker and try again.');
                        this.showPopupVideoRecording = true;
                        this.videoRecordingUrl = res.data.fileUrl;
                    } else {
                        popUp.focus();
                    }

                }
            });
    }

    companySurveySetup() {
        this.surveySetupService.searchSurveySetup({ companyId: this.authService.getCurrentCompanyId() }).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.selectedSurveySetup = resObj.data.content[0];
            }
        });
    }

    getEmployeeById() {
        this.employeeService.getEmployeeByIdCustom(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                this.employee = res.data;
            }
        });
    }

    getPerformanceCriteria() {
        const options: any = {
            companyId: 1,
            type: 'Survey'
        };
        this.performanceService.searchPerformanceCriteria(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employeePerformances = resObj.data.content;
                // console.log(this.employeePerformances);
                setTimeout(() => {
                    this.updateRowGroupMetaData();
                }, 1000);
            }
        }, (_error) => console.error(_error));
        this.getEmployeeById();

    }

    calculateTotals(performancesList: EmployeePerformance[]) {
        let sum = 0;
        performancesList.forEach((performance: EmployeePerformance) => {
            if (performance.value) {
                sum = sum + +performance.value;
            }
        });
        return sum;
    }

    updateCategoryValue(rowData) {
        this.employeePerformances.forEach((val, index) => {
            if (val.criteriaCategory.name === rowData.criteriaCategory.name
                && val.criteriaName === rowData.criteriaName) {
                val.value = rowData.value;
            }
        });
    }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};
        if (this.employeePerformances) {
            for (let i = 0; i < this.employeePerformances.length; i++) {
                const rowData = this.employeePerformances[i];
                const category = rowData.criteriaCategory.name;
                if (i === 0) {
                    this.rowGroupMetadata[category] = { index: 0, size: 1 };
                } else {
                    const previousRowData = this.employeePerformances[i - 1];
                    const previousRowGroup = previousRowData.criteriaCategory.name;
                    if (category === previousRowGroup) {
                        this.rowGroupMetadata[category].size++;
                    } else {
                        this.rowGroupMetadata[category] = { index: i, size: 1 };
                    }
                }
            }
        }
    }

    isNewCategory(categoryId, rowIndex: number) {
        return this.rowGroupMetadata && this.rowGroupMetadata[categoryId] &&
            this.rowGroupMetadata[categoryId].index === rowIndex;
    }

    saveSurvey() {
        const payload = [];
        const date = new Date();
        this.employeePerformances.forEach(ele => {
            const survey = new EmployeePerformance(ele, this.employee, ele.value, date, date, this.selectedSurveySetup.id);
            payload.push(survey);
        });
        this.employeePerformanceService.createAll(payload).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.messageService.add({ severity: 'info', summary: 'Created', detail: 'Employee survey submitted successfully!' });
                this.showSurvey = false;
            }
        }, error1 => {
            this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to submit Employee survey' });
        });
    }

    surveyPopup() {
        const payload = {
            companyId: this.authService.getCurrentCompanyId(),
            currentDate: new Date(),
            userId: this.authService.getCurrentLoggedInId(),
            userType: 'EMPLOYEE',
            displaySurveyPopUp: this.isOpenClockingReminder ? 1 : 2
        };
        this.surveySetupService.displayPopup(payload).subscribe((res: any) => {
            if (res.status === 'SUCCESS' && res.data && res.data.length) {
                this.showSurvey = true;
            }
        });
    }

    async getAllBillingInvoice() {
        this.invoiceNumberFilterList = [];
        const option: any = {};
        option.companyId = this.authService.getCurrentCompanyId();
        const listInvoiceNumber = await this.paymentInvoiceService.getAllBillingInvoice(option).toPromise();
        if (listInvoiceNumber && listInvoiceNumber.data.length > 0) {
            listInvoiceNumber.data.forEach(invoice => {
                const item: any = {};
                item.label = invoice.invoiceNumber;
                if (invoice.status === 'Inactive') {
                    item.routerLink = ['/app/payments/old-invoice'];
                } else {
                    item.routerLink = ['/app/payments/invoice'];
                }
                item.type = 'invoice';
                item.clientId = invoice.clientId;
                this.invoiceNumberFilterList.push(item);
                this.menuList.push(item);
            });
        }
    }

    async getBillingInvoiceByClient() {
        this.invoiceNumberFilterList = [];
        const option: any = {};
        option.companyId = this.authService.getCurrentCompanyId();
        option.clientId = this.authService.getCurrentLoggedInId();
        option.ignoreInvoiceDetails = true;
        option.oldInvoiceOnly = true;
        option.page = 0;
        option.size = 99999;
        const listInvoiceNumber = await this.paymentInvoiceService.filter(option).toPromise();
        if (listInvoiceNumber && listInvoiceNumber.data && listInvoiceNumber.data.content.length > 0) {
            listInvoiceNumber.data.content.forEach(invoice => {
                const item: any = {};
                item.label = invoice.invoiceNumber;
                if (invoice.status === 'Inactive') {
                    item.routerLink = ['/app/payments/old-invoice'];
                } else {
                    item.routerLink = ['/app/payments/invoice'];
                }
                item.type = 'invoice';
                item.clientId = invoice.clientId;
                this.invoiceNumberFilterList.push(item);
                this.menuList.push(item);
            });
        }
    }

    startTimer() {
        this.timeLeft = 3;
        this.showCountDown = true;
        this.timeLimit = 0;
        this.auto = false;
        this.messageService.add({ severity: 'info', summary: 'Recording', detail: 'Started Recording Video' });
        this.countInterval = setInterval(() => {
            if (this.timeLeft === 1) {
                this.showCountDown = false;
                clearInterval(this.countInterval);
                setTimeout(() => {
                    this.showLimit = true;
                    this.recordRTC.startRecording();
                    this.limitScreenRecorder();
                }, 1000);
                return;
            }
            if (this.timeLeft > 0) {
                this.timeLeft--;
            }
        }, 1000);
    }


    limitScreenRecorder() {
        this.timeLimit = 0;
        this.limitInterval = setInterval(() => {
            if (this.timeLimit === 300) {
                this.showLimit = false;
                this.auto = true;
                clearInterval(this.limitInterval);
                this.stopRecording();
                return;
            }
            if (this.timeLimit < 300) {
                this.timeLimit++;
            }
            this.displayTime = this.transform(this.timeLimit);
        }, 1000);
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        let seconds: any;
        let mins: string;
        if ((value - minutes * 60) < 10) {
            seconds = '0' + (value - minutes * 60);
        } else {
            seconds = value - minutes * 60;
        }
        if (minutes < 10) {
            mins = '0' + minutes;
        }
        return mins + ':' + seconds;
    }

    onDashBoard() {
        if (this.authService.isEmployeeRole()) {
            this.dashBoardForRole = '/app/employee-dashboard';
        }
        if (this.authService.adminRole()) {
            this.dashBoardForRole = '/app/dashboard';
        }
        if (this.authService.isContractorRole()) {
            this.dashBoardForRole = `/app/crm/freelancer/edit/${this.authService.getCurrentLoggedInId()}`;
        }
        if (this.authService.isClientRole()) {
            this.dashBoardForRole = `/app/client/dasboard`;
        }
        if (this.authService.isGuestRole()) {
            this.dashBoardForRole = `/app/tm`;
        }
        if (this.authService.isChildClientRole()) {
            this.dashBoardForRole = '/app/job-follow-up/billing-total-job';
        }
        this.router.navigate([this.dashBoardForRole]);
    }

    saveUsersOnline() {
        const user = this.authService.getUserInfo();
        const firstLastName = (!user.firstName || !user.lastName) ? user.username : user.firstName + ' ' + user.lastName;
        const options = {
            userId: user.adminId,
            userName: user.username,
            userType: this.authService.getUserType(),
            firstLastName: firstLastName,
            companyId: this.authService.getCurrentCompanyId()
        };
        this.companyService.saveUsersOnline(options).subscribe((res: any) => {
            // console.log(res)
        });
    }

    clickPrivacy() {
        this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Coming Soon...' });
    }

    isAdmin() {
        if (this.authService.isSuper() || this.authService.isSubSuper()
            || this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) { return true; }
        return false;
    }

    companyOtherSetting() {
        this.companyService.getCompanySetting(this.authService.getCurrentCompanyId()).subscribe(res => {
            this.shortArray = [];
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.companySetting = resObj.data
                if (resObj.data && resObj.data.shortcutsCheckBoxes?.length > 0) {
                    this.shortcuts.forEach(el => {
                        if (this.authService.isClientRole()) {
                            if (resObj.data.shortcutsCheckBoxes.includes(el.value.toString())
                                && el.role && el.role.includes('CLIENT')
                            ) {
                                this.showShortArray = true;
                                this.shortArray.push(el);
                            }
                        } 
                        else if (this.authService.isEmployeeRole()) {
                            if (resObj.data.shortcutsCheckBoxes.includes(el.value.toString())
                                && el.role && el.role.includes('EMPLOYEE')
                            ) {
                                this.showShortArray = true;
                                this.shortArray.push(el);
                            }
                        } else {
                            if (resObj.data.shortcutsCheckBoxes.includes(el.value.toString())) { this.shortArray.push(el); }
                        }
                    });
                    if (!this.isPlatformAdmin) {
                        this.shortArray = this.shortArray.filter(item => item.label != 'Platform Admin');
                    }
                }
                localStorage.removeItem('_shortcutSetting');
            }
        });
    }

    getCompany() {
        this.companyService.getCompanyByCompanyId(this.authService.getCurrentCompanyId()).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.company = resObj.data;
                localStorage.setItem('_infoCompany', JSON.stringify(this.company));
            }
        });
    }

    refreshCall() {

        window.location.reload();

        this.isRefresh = false;

    }

    getPendingInvoice() {
        this.pendingTransaction = [];
        this.paymentInvoiceService.filter({
            listStatus: ['Pending'],
            companyId: this.authService.getUserInfo().companyId,
            clientId: this.isClientAccount ? this.authService.getUserInfo().adminId : null,
            oldInvoiceOnly: false,
            ignoreInvoiceDetails: true
        }).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                res.data.content.forEach(t => {
                    if (!this.pendingTransaction.find(id => id === t.id)) {
                        this.pendingTransaction.push(t.id);
                    }
                });
            }
        });

    }

    goToPendingInvoice() {
        if (this.router.url.includes('/app/payments/invoice')) {
            this.notificationService.notifyInvoiceStatusChanged('Pending');
        } else {
            this.router.navigate(['/app/payments/invoice'], {
                queryParams: {
                    status: 'Pending'
                }
            });
        }
    }

    async getNewPaymentInvoice() {
        this.newPaymentTransaction = [];
        const option: any = {
            size: 20,
            page: 0,
            companyId: this.authService.getCurrentCompanyId(),
            sortField: 'createdAt',
            sortOrder: 'DESC'
        };
        if (this.newPaymentLastSeenTime) {
            option.fromDate = this.newPaymentLastSeenTime;
            option.toDate = new Date().getTime();
        }
        const newPaymentRes: any = await this.paymentTransactionService.search(option).toPromise();
        if (newPaymentRes.status === 'SUCCESS') {
            newPaymentRes.data.content.forEach(t => {
                if (this.newPaymentTransaction.length >= 3) {
                    return;
                }
                if (t.invoiceNumber && t.invoiceNumber.length > 0 && !this.newPaymentTransaction.find(invoiceNumber =>
                    invoiceNumber === t.invoiceNumber[0]) && (!this.newPaymentLastSeenTime || new Date(t.createdAt).getTime() > this.newPaymentLastSeenTime)) {
                    this.newPaymentTransaction.push(t.invoiceNumber[0]);
                }
            });
        }
    }

    goToNewPaymentInvoice() {
        this.firebaseNotificationService.saveFirebaseNotification(`/latest-seen-payment/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`, {
            lastSeenTime: new Date().getTime()
        }).subscribe(() => {
            if (this.router.url.includes('/app/payments/invoice')) {
                this.notificationService.notifyInvoiceNumbersChanged(this.newPaymentTransaction.join(','));
            } else {
                this.router.navigate(['/app/payments/invoice'], {
                    queryParams: {
                        invoices: this.newPaymentTransaction.join(',')
                    }
                });
            }
        });
    }

    markForNofificationUploadTotalJob(url, payload) {
        this.firebaseNotificationService.saveFirebaseNotification(url, payload).subscribe(() => {
            // console.log('Change status done');
        });
    }

    acceptUploadTotalJob() {
        this.showPopupTotalJobUpload = false;
    }

    acceptUploadBillingReport() {
        this.showPopupBillingReport = false;
    }

    acceptUploadLogDetail() {
        this.showPopupLogDetailReport = false;
    }

    acceptCorrectionReport() {
        this.showPopupCorrectionReport = false;
    }

    acceptLogTotalJob() {
        this.showPopupLogTotalJob = false;

    }

    acceptLogDetailReport() {
        this.showPopupLogDetailReport = false;
    }

    getClientList() {
        const options: any = {
            status: 1
        };
        this.clientService.getClientDropdown(options).subscribe(res => {
            const resObj: any = res;
            this.clientList = [];
            if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
                resObj.data.forEach(client => {
                    this.clientList.push({ label: client.value, value: client.key });
                });
                this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
            }
        });
    }

    checkSytemMaintain() {
        this.contentList = [];
        const options: any = {
            page: 0,
            size: 20,
        };
        this.maintain = false;
        this.maintainService.checkSystemMaintain(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.contentList = resObj.data.content;
                if (this.contentList.length > 0) {
                    this.maintain = true;
                    this.textMaintain = this.contentList[0].websiteNotice + ' - Start Time: ' + this.datePipe.transform(this.contentList[0].startTime, 'dd/MM/yyyy HH:mm:ss') + ' - End Time: ' + this.datePipe.transform(this.contentList[0].endTime, 'dd/MM/yyyy HH:mm:ss');
                }
            } else {
              this.contentList = [];
              this.maintain = false;
            }
          }, error => {
            this.contentList = [];
            this.maintain = false;
        });
    }

    openVideoRecording() {
        this.showPopupVideoRecording = false;
        window.open(this.videoRecordingUrl);
    }
    openUserAgreement() {
        this.showPopupUserAgreement = true;
    }
    closeUserAgreement() {
        this.showPopupUserAgreement = false;
    }
    openUserPrivacy() {
        this.showPopupUserPrivacy = true;
    }
    closeUserPrivacy() {
        this.showPopupUserPrivacy = false;
    }

    goToWalker() {
        this.firebaseNotificationService.saveFirebaseNotification(`/notification-walker/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`, {
        }).subscribe(() => {
            this.router.navigate(['/app/ele-campaign/walker/list'], {});
        });
    }

    async getNotificationMessage() {
        const destinationUsers = [];
        if (this.authService.getRoleLevel() === RoleLevel.ROLE_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_SUB_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_COMPANY_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_SUB_COMPANY_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_AGENCY_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_SUB_AGENCY_ADMIN
        ) {
            destinationUsers.push(1);
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_EMPLOYEE) {
            destinationUsers.push(2);
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_CONTRACTOR) {
            destinationUsers.push(3);
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_CLIENT) {
            destinationUsers.push(4);
        } else if (this.authService.getRoleLevel() === RoleLevel.GUESS) {
            destinationUsers.push(5);
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_CHILD_CLIENT) {
            destinationUsers.push(6);
        }
        this.subscriptions.add(this.db.list(`/notification/${destinationUsers[0]}/${this.authService.getCurrentCompanyId()}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            // console.log('notification event', event);
            if (event && event.length > 0) {
                this.newNotificationMessage = [];
                const option: any = {
                    size: 99999,
                    page: 0,
                    companyId: this.authService.getCurrentCompanyId(),
                    destinationUsers: destinationUsers,
                    sortField: 'createdAt',
                    sortOrder: 'DESC',
                    isRead: true
                };
                // const newNotificationMessageRes: any = await this.notificationService.search(option).toPromise();
                this.notificationService.search(option).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.totalNewNotificationMessage = resObj.data.content.length;
                    }
                });
            }
        }));
    }

    confirmJobReportPopupReminder() {
        this.firebaseNotificationService.saveFirebaseNotification(`/job-report-reminder/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`, {
            status: 'SEEN_YES',
            time: new Date().getTime()
        }).subscribe(() => {
            this.showJobReportPopupReminder = false;
            // this.router.navigate(['/app/employee/employee-job-report/list']);
        });

        const checkRequest: any = {
            companyId: this.authService.getCurrentCompanyId(),
            reportDate: moment(moment(new Date()).toDate()).utc(true).toDate(),
            userName: this.authService.getUserInfo().username
        };
        this.employeeJobReportService.getJobReportExistedToDay(checkRequest).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.showJobReportPopupReminder = false;
                if (resObj.data && resObj.data.id) {
                    this.router.navigate(['/app/employee/employee-job-report/edit/', resObj.data.id]);
                } else {
                    this.router.navigate(['/app/employee/employee-job-report/add']);
                }
            }
        });
    }

    rejectJobReportPopupReminder() {
        this.firebaseNotificationService.saveFirebaseNotification(`/job-report-reminder/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`, {
            status: 'SEEN_NO',
            time: new Date().getTime()
        }).subscribe(() => {
            this.companySettingServiceV2.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    if (res.data.employeeMissingPunchNotificationEmail) {
                        const emailPayload = {
                            toEmails: [res.data.employeeMissingPunchNotificationEmail],
                            subject: 'Job Report Reminder',
                            body: `${this.authService.getUserInfo().firstName} ${this.authService.getUserInfo().lastName} is missing Job Report`,
                            emailCompanyId: this.authService.getUserInfo().companyId,
                        };
                        this.notificationService.sendTextEmail(emailPayload).subscribe();
                    }
                }
            });
            this.showJobReportPopupReminder = false;
        });
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const button = this.buttonConfirmJobReportPopupReminder.nativeElement as HTMLButtonElement;
        if (event.target === button) {
            event.preventDefault();
        }
    }

    async getClientListData() {
        const options: any = {};
        options.size = 9999;
        options.page = 0;
        options.userId = this.authService.getCurrentLoggedInId();
        options.agency = this.authService.getCurrentAgency() ? this.authService.getCurrentAgency() : 0;
        options.companyId = this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null;
        this.clientListData = [];
        const listInvoiceNumber = await this.clientService.getAllClient(options).toPromise();
        this.clientListData = listInvoiceNumber.data;
        this.clientListData.forEach(invoice => {
            const itemName: any = {};
            itemName.label = invoice.name;
            itemName.routerLink = ['/app/crm/client/list'];

            itemName.clientId = invoice.id;
            itemName.type = 'client';
            this.menuList.push(itemName);
        });
    }

    readNotification() {
        const destinationUsers = [];
        let index = 0;
        if (this.authService.getRoleLevel() === RoleLevel.ROLE_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_SUB_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_COMPANY_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_SUB_COMPANY_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_AGENCY_ADMIN
            || this.authService.getRoleLevel() === RoleLevel.ROLE_SUB_AGENCY_ADMIN
        ) {
            index = 1;
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_EMPLOYEE) {
            index = 2;
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_CONTRACTOR) {
            index = 3;
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_CLIENT) {
            index = 4;
        } else if (this.authService.getRoleLevel() === RoleLevel.GUESS) {
            index = 5;
        } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_CHILD_CLIENT) {
            index = 6;
        }

        // console.log('destinationUsers', destinationUsers);
        // this.newNotificationMessage = [];
        // const option: any = {
        //     size: 99999,
        //     page: 0,
        //     companyId: this.authService.getCurrentCompanyId(),
        //     destinationUsers: destinationUsers,
        //     sortField: 'createdAt',
        //     sortOrder: 'DESC',
        //     isRead: true
        // };
        // this.notificationService.search(option).toPromise();
        this.totalNewNotificationMessage = 0;
        this.firebaseNotificationService.saveFirebaseNotification(`/notification/${index}/${this.authService.getCurrentCompanyId()}`, {
            ...destinationUsers,
        }).subscribe();
    }
    getEmployeesList(name) {
        const options = {
            page: 0,
            size: 5,
            sortField: 'createdAt',
            sortOrder: 'DESC',
            type: 'employee',
            companyId: this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null,
            name
        };
        this.employeeService.searchEmployee(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employees = resObj.data.content;
                this.employees = this.employees.map(item => {
                    return {
                        label: item.fullName,
                        id: item.id,
                        companyId: item.companyId,
                        workHours: item.workHours,
                        type: 'employee'
                    };
                });
                this.systemSearchList.unshift(...this.employees);
                if (this.employees.length) {
                    this.systemSearchList = this.systemSearchList.filter(item => item.label !== 'NOT FOUND');
                }
            }
        });
    }
    getContractorsList(name) {
        const options = {
            page: 0,
            size: 5,
            sortField: 'id',
            sortOrder: 'DESC',
            companyId: this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null,
            name
        };
        this.contentService.filter(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.contractors = resObj.data.content;
                this.contractors = this.contractors.map(item => {
                    return {
                        label: item.fullName,
                        id: item.id,
                        companyId: item.companyId,
                        workHours: item.workHours,
                        type: 'contractor'
                    };
                });
                this.systemSearchList.unshift(...this.contractors);
                if (this.contractors.length) {
                    this.systemSearchList = this.systemSearchList.filter(item => item.label !== 'NOT FOUND');
                }
            }
        });
    }

    navigateToCard() {
        this.updateExpiredCardModal = false;
        this.router.navigate(['app/payments/detail']);
    }

    getCardDetails(isLogin) {
        if (isLogin) {
            const userId = this.authService.getUserInfo().adminId;
            const userType = this.authService.getPaymentUserType();
            this.paymentProfileService.getUserCardList(userId, userType).subscribe((response: any) => {
                if (response.status === 200) {
                    const userCardList = response.data;
                    const paymentTransaction = <any>{
                        roleId: this.authService.getRoleLevel(),
                        userId: this.authService.getCurrentLoggedInId(),
                        companyId: this.authService.getCurrentCompanyId()
                    };
                    this.paymentCardDefaultService.getDefaultCard(paymentTransaction).subscribe(res => {
                        const resObj: any = res;
                        if (resObj.status === 'SUCCESS') {
                            if (resObj.data && (resObj.data.cardId || resObj.data.bankId)) {
                                const card = userCardList.find(item => item.id === (resObj.data.cardId || resObj.data.bankId));
                                if (card) {
                                    const momentDate = moment(card.expire, 'MM/YYYY').add(1, 'M');
                                    if (moment().toDate().getTime() >= momentDate.toDate().getTime()) {
                                        this.updateExpiredCardModal = true;
                                    }
                                }
                            }
                        }
                    });
                }
            }, err => {
                console.log(err);
            });
        }
    }

    loadBalance() {
        this.paymentProfileService.getAccountBalance({
            app_user_id: this.authService.getUserInfo().adminId,
            user_type: this.authService.getPaymentUserType()
        }).subscribe((balanceRes: any) => {
            if (balanceRes.status === 200) {
                this.accountId = balanceRes.data.accountId;
                this.accountBalance = balanceRes.data;
            }
        });
    }

    loadPaymentMethod() {
        forkJoin([
            this.paymentProfileService.listBankAccount(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()),
            this.paymentProfileService.getUserCardList(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()),
            this.paymentCardDefaultService.getDefaultCard({
                roleId: this.authService.getRoleLevel(),
                userId: this.authService.getCurrentLoggedInId(),
                companyId: this.authService.getCurrentCompanyId()})
        ]).subscribe((res: any[]) => {
            const customerBanks = [];
            if (res[0].data && res[0].data && res[0].data.content && res[0].data.content.length > 0) {
                res[0].data.content.forEach(data => {
                    let paymentMethod = data;
                    if (paymentMethod) {
                        paymentMethod = {
                            ...paymentMethod,
                            status: data.status,
                            bankId: data.id,
                        };
                        customerBanks.push(paymentMethod);
                    }
                });
            }
            const customerCards = res[1] && res[1].data && res[1].data !== 'User not found' ? res[1].data : [];
            this.defaultMethod = res[2].data;
            this.customerCard = customerCards.find(c => c.id === this.defaultMethod.cardId);
            this.customerBank = customerBanks.find(c => c.id === this.defaultMethod.bankId);
            if (!this.customerCard) {
                this.customerCard = customerCards[0];
            }  else {
                this.selectedMethod = this.customerCard;
            }
            if (!this.customerBank) {
                this.customerBank = customerBanks[0];
            }  else {
                this.selectedMethod = this.customerBank;
            }
            if (this.customerBank) {
                this.customerBank.lastFourNumbers = this.customerBank.lastFourNumbers.replace('xxxx', '');
            }
        });
    }

    openAddAmountModal() {
        this.selectedFeeAmount = Number(this.selectedAmount) * (this.transferFeeCashOut || 6.5) / 100;
        this.showAddAmountConfirmDialog = true;
    }

    addBalanceAccount() {
        if (!this.accountId) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please create a link account on your walllet' });
            this.redirectToWallet();
            return;
        }
        if (!this.selectedAmount || this.selectedAmount < 1) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter amount greater than 1' });
            return;
        }
        this.addAmountSummiting = true;
        if (this.selectedAmount) {
            const payload = {
                app_user_id: this.authService.getUserInfo().adminId,
                amount: Number(this.selectedAmount),
                customer: this.selectedMethod.accountId,
                source: this.selectedMethod.customerCardId || this.selectedMethod.customerBankToken,
                destination: this.accountId,
                user_type: this.authService.getPaymentUserType(),
                created_by_user: this.authService.getUserInfo().username,
                fee: this.selectedFeeAmount,
                metadata: {
                    customer: this.selectedMethod.accountId,
                    source: this.selectedMethod.customerCardId || this.selectedMethod.customerBankToken,
                    destination: this.accountId
                },
                memo: `Add fun from ${this.selectedMethod.customerCardId
                    ? this.selectedMethod?.paymentCardType + ' Ending ' + this.selectedMethod?.lastFourNumbers :
                    'Bank account Ending ' + this.selectedMethod?.lastFourNumbers} to wallet`,
            };
            this.paymentProfileService.destinationCharge(payload).subscribe((res: any) => {
                if (res.status === 200) {
                    this.selectedAmount = null;
                    this.selectedFeeAmount = null;
                    this.showAddAmountConfirmDialog = false;
                    this.paymentProfileService.notifyBalanceWalletChange();
                    this.walletTransactionService.sync(res.data.transactionPaymentId).subscribe();
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Balance amount added successfully' });
                    this.loadBalance();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'error', detail: res.data });
                }
                this.addAmountSummiting = false;
            }, (error) => {
                this.addAmountSummiting = false;
            });
        }
    }

    redirectToWallet() {
        this.router.navigate(['/app/payments/wallet']);
    }

    onChangeMethodSelected() {
        if (this.changeMethodSelected) {
            this.selectedMethod = this.changeMethodSelected;
        }
        this.changeMethodSelected = null;
        this.changeMethodModal = false;
    }

    onChangeMethodClose() {
        this.changeMethodSelected = null;
        this.changeMethodModal = false;

    }

    getPlanName(id: any) {
        if (this.planList) {
            const data: any = this.planList.filter((val) => {
                return val.value === id;
            });
            return data && data.length > 0 ? data[0].label : '';
        }
    }

    getPlanList() {
        this.planList = [];
        const adminType = ['PLAN'];
        this.companyService.getAdminType(adminType).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.groups = resObj.data;
                if (this.groups.length > 0 ) {
                    this.groups.forEach(group => {
                        const groupName = group.name.split(/\s+/).map(e => _.capitalize(e)).join('');
                        this.planList.push({value: group.id, label : groupName });
                    });
                }
                localStorage.setItem('_planList', JSON.stringify(this.planList));
            }
        }, err => {});
    }

    getType(type: string) {
        switch (type) {
            case '1':
                return 'Sole proprietorship';
            case '2':
                return 'Partnership';
            case '3':
                return 'Corporation';
            case '4':
                return 'Limited Liability Company (LLC)';
            case '5':
                return 'Churches/ministries';
            default:
                return '';
        }
    }
   getAllDocument() {
    this.documentSettingsService.search({type: 'default', companyId: this.authService.getCurrentCompanyId(), forPolicy: true}).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
            this.allDocuments = res.data || [];
            this.allDocuments = this.allDocuments.filter(item => item.isVisible);

            this.originalDocuments = this.allDocuments;
            const requiredDocumentIds = this.companySetting.documentId || [];
            const requiredCompliances = this.allDocuments.filter(doc => doc.isRequired);
            const otherCompliances = this.allDocuments.filter(doc => !doc.isRequired);

            // Check completion status based on `fileName` or another field that indicates completion
            this.documentDetails = this.allDocuments.map(document => {
                const isCompleted = document.fileName || document.fileUrls || document.file;  // Adjust based on actual data
                return {
                    template: {
                        name: document.documentName,
                        fields: [getDefaultField()]
                    },
                    file: isCompleted ? document.fileName : null,  // Set file if the document has been completed
                    fileName: isCompleted ? document.fileName : null,
                    fileImageSrc: isCompleted ? document.fileUrl : '',
                    fileUrls: isCompleted ? document.fileUrls || [] : [],
                    fileType: '',
                    addExpDate: document.addExpDate,
                    confirmSetting: document.confirmSetting,
                    confirmed: isCompleted ? document.confirmed : null,
                    expirationDate: isCompleted ? document.expirationDate : null,
                    document: {},
                    key: document.documentName.replace(/[^a-zA-Z0-9]+/g, '_').toUpperCase(),
                    finish: !!isCompleted,  // Mark as finished if the document has a file or fileUrl
                    collapsed: true,
                    complianceType: document.isRequired ? 'required' : 'other'  // Ensure compliance type is set
                };
            });

            // Sort by complianceType and name
            this.documentDetails.sort((a, b) => {
                if (a.complianceType === b.complianceType) {
                    return a.template.name.localeCompare(b.template.name);
                }
                return a.complianceType === 'required' ? -1 : 1;
            });

            // Apply pagination
            this.paginateDocumentDetails();
        }
    });
}
    paginateDocumentDetails() {
        const pageSize = 8;
        this.paginatedDocumentDetails = [];
    
        for (let i = 0; i < this.documentDetails.length; i += pageSize) {
            const pageData = this.documentDetails.slice(i, i + pageSize);
            this.paginatedDocumentDetails.push({ id: (i / pageSize) + 1, data: pageData });
        }
    
        this.currentPage = 0;  // Start at the first page
    }
        

    getEmployeeCompliance(allDocuments) {
        const options = <any>{};
        options.page = 0;
        options.size = 9999;
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        options.status = 1;
        options.type = 'employee';
        options.companyId = this.authService.getCurrentCompanyId();

        forkJoin([
            this.employeeService.searchEmployee(options),
            this.complianceDocumentService.getComplianceList({companyId: this.authService.getCurrentCompanyId()})
        ]).subscribe((res: any[]) => {
            const resObj: any = res;
            this.listEmployees = resObj[0].data.content;
            const dataMap = res[1].data;
            this.complianceEmployeeCount = this.getComplianceCount(dataMap, this.listEmployees,  allDocuments, 'employee');
        });
    }

    getVolunteerCompliance(allDocuments) {
        const options = <any>{};
        options.page = 0;
        options.size = 9999;
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        options.status = 1;
        options.type = 'volunteer';
        options.companyId = this.authService.getCurrentCompanyId();

        forkJoin([
            this.employeeService.searchEmployee(options),
            this.complianceDocumentService.getComplianceList({companyId: this.authService.getCurrentCompanyId()})
        ]).subscribe((res: any[]) => {
            const resObj: any = res;
            this.listVolunteer = resObj[0].data.content;
            const dataMap = res[1].data;
            this.complianceVolunteerCount = this.getComplianceCount(dataMap, this.listVolunteer, allDocuments, 'volunteer');
        });
    }

    getComplianceCount(dataMap, data , allDocuments, type) {
        let complianceCount = 0;
        let documentSettings = [];
        documentSettings = allDocuments.filter(item => item.role && item.role.indexOf(type) >= 0) || [];
        if (data && data.length > 0) {
            data.forEach(function (obj) {
                const foundEmployee = (dataMap || []).find(item => item.employeeId == obj.id);
                if (foundEmployee) {
                    obj.documentNames = foundEmployee.documentNames;
                    obj.complianceDocuments = foundEmployee.complianceDocuments;
                }
                const compliantDocNames = documentSettings.map((doc) => {
                    return doc.documentName;
                });
                obj.documentNames = (obj.documentNames || []).filter(item => compliantDocNames.includes(item));
                if (!obj.documentNames || !obj.documentNames.length) {
                    obj.complianceStatus = 'no_doc';
                } else {
                    let expired = false;
                    for (const doc of obj.complianceDocuments) {
                        if (compliantDocNames.includes(doc.templateName) && doc.expirationDate && new Date(doc.expirationDate).getTime() < new Date().getTime()) {
                            obj.complianceStatus = 'expired_doc';
                            expired = true;
                            break;
                        }
                    }
                    if (!expired) {
                        let missed = false;
                        for (const name of compliantDocNames) {
                            if (!obj.documentNames.includes(name)) {
                                obj.complianceStatus = 'missed_doc';
                                missed = true;
                            }
                        }
                        if (!missed) {
                            obj.complianceStatus = 'all_doc';
                            complianceCount ++;
                        }
                    }
                }
            });
        }
        return complianceCount;
    }
    async loadPunchHistory() {
        const options: any = {
            page: 0,
            size: 1,
            sortField: 'createdAt',
            sortOrder: 'ASC'
        };
        options.companyId = this.authService.getUserInfo().companyId;
        if (this.isEmployee) {
            options.employeeIds = [];
            options.employeeIds.push(this.authService.getCurrentEmployee());
            options.companyId = this.authService.getUserInfo().companyId;
        }
        options.fromDate = moment().subtract(1, 'month');
        options.toDate = moment();

        const resObj: any = await this.employeeClockingServiceV2.search2(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            this.punchHistoryCount = resObj.data.totalElements;
        }
    }

    loadStat() {
        const options: any = {
            page: 0,
            size: 1,
            sortField: 'createdAt',
            sortOrder: 'ASC'
        };
        options.companyId = this.authService.getUserInfo().companyId;
        if (this.isEmployee) {
            options.employeeIds = [];
            options.employeeIds.push(this.authService.getCurrentEmployee());
            options.companyId = this.authService.getUserInfo().companyId;
        }
        options.fromDate = moment(new Date()).startOf('day').toDate();
        options.toDate = moment(new Date()).endOf('day').toDate();
        this.employeeClockingService.punchStatisticNew(options).subscribe(res => {
            if (res.data) {
                // console.log('punchStatistic res.data: ', res.data);
                const statisticData: any = res.data;
                this.totalEndWork = statisticData.totalEndWork;
                this.totalClockedIn = statisticData.totalClockedIn;
            }
        }, (_error) => {
            console.log(_error);
        });
    }

    loadJobReports() {
        const options: any = {
            size: 1,
            page: 0,
            sortField : 'createdAt',
            sortOrder : 'DESC',
        };
        if (!this.authService.isSuper() && !this.authService.isSubSuper()) { options.companyId = this.authService.getCurrentCompanyId(); }

        this.employeeJobReportService.search(options).subscribe((data: any) => {
            this.totalJobReports = data.data.totalElements;
        }, () => {
        });
    }
    showCompliancePopUp() {
        this.complianceEmployeeCount = 0;
        this.complianceInterPaidCount = 0;
        this.complianceInterUnpaidCount = 0;
        this.complianceVolunteerCount = 0;
        this.complianceContractorsCount = 0;
        this.showComplianceCheckPopUp = true;
        this.getEmployeeCompliance(this.allDocuments);
        this.getVolunteerCompliance(this.allDocuments);
        this.getContractorsCompliance();
        this.loadDataAbsence();
        this.loadTotalOvertime();
        this.loadPunchHistory();
        this.loadJobReports();
        this.loadStat();
    }

    openTimerForTimeRecording = false;
    processTimerForTimeRecording() {
        this.openTimerForTimeRecording = true;
        this.showIconPause = false;
        this.showIconStart = true;
    }

    toggleSoundTimerForTimeRecording($event) {
        if (this.showVolume === true) {
            this.muteVolume = true;
            this.showVolume = false;
        } else {
            this.muteVolume = false;
            this.showVolume = true;
        }
    }

    getContractorsCompliance() {
        const options = <any>{};
        options.page = 0;
        options.size = 9999;
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        options.companyId = this.authService.getCurrentCompanyId();
        if (this.authService.getContractorTeamId() !== null && this.authService.getContractorTeamId() > 0) {
            options.teamId = this.authService.getContractorTeamId();
        }
        if (this.authService.getContractorType() === 1 || this.authService.getContractorType() === 0) {
            options.id = this.authService.getCurrentLoggedInId();
        }
        if (this.authService.getCurrentCompanyId() != null) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        const _this = this;
        forkJoin([
            this.contentService.filter(options),
            this.complianceDocumentService.getComplianceList({companyId: this.authService.getCurrentCompanyId(), type: 'CONTRACTOR'})
        ]).subscribe((res: any[]) => {
            const resObj: any = res[0];
            setTimeout(() => {
                this.listContractors = resObj.data.content;
                let documentSettings = [];
                documentSettings = _this.allDocuments.filter(item => item.role &&  item.role.indexOf('contractor') >= 0) || [];
                const dataMap = res[1].data;
                this.listContractors.forEach((element, index) => {
                    const compliantDocNames = documentSettings.map((doc) => {
                        return doc.documentName;
                    });
                    const foundEmployee = (dataMap || []).find(item => item.employeeId == element.id);
                    if (foundEmployee) {
                        element.documentNames = foundEmployee.documentNames;
                        element.complianceDocuments = foundEmployee.complianceDocuments;
                        element.documentNames = (element.documentNames || []).filter(item => compliantDocNames.includes(item));
                    }
                    element.documentNames = (element.documentNames || []).filter(item => compliantDocNames.includes(item));
                    if (!element.documentNames || !element.documentNames.length) {
                        element.complianceStatus = 'no_doc';
                    } else {
                        let expired = false;
                        for (const doc of element.complianceDocuments) {
                            if (compliantDocNames.includes(doc.templateName) && doc.expirationDate && new Date(doc.expirationDate).getTime() < new Date().getTime()) {
                                element.complianceStatus = 'expired_doc';
                                expired = true;
                                break;
                            }
                        }
                        if (!expired) {
                            let missed = false;
                            for (const name of compliantDocNames) {
                                if (!element.documentNames.includes(name)) {
                                    element.complianceStatus = 'missed_doc';
                                    missed = true;
                                }
                            }
                            if (!missed) {
                                element.complianceStatus = 'all_doc';
                                this.complianceContractorsCount++;
                            }
                        }
                    }

                });
            }, 1000);
        });
    }

    loadDataAbsence() {
        const options = <any>{};
        options.page = 0;
        options.size = 1;
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        options.companyId = this.authService.getCurrentCompanyId();
        options.status = 0;

        this.employeeService.getAllAbsence(options).subscribe((data: any) => {
            this.totalAbsence = data.data.totalElements;
        }, (error) => {
        });
    }
    async loadPayment() {
        let dayending = 0;
         if (!this.payrollSetting) {
             const res: any = await this.payrollSettingService.getbyId(this.authService.getCurrentCompanyId()).toPromise();
             const res1Obj: any = res;
             this.payrollSetting = res1Obj.data;
             switch (this.payrollSetting.payrollWeekEnding) {
                 case 'MONDAY':
                     dayending = 1;
                     break;
                 case 'TUESDAY':
                     dayending = 2;
                     break;
                 case 'WEDNESDAY':
                     dayending = 3;
                     break;
                 case 'THURSDAY':
                     dayending = 4;
                     break;
                 case 'FRIDAY':
                     dayending = 5;
                     break;
                 case 'SATURDAY':
                     dayending = 6;
                     break;
                 case 'SUNDAY':
                     dayending = 0;
                     break;
                 default:
                     break;
             }
         } else {
             switch (this.payrollSetting.payrollWeekEnding) {
                 case 'MONDAY':
                     dayending = 1;
                     break;
                 case 'TUESDAY':
                     dayending = 2;
                     break;
                 case 'WEDNESDAY':
                     dayending = 3;
                     break;
                 case 'THURSDAY':
                     dayending = 4;
                     break;
                 case 'FRIDAY':
                     dayending = 5;
                     break;
                 case 'SATURDAY':
                     dayending = 6;
                     break;
                 case 'SUNDAY':
                     dayending = 0;
                     break;
                 default:
                     break;
             }
         }
        if (this.payrollSetting) { this.getByDayOfWeek(dayending); }
    }

    getByDayOfWeek(dayOfWeek: number) {
        this.dayOfWeekOvertime = dayOfWeek;
        const curr = new Date(); // get current date
        const endWeek: number = curr.getDate() - curr.getDay() + (dayOfWeek === 0 ? 7 : dayOfWeek);
        const date = new Date(curr.setDate(endWeek));

        this.endDateOvertime = new Date(date.setDate(date.getDate()));
        this.startDateOvertime = new Date(date.setDate(date.getDate() - 7));
    }

    loadTotalOvertime() {
        const options = <any>{};
        options.page = 0;
        options.size = 1;
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        options.companyId = this.authService.getCurrentCompanyId();
        if (this.startDateOvertime) {
            Object.assign(options, { fromDate: this.startDateOvertime });
        }
        if (this.endDateOvertime) {
            Object.assign(options, { toDate: this.endDateOvertime });
        }
        this.employeeClockingServiceV2.getSummaryDetail(options).subscribe(res => {
            const res1Obj: any = res;
            this.totalOvertime = res1Obj.data.totalElements;
        });
    }

    showComplianceEmployeeWizard: boolean = false
    complianceEmployeeWizardList: any = []
    documentDetails: any = []
    customizeColumns: any = []
    listSendAlert: any = []
    listOfState: any = []
    companySetting: any
    complianceInfo: any
    cols = [
        { field: 'fullName', value: 'fullName', label: 'Full Name', show: true, width: 150, sortOptions: '', sort: 'text', disabled: true},
        { field: 'employeeStartDate', value: 'employeeStartDate', label: 'Hire Date', show: true, sortOptions: '', sort: 'number', disabled: true},
        { field: 'salaryType', value: 'salaryType', label: 'Wage', show: true, sortOptions: '', sort: '', disabled: true},
    ]
    private _jsonIpURL = 'assets/us-states.json'

    getStates(): Observable<any> {
        return this.http.get(this._jsonIpURL);
    }

    async openComplianceEmployeeWizard() {
        this.complianceEmployeeWizardList = [];
    
        // Simulate alert list population
        for (let i = 1; i <= 30; i++) {
            this.listSendAlert.push({label: i, value: i});
        }
    
        // Fetch states and populate listOfState
        this.getStates().subscribe(response => {
            const listOfState = [];
            const states = response.states;
            states.forEach(item => {
                const data = {label: item.name + ' (' + item.abbreviation + ')', value: item.abbreviation};
                listOfState.push(data);
            });
            this.listOfState = listOfState;
        });
    
        // Fetch compliance document list, then process compliance data
        await this.getComplianceDocumentList().then(() => {
            this.getComplianceList();  // This is where you fetch and process the compliance list
        });
    }

    getComplianceList() {
        this.complianceDocumentService.getComplianceList({
            companyId: this.authService.getCurrentCompanyId(),
            employeeId: this.authService.getCurrentLoggedInId()
        }).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.complianceInfo = resObj.data[0] || {};
                const documentNames = this.complianceInfo.documentNames || [];
    
                // Log the document names to ensure they are being populated
                // console.log('Document Names:', documentNames);
    
                const complianceDocuments = this.complianceInfo.complianceDocuments || [];
    
                let unComNo = 0;
                const uncomNoNames = [];
                const comNoNames = [];
    
                // Cross-reference documentNames with complianceDocuments
                documentNames.forEach(docName => {
                    // Check if there's a corresponding entry in complianceDocuments
                    const matchingDoc = complianceDocuments.find(doc => doc.documentName === docName);
    
                    // Check if the document has been completed (e.g., check updatedAt, file, or another indicator)
                    if (matchingDoc && matchingDoc.updatedAt) {
                        comNoNames.push(docName);  // Mark as completed
                    } else {
                        uncomNoNames.push(docName);  // Mark as uncompleted
                        unComNo++;
                    }
                });
    
                // Debugging logs
                // console.log('Completed Documents (comNoNames):', comNoNames);
                // console.log('Uncompleted Documents (uncomNoNames):', uncomNoNames);
    
                // Handle pagination and display (unchanged)
                const comNoNamesMapped = comNoNames.map(docName => ({
                    name: docName,
                    comNo: true  // Mark as completed
                }));
    
                const uncomNoNamesMapped = uncomNoNames.map(docName => ({
                    name: docName,
                    comNo: false  // Mark as uncompleted
                }));
    
                let concatList = comNoNamesMapped.concat(uncomNoNamesMapped)
                    .sort((a, b) => a.name.localeCompare(b.name));
    
                const splitSize = 8;
                for (let index = 0; index < concatList.length; index += splitSize) {
                    const complianceBean = {
                        id: (index / splitSize) + 1,
                        data: concatList.slice(index, index + splitSize)
                    };
                    this.complianceEmployeeWizardList.push(complianceBean);
                }
    
                if (uncomNoNames.length > 0) {
                    this.showComplianceEmployeeWizard = true;
                }
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Cannot get your compliance'
                });
            }
        }, error => {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Internal server. Please contact administrator for more information'
            });
        });
    }
    
    
    async getComplianceDocumentList() {
        await this.complianceDocumentService.getDocumentList(this.authService.getCurrentLoggedInId(), 'EMPLOYEE').subscribe((res: any) => {
            const existedDocuments = res.data;
            let newTemplates = [];
            this.documentDetails.forEach(document => {
                const existedDoc = existedDocuments.find(d => d.key === document.key);
                if (existedDoc) {
                    document.id = existedDoc.id;
                    document.adminId = existedDoc.adminId;
                    document.file = existedDoc.fileName;
                    document.fileImageSrc = existedDoc.fileUrl;
                    document.fileUrls = existedDoc.fileUrls || [];
                    document.fileName = existedDoc.fileName;
                    document.updatedAt = existedDoc.updatedAt || new Date();
                    document.createdAt = existedDoc.createdAt || new Date();
                    document.expirationDate = existedDoc.expirationDate;
                    document.confirmed = existedDoc.confirmed;

                    document.document = document.key !== 'DRIVER_DMV_LIC' ? {
                        data: [{
                            value: existedDoc.note,
                            documentFieldId: document.template.fields[0].id
                        }]
                    } : null;
                    document.finish = true;
                }
            });
            this.documentDetails.push(...newTemplates);
            this.documentDetails.sort(function(a, b) {
                if (!(<any>a).updatedAt && !(<any>b).updatedAt) return 1;
                if (!(<any>a).updatedAt) return 1;
                if (!(<any>b).updatedAt) return -1;
                else return (<any>b).updatedAt < (<any>a).updatedAt ? -1 : 1;
            });
        });
    }



    async handleShowCommmentPopup(docName) {
        const itemDoc = this.documentDetails.find(i => docName === i.template?.name)

        if (itemDoc) {
            this.fileUploaded = [];
            this.showCommentPopup = true;
            this.selectedDocumentName = itemDoc.template?.name;
            this.selectedDocumentData = _.cloneDeep(itemDoc);
            this.fileUploaded = this.selectedDocumentData.fileUrls || [];
        }
    }

    onUploadDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.toArray()[0].nativeElement.click();
        uploadFilePanel.hide();
    }
    
    uploadFile(event: any) {
        this.percentage = 0;
        let filesToUpload: File[] = [];
        if (event.target.files.length === 0) {
            return;
        }
        this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Add Document'),
            detail: this.translatePipe.transform('Your file is being uploaded, please wait...')
        });
    
        // Cast event.target.files to File[]
        filesToUpload = Array.from(event.target.files) as File[];
        this.showFileUpload = true;
    
        filesToUpload.forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file as Blob);
            this.uploadMultipleFiles(file);  // Upload each file
        });
        
        // console.log('File Uploaded:', this.fileUploaded);  // Debugging line to check fileUploaded array
    
        $("#File1").val('');  // Clear the file input
    }
    
    
    
    uploadMultipleFiles(file) {
        this.processing = true;
        this.documentServicev2.uploadFile(file, 'walker_file', this.authService.getCurrentCompanyId(), 'Company').subscribe(res => {
            this.processing = false;
            if (res.status === 'SUCCESS') {
                const options = { ids: [res.data.id] };
                this.getAllFiles(options);  // Fetch the file after successful upload
            }
        });
    }
    
    getAllFiles(options: any) {
        this.documentServicev2.searchFile(options).subscribe(res => {
            const files = res.data.content || [];
            this.showFileUpload = files.length > 0;
            this.fileUploaded = files.map(file => file.fileUrl);  // Store file URLs in the array
            console.log('Uploaded Files:', this.fileUploaded);  // Check if fileUploaded is correctly populated
        });
    }
    

    onViewDocument(url) {
        if (url) {
            window.open(url);
        }
    }

    getFileName(url) {
        return url.split('/').pop().split('__').pop();
    }

    removeFile(url) {
        this.fileUploaded = this.fileUploaded.filter(file => file !== url);
        this.showFileUpload = this.fileUploaded.length > 0;
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    isDate(evt) {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        const val = evt.target?.value;
        if (val.length > 0) {
            if (val.length === 1) {
                if (48 <= charCode && charCode <= 57) {
                    return true;
                }
            } else if (val.length === 2 || val.length === 5) {
                if (charCode === 47) {
                    return true;
                }
            } else if (val.length === 3) {
                if (48 <= charCode && charCode <= 51) {
                    return true;
                }

            } else if (val.length === 4 || val.length === 7 || val.length === 8 || val.length === 9) {
                if (48 <= charCode && charCode <= 57) {
                    return true;
                }
            } else if (val.length === 6) {
                if (charCode === 49 || charCode === 50) {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            if (charCode === 48 || charCode === 49) {
                return true;
            }
        }
        return false;
    }
    
    clearDocument(document) {
        if (!document.id) {
            this.messageService.add({
                severity: 'warn',
                summary: this.translatePipe.transform('Clear Document'),
                detail: this.translatePipe.transform('This compliance item has no documents to clear from database.')
            });
            return;
        }
        this.clearingDocuments = true;
        this.complianceDocumentService.delete(document.id).subscribe({
            next: () => {
                document.id = null;
                document.fileUrl = null;
                document.file = null;
                document.fileImageSrc = null;
                document.fileName = null;
                this.fileUploaded = [];
                this.showFileUpload = false;
                if (this.selectedDocumentData) {
                    this.selectedDocumentData.fileUrls = [];
                    this.selectedDocumentData.file = null;
                    this.selectedDocumentData.fileImageSrc = null;
                }
                const index = this.documentDetails.findIndex(doc => 
                    doc.template.name.replace(/[^a-zA-Z0-9]+/g, '_').toUpperCase() === document.key
                );
    
                if (index !== -1) {
                    const doc = this.documentDetails[index];
                    doc.file = null;
                    doc.fileUrl = null;
                    doc.fileImageSrc = null;
                    doc.fileName = null;
                    doc.finish = false;
                    doc.document = {
                        id: null,
                        data: [{ value: null, documentFieldId: null }]
                    };
                }
                this.messageService.add({
                    severity: 'info',
                    summary: this.translatePipe.transform('Clear Document'),
                    detail: this.translatePipe.transform('The document for this compliance item has been cleared from database.'),
                });
                this.refreshComplianceList();
                this.saveDocumentCount();
                this.showCommentPopup = false;
                this.clearingDocuments = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('Clear Document'),
                    detail: this.translatePipe.transform('Error deleting item, try again later. If this error persists, contact support.'),
                });
                this.clearingDocuments = false;
            }
        });
    }
    
    saveDocumentCount() {
        let completed = this.documentDetails.filter(document => document.fileImageSrc && document.fileImageSrc !== '');
        let body = {
            id: this.authService.getCurrentLoggedInId(),
            totalDocument: (this.documentDetails || []).length,
            completedDocument: (completed || []).length
        }
        this.employeeService.saveDocumentCount(body).subscribe(() => {
            console.log('Document count saved');
        }, error => {
            console.error('Error saving document count', error);
        });
    }

    onClickSaveDocumentDriver(document: any, file: any) {
        const payload: any = {
            //id: document.id,
            adminId: this.authService.getCurrentLoggedInId(),
            adminType: 'EMPLOYEE',
            companyId: this.authService.getCurrentCompanyId(),
            key: document.key,
            templateName: document.template.name,
            createdAt: document.createdAt || new Date(),
            updatedAt: new Date(),
            expDate: this.expDateM,
            licenseNumber: this.licenseNumberM,
            sendAlert: this.sendAlertM,
            state: this.stateM,
            createdByUsr: this.authService.getCurrentUsername()
        };

        if (document.saveClicked) return;
        document.saveClicked = true;
        this.showCommentPopup = false;

        const handleFileUpload = (res: any) => {
            payload.fileName = res.data.fileName;
            payload.fileUrl = res.data.fileUrl;
            document.fileImageSrc = res.data.fileUrl;
            if (document.id) {
                payload.lastModifiedBy = this.authService.getCurrentUsername();
            }
            this.complianceDocumentService.save(payload).subscribe(async () => {
                this.messageService.add({ severity: 'info', summary: 'Updated', detail: 'Compliance item has been updated.' });
                this.saveDocumentCount();
            });
        };

        const saveDocument = () => {
            if (document.id) {
                payload.lastModifiedBy = this.authService.getCurrentUsername();
            }
            this.complianceDocumentService.save(payload).subscribe(() => {
                this.messageService.add({ severity: 'info', summary: 'Updated', detail: 'Compliance item has been updated.' });
                document.saveClicked = false;
                this.saveDocumentCount();
            });
        };

        if (file) {
            if (file instanceof File) {
                this.documentServicev2.uploadFile(file, 'document_compliance_file', this.authService.getCurrentLoggedInId(), 'Employee').subscribe(handleFileUpload);
            } else {
                payload.fileName = document.fileName;
                payload.fileUrl = document.fileImageSrc;
                saveDocument();
            }
        } else {
            saveDocument();
        }
    }

  onClickSaveDocument(document: any, file: any) {
    this.savingDocuments = true;
    const payload: any = {
        adminId: this.authService.getCurrentLoggedInId(),
        adminType: 'EMPLOYEE',
        companyId: this.authService.getCurrentCompanyId(),
        key: document.key,
        note: (document.document.data || []).length ? document.document.data[0].value : '',
        templateName: document.template.name,
        createdAt: document.createdAt || new Date(),
        updatedAt: new Date(),
        createdByUsr: this.authService.getCurrentUsername(),
        expirationDate: document.expirationDate,
        confirmed: document.confirmed
    };

    if (!this.fileUploaded || this.fileUploaded.length <= 0) {
        this.messageService.add({ 
            severity: 'error', 
            summary: this.translatePipe.transform('Missing Fields'), 
            detail: this.translatePipe.transform('Please fill out all required fields.') 
        });
        this.savingDocuments = false;
        return;
    }

    if (this.fileUploaded && this.fileUploaded.length) {
        payload.fileUrls = this.fileUploaded;
        payload.fileUrl = this.fileUploaded[0];
        payload.fileName = this.getFileName(this.fileUploaded[0]);

    }

    if (document.id) {
        payload.id = document.id;
        payload.lastModifiedBy = this.authService.getCurrentUsername();
    }

    this.complianceDocumentService.save(payload).subscribe(() => {
        this.messageService.add({
            severity: 'info', 
            summary: this.translatePipe.transform('Save'), 
            detail: this.translatePipe.transform('Compliance item details have been updated') ,
        });
        document.saveClicked = false;
        this.showCommentPopup = false;
        this.saveDocumentCount();
        this.openComplianceEmployeeWizard();
        this.savingDocuments = false;
    }, error => {
        this.messageService.add({ 
            severity: 'warn', 
            summary: this.translatePipe.transform('Incomplete'), 
            detail: this.translatePipe.transform('Please upload a document file to complete compliance item.'),
        });
        this.savingDocuments = false;
        document.saveClicked = false;
    });
}

    
refreshComplianceList() {
    this.getComplianceDocumentList().then(() => {
        this.getComplianceList();
    });
}


    async getPunchHistoryList() {
        this.getLastWeek()
        const options = {
            employeeIds: [this.authService.getCurrentLoggedInId()],
            fromDate: this.punchFromDate,
            toDate: this.punchToDate,
            sortField: "createdAt",
            sortOrder: "ASC",
            companyId: this.authService.getCurrentCompanyId()
        }

        this.punchHistoryList = []
        const resObj: any = await this.employeeClockingServiceV2.search2(options).toPromise()
        if (resObj.status === 'SUCCESS') {
            this.punchHistoryList = _.compact(resObj.data.content);
            this.totalPunchRecords = resObj.data.totalElements;

            const checkConfirmed = (this.punchHistoryList || []).filter(i => i.confirmPunch === 'Confirmed')
            this.fullName = this.punchHistoryList && this.punchHistoryList[0] ? this.punchHistoryList[0]?.employee?.fullName : this.authService.getCurrentUsername();

            if (!checkConfirmed || checkConfirmed?.length <= 0) {
                this.showPunchDialog = true;
                const payLoad = {
                    fullName: this.fullName,
                    companyId: this.authService.getCurrentCompanyId(),
                    employeeId: this.authService.getCurrentLoggedInId(),
                    period : this.punchFromDateDialog + ' - ' + this.punchToDateDialog,
                    application: 'Web'
                };
                this.employeeClockingServiceV2.updatePunchConfirm(payLoad).subscribe(res => {}, () => {
                });
            };
        }
    }
    payrollDate: any;
    getLastWeek() {
        const curr = new Date();
        let first = curr.getDate() - curr.getDay() - 7;
        let last = first + 6;

        if (!this.payrollSetting) {
            this.payrollSettingService.getbyId(this.authService.getCurrentCompanyId()).subscribe(res => {
                const resObj: any = res;
                this.payrollSetting = resObj.data;

                this.calculatePayrollDate();
                const start = new Date(this.payrollDate);
                start.setMinutes(start.getMinutes() + start.getTimezoneOffset());
                start.setDate(start.getDate() - 7);
                const end = new Date(start);
                end.setDate(start.getDate() - 6);

                this.payrollDate[0] = end;
                this.payrollDate[1] = start;
                this.punchFromDate = moment(end).format('YYYY-MM-DD');
                this.punchFromDateDialog = moment(end).format('MM/DD/YYYY');

                this.punchToDate = moment(start).format('YYYY-MM-DD');
                this.punchToDateDialog = moment(start).format('MM/DD/YYYY');
            });
        } else {
            this.calculatePayrollDate();
            const start = new Date(this.payrollDate);
            start.setMinutes(start.getMinutes() + start.getTimezoneOffset());
            start.setDate(start.getDate() - 7);
            const end = new Date(start);
            end.setDate(start.getDate() - 6);

            this.payrollDate[0] = end;
            this.payrollDate[1] = start;

            this.punchFromDate = moment(end).format('YYYY-MM-DD');
            this.punchFromDateDialog = moment(end).format('MM/DD/YYYY');

            this.punchToDate = moment(start).format('YYYY-MM-DD');
            this.punchToDateDialog = moment(start).format('MM/DD/YYYY');
        }
    }

    calculatePayrollDate() {
        // calculate payroll date
        const today = new Date();
        const dayOfWeek = today.getDay() === 0 ? 7 : today.getDay();
        let dayending = 0;
        switch (this.payrollSetting.payrollWeekEnding) {
            case 'MONDAY':
                dayending = 1;
                break;
            case 'TUESDAY':
                dayending = 2;
                break;
            case 'WEDNESDAY':
                dayending = 3;
                break;
            case 'THURSDAY':
                dayending = 4;
                break;
            case 'FRIDAY':
                dayending = 5;
                break;
            case 'SATURDAY':
                dayending = 6;
                break;
            case 'SUNDAY':
                dayending = 7;
                break;
            default:
                break;
        }
        const last = today.getDate() - dayOfWeek + dayending;
        this.payrollDate = new Date(today.setDate(last));
    }

    isExceptionPunchInOut(location: any) {
        return location === 'Holiday' || location === 'OFF' || location === 'VACATION' || location === 'SICK' || location === 'OTHER';
    }

    dateToShowInTwelveHoursFormat(date) {
        if (date) {
            return moment(date).format('hh:mm A');
        } else {
            return '';
        }
    }

    getToolTip(locationName, text) {
        if (locationName === 'Holiday') {
            return 'Holiday: ' + text;
        } else {
            if (text && text !== null && text !== 'null') {
                return ' by ' + text;
            }
            return '';
        }
    }

    calCulateTotalLunchTothhmm(rowData) {
        if (!(rowData && rowData.employeeClockingLunchHourHistory)) {
            return '';
        }
        let totalMinutes = 0;
        const clockings = rowData.employeeClockingLunchHourHistory;
        for (const clocking of clockings) {
            if (clocking.lunchTime) { totalMinutes += clocking.lunchTime; }
        }
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    getEndWorkLabel(data) {
        if (data.createdAt) {
            const cteateDate = moment(data.createdAt).format('YYYY-MM-DD');
            const currentDate = moment(new Date()).format('YYYY-MM-DD');
            return (cteateDate === currentDate) ? '--:--' : 'Missing';
        } else {
            return 'Missing';
        }
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    getTotalOverrtime(punchHistoryList: Array<any>) {
        let totalHours = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.overTime;
        });
        return this.hhmm(Math.round(totalHours));
    }

    getTotalHour(punchHistoryList: Array<any>) {
        let totalHours = 0;
        let totalOvertime = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.totalWorkTime;
            totalOvertime += x.overTime;
        });
        const result = totalHours / 60000 - totalOvertime;
        return this.hhmm(Math.round(result));
    }

    viewMap(rowData: any) {
        this.milestone_markers = [];
        this.milestone_groups = [];
        this.routePathMilestone = [];
        this.trackingData = [];
        this.trackingList = [];
        if (!rowData.employee || !rowData.employee.employeeId) { return; }
        this.loadingTrackData = true;
        this.showTrackingDialog = true;
        this.selectedLat = null;
        this.selectedLong = null;
        this.selectedName = rowData.employee.fullName;
        const options = <any>{};
        if (rowData.clockInTime != null) {
            options.fromDate = moment(rowData.clockInTime).toDate();
            this.startTime = rowData.clockInTime;
        } else {
            options.fromDate = moment(rowData.punchDate).startOf('day').toDate();
        }
        if (rowData.clockOutTime != null) {
            options.toDate = moment(rowData.clockOutTime).add(1, 'minute').toDate();
            this.endDate = rowData.clockOutTime;
        } else {
            options.toDate = moment(rowData.punchDate).endOf('day').toDate();
        }
        options.sortField = 'createdAt';
        options.sortOrder = 'ASC';
        options.page = 0;
        options.size = 9999;
        options.employeeId = rowData.employee.employeeId;
        this.evvRegisterdService.filter(options).subscribe(res => {
            const resObj: any = res;
            this.loadingTrackData = false;
            if (resObj.status === 'SUCCESS' && (resObj.data.content || []).length) {
                this.trackingData = [];
                const start = options.fromDate.getTime();
                const end = options.toDate.getTime();
                resObj.data.content.forEach(item => {
                    if (!item.createdAt) {
                        return;
                    }
                    const time = moment(item.createdAt).toDate().getTime();
                    if(start <= time && time <= end) {
                        this.trackingData.push(item);
                    }
                });

                if (this.trackingData && this.trackingData.length) {
                    this.firstLat = this.trackingData[0].lat;
                    this.firstLong = this.trackingData[0].lng;
                    this.firstLatMilestone = this.trackingData[0].lat;
                    this.firstLngMilestone = this.trackingData[0].lng;
                    this.zoomMilestone = this.options.zoom;
                    this.trackingList = this.trackingData.map(data => {
                        return {
                            lat: data.lat,
                            lng: data.lng
                        };
                    });
                    let i = 0;
                    this.milestones = this.trackingData.filter(l => l.evvType !== 'Walker/questionnaire').map(data => {
                        let position = '';
                        if (i === 0 && data.evvType !== 'End Work') {
                            position = ' (Start)';
                        } else if (i === this.trackingData.length - 1) {
                            position = ' (End)';
                        }
                        i++;
                        return {
                            id: data.id,
                            lat: data.lat,
                            lng: data.lng,
                            draggable: false,
                            createdAt: data.createdAt,
                            zipCode: data.zipCode,
                            labelText: this.getLabelText(data.evvType),
                            evvTime: data.evvTime,
                            label: {
                                text: `${data.evvType}${position}`,
                                className: 'marker-label',
                                fontSize: '12px',
                            }
                        };
                    });
                    this.milestone_groups = this.chunkList(this.milestones);
                    if (this.milestone_groups && this.milestone_groups.length) {
                        this.milestone_groups.forEach(group => {
                            let inprogress = true;
                            group.data.forEach(item => {
                                if (item.labelText === 'End Work' || item.labelText === 'On Break') {
                                    inprogress = false;
                                }
                            });
                            group.inprogress = inprogress;
                        })
                    }
                }
            }
        }, () => {
            this.loadingTrackData = false;
            this.trackingData = [];
            this.trackingList = [];
        });
    }

    getLabelText(text) {
        if (text === 'Arrival Time' || text === 'Start work') {
            return 'Start Work';
        }

        if (text === 'End Work' || text === 'Leave Time') {
            return 'End Work';
        }

        if (text === 'Out for Lunch') {
            return 'On Break';
        }

        if (text === 'Back from Lunch') {
            return 'End Break';
        }
        return text;
    }

    chunkList(arr) {
        const tempArr = [];
        let currentPos = 'Start Work';
        let currArr = [];
        const starts = arr.filter(item => item.labelText === 'Start Work');
        let firstStart = starts && starts.length > 0 ? starts[0] : null;
        if (firstStart) {
            arr = arr.filter(item => item.evvTime > firstStart.evvTime);
            currArr.push(firstStart);
        } else {
            if (arr && arr.length > 0 && this.startTime) {
                firstStart = _.cloneDeep(arr[0]);
                firstStart.createdAt = this.startTime;
                firstStart.labelText = 'Start Work';
                firstStart.evvTime = this.startTime;
                currArr.push(firstStart);
            }
        }
        for (let i = 0; i < arr.length; i++) {
            if (currentPos == null) {
                break;
            }
            const milestone = arr[i];
            if (milestone.labelText === 'LIVE UPDATE' && currentPos != 'On Break') {
                currArr.push(milestone);
                continue;
            }
            if (currentPos === 'Start Work') {
                if (milestone.labelText === 'On Break') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'On Break';
                } else if (milestone.labelText === 'End Work') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'End Work';
                    break;
                }
            } else if (currentPos === 'On Break') {
                if (milestone.labelText === 'End work') {
                    const object = _.cloneDeep(milestone);
                    object.labelText = 'End Break';
                    currArr.push(object);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currArr.push(milestone);
                    currentPos = 'End Work';
                    break;
                } else if (milestone.labelText === 'End Break') {
                    currArr.push(milestone);
                    currentPos = 'End Break';
                }
            } else if (currentPos === 'End Break') {
                if (milestone.labelText === 'On Break') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'On Break';
                } else if (milestone.labelText === 'End Work') {
                    currArr.push(milestone);
                    tempArr.push(_.cloneDeep(currArr));
                    currArr = [];
                    currentPos = 'End Work';
                    break;
                }
            } else {
                currentPos = null;
            }
        }
        const result = [];
        for (let i = 0; i < tempArr.length; i++) {
            const arr = tempArr[i];
            if (arr && arr.length > 1) {
                const data = {
                    data: [arr[0], arr[arr.length - 1]],
                    positions: [...arr],
                    groupId: result.length
                };
                result.push(data);
            }
        }
        return result;
    }

    closeTrackingDialog() {
        this.showTrackingDialog = false;
        this.trackingData = [];
        this.trackingList = [];
        this.firstLat = null;
        this.firstLong = null;
        this.fullRecordsTab = true;
        this.activeItem = this.dialogTabs[0];
    }

    toggleMarkers(rowData) {
        this.options.zoom = 14;
        this.endDate = null;
        this.milestone_markers = [];
        this.routePathMilestone = [];
        const allPositions = rowData.positions;
        if (allPositions.length > 1) {
            allPositions[0].label.className = 'marker-label-1';
            this.milestone_markers.push(allPositions[0]);
            allPositions[allPositions.length - 1].label.className = 'marker-label-2';
            this.milestone_markers.push(allPositions[allPositions.length - 1]);
        }
        this.selectedMarker = rowData.positions[0];
        this.firstLatMilestone = this.selectedMarker.lat;
        this.firstLngMilestone = this.selectedMarker.lng;
        this.zoomMilestone = 20;
        if (rowData.routePathMilestone) {
            this.routePathMilestone = rowData.routePathMilestone;
            return;
        }
        this.routePathMilestone = allPositions;
        this.options.zoom = 16;
    }

    submitPunchConfirmation() {

        console.log('submitPunchConfirmation signaturePadConfirm.isEmpty: ', this.signaturePadConfirm.isEmpty())
        if (this.showSignaturePadConfirm) {
            if (this.signaturePadConfirm.isEmpty()) {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('Invalid data'),
                    detail: this.translatePipe.transform('Licensee/Licensor Signatures are required!')
                });
                return;
            }
            const signaturePadRaw = this.dataURItoBlob(this.signaturePadConfirm.toDataURL());
            const signaturePadFile = new File([signaturePadRaw], 'signaturePadConfirm.png');
            this.documentServicev2.uploadFile(signaturePadFile, 'recollection_signaturePadFile', this.authService.getCurrentCompanyId(), 'Company') // upload licensor sign
                .subscribe(resSignature => {
                    const resObj: any = resSignature;
                    const payLoad = {
                        employeeClockingIds: this.punchHistoryList.map(i => i.id),
                        signature: resObj.data.id,
                        signatureUrl: resObj.data.fileUrl,
                        fullName: this.fullName,
                        companyId: this.authService.getCurrentCompanyId(),
                        employeeId: this.authService.getCurrentLoggedInId(),
                        period : this.punchFromDateDialog + ' - ' + this.punchToDateDialog,
                        application: 'Web'
                    };
                    this.employeeClockingServiceV2.confirmPunch(payLoad).subscribe(res => {
                        if (res.status === 'SUCCESS') {
                            this.messageService.add({
                                severity: 'info',
                                summary: this.translatePipe.transform('Save'),
                                detail: this.translatePipe.transform('Punch confirmation success')
                            });
                            this.monitoringDetailsService.monitorAction(
                                `Weekly hours popup confirmed`,
                                this.timeSpent,
                                { weekly_hours_popup_confirmed_by: this.authService.getCurrentLoggedInName()},
                                'complete',
                                ' Weekly hours popup confirmed',
                                0
                            );
                        } else {
                            this.messageService.add({
                                severity: 'error',
                                summary: this.translatePipe.transform('Save'),
                                detail: this.translatePipe.transform('Punch confirmation error')
                            });
                        }

                        this.showPunchDialog = false;
                    }, () => {
                        this.showPunchDialog = false;
                        this.messageService.add({
                            severity: 'error',
                            summary: this.translatePipe.transform('Error'),
                            detail: this.translatePipe.transform('Punch confirmation error')
                        });
                    });
                });
        }
    }

    private dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
            type: mimeString
        });
    }

    redirectToPunchHistory() {
        this.router.navigate(['/app/employee-clocking/punch-history'])
    }

     // Go to the next page
     nextPage() {
        if (this.currentPage < this.complianceEmployeeWizardList.length - 1) {
            this.currentPage++;
        }
    }

    // Go to the previous page
    prevPage() {
        if (this.currentPage > 0) {
            this.currentPage--;
        }
    }


    time: number = 0;
    startTimerRecord: Date;
    startTimerRecordStr: any;
    endTimerRecord: Date;
    endTimerRecordStr: any;
    intervalId: any;
    showIconStart = false;
    showIconPause = false;

    isRunning = false;
    timerRecordStart: number = 0;  
    time1: number = 0;

    startFirstTimerRecord() {
        if (!this.isRunning) {
            let startTime = Date.now();

            const timerRecordsItems :any[] = [
                {
                    companyId: this.authService.getCurrentCompanyId(),
                    createdByUsr: this.authService.getCurrentUsername(),
                    startTimeStr: this.datePipe.transform(startTime, 'hh:mm a'),
                    startTime: startTime,
                    action: 'START_TIMER'
                }
            ]
            const timerRecords : any = {
                companyId: this.authService.getCurrentCompanyId(),
                roleId: this.authService.getRoleLevel(),
                userType: this.authService.getPaymentUserType(),
                userId: this.authService.getCurrentLoggedInId(),
                name: this.authService.getCurrentLoggedInName(),
                createdByUsr: this.authService.getCurrentUsername(),
                startTimeStr: this.datePipe.transform(startTime, 'hh:mm a'),
                startTime: startTime,
                action: 'START_TIMER'
            };

            const timerRecordsKey = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(startTime, 'ddMMyyyy');
            timerRecords.timerRecordsKey = timerRecordsKey;
            timerRecords.timerRecordsItems = timerRecordsItems;

            this.timerRecordsService.startTimer(timerRecords).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    this.messageService.add({ 
                        severity: 'success', 
                        summary: this.translatePipe.transform('Success'), 
                        detail: this.translatePipe.transform('Start timer success') 
                    });
                    this.isRunning = true;
                    this.showIconPause = true;
                    this.showIconStart = false;

                    if (!this.timerRecordStart) {
                        this.timerRecordStart = startTime;
                        localStorage.setItem('timerRecordStart', this.timerRecordStart.toString());
                        this.saveTimerRecordStartInFirebase(this.timerRecordStart.toString());
                    }
            
                    this.intervalId = setInterval(() => {
                        const currentTime = Date.now();
                        this.time1 = Math.floor((currentTime - this.timerRecordStart) / 1000);  // Tính thời gian đã trôi qua
                        this.saveTimerState();  // Lưu trạng thái vào localStorage mỗi giây
                    }, 1000);
                    
                } else {
                    this.messageService.add({ 
                        severity: 'error', 
                        summary: this.translatePipe.transform('Error'), 
                        detail: this.translatePipe.transform('Start timer fail!') 
                    });
                }
            }, (error: any) => {
                this.messageService.add({ 
                    severity: 'error', 
                    summary: this.translatePipe.transform('Error'), 
                    detail: this.translatePipe.transform('Start timer fail!') 
                });
            });
            
        }
    }

    startTimerContinue() {
        if (!this.isRunning) {
          this.isRunning = true;
          this.showIconPause = true;
          this.showIconStart = false;
          // Lưu thời gian bắt đầu vào localStorage nếu nó chưa được khởi tạo
          if (!this.timerRecordStart) {
            this.timerRecordStart = Date.now();
            localStorage.setItem('timerRecordStart', this.timerRecordStart.toString());
            this.saveTimerRecordStartInFirebase(this.timerRecordStart.toString());
          }
    
          this.intervalId = setInterval(() => {
            const currentTime = Date.now();
            this.time1 = Math.floor((currentTime - this.timerRecordStart) / 1000);  // Tính thời gian đã trôi qua
            this.saveTimerState();  // Lưu trạng thái vào localStorage mỗi giây
          }, 1000);
        }
    }

    // Lưu trạng thái vào localStorage
    saveTimerState() {
        this.saveTimerStateInFirebase(this.isRunning);
        localStorage.setItem(
            'timerForTimeRecording',
            JSON.stringify({
                isRunning: this.isRunning,
            })
        );
    }

    saveTimerRecordStartInFirebase(time) {
        const key = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(Date.now(), 'ddMMyyyy');
        this.firebaseNotificationService.saveFirebaseNotification(`/timerRecordStart/${key}`, {
            timerRecordStart: time
        }).subscribe();
    }

    saveTimerStateInFirebase(isRunning) {
        const key = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(Date.now(), 'ddMMyyyy');
        this.firebaseNotificationService.saveFirebaseNotification(`/timerForTimeRecording/${key}`, {
          isRunning: isRunning
        }).subscribe();
    }

    // Khôi phục trạng thái từ localStorage
    async loadTimerState() {
        const key = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(Date.now(), 'ddMMyyyy');

        const savedDataFirebase : any = await this.firebaseNotificationService.getFirebase(`/timerForTimeRecording/${key}`).toPromise();
        // console.log('loadTimerState savedDataFirebase: ', savedDataFirebase);
        const savedStartTimeFirebase : any = await this.firebaseNotificationService.getFirebase(`/timerRecordStart/${key}`).toPromise();
        // console.log('loadTimerState savedStartTimeFirebase: ', savedStartTimeFirebase);
        
        // const savedData = localStorage.getItem('timerForTimeRecording');
        // const savedStartTime = localStorage.getItem('timerRecordStart');

        if (savedDataFirebase.status === 'SUCCESS' && savedDataFirebase.data
            && savedStartTimeFirebase.status === 'SUCCESS' && savedStartTimeFirebase.data
        ) {
            const savedData = savedDataFirebase.data.isRunning; 
            const savedStartTime = savedStartTimeFirebase.data.timerRecordStart;

            if (savedData && savedStartTime) {
                // const { isRunning } = JSON.parse(savedData);
                const isRunning = savedData;
                this.timerRecordStart = parseInt(savedStartTime, 10);
                this.time1 = Math.floor((Date.now() - this.timerRecordStart) / 1000);  // Tính thời gian đã trôi qua
    
                this.isRunning = isRunning;
    
                if (isRunning) {
                    this.isRunning = false;
                    this.openTimerForTimeRecording = true;
                    this.showIconPause = true;
                    this.showIconStart = false;
                    this.startTimerContinue();  // Tiếp tục chạy nếu trước đó đang chạy
                } else {
                    this.showIconPause = false;
                    this.showIconStart = true;
                }
            }
        }
        
    }

    getFormattedTime1(): string {
        const minutes = Math.floor(this.time1 / 60);
        const seconds = this.time1 % 60;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    stopTimerRecords() {
        clearInterval(this.intervalId);
        this.isRunning = false;
        this.saveTimerState();  // Lưu trạng thái khi dừng
    }
    
    async resetTimerRecords() {
        let stopTime = Date.now();
        const key = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(stopTime, 'ddMMyyyy');

        const savedDataFirebase : any = await this.firebaseNotificationService.getFirebase(`/timerForTimeRecording/${key}`).toPromise();
        console.log('loadTimerState savedDataFirebase: ', savedDataFirebase);
        const savedStartTimeFirebase : any = await this.firebaseNotificationService.getFirebase(`/timerRecordStart/${key}`).toPromise();
        console.log('loadTimerState savedStartTimeFirebase: ', savedStartTimeFirebase);

        if (savedDataFirebase.status === 'SUCCESS' && savedDataFirebase.data
            && savedStartTimeFirebase.status === 'SUCCESS' && savedStartTimeFirebase.data
        ) {
            const savedData = savedDataFirebase.data.isRunning; 
            const savedStartTime = savedStartTimeFirebase.data.timerRecordStart;
            if (savedData && savedStartTime) {
                const timerRecordStart = parseInt(savedStartTime, 10);
    
                const stopTimerRecords : any = {
                    endTimeStr: this.datePipe.transform(stopTime, 'hh:mm a'),
                    endTime: stopTime,
                    countTime: this.time1,
                    action: 'STOP_TIMER'
                };
                const timerRecordsKey = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(timerRecordStart, 'ddMMyyyy');
                stopTimerRecords.timerRecordsKey = timerRecordsKey;
                stopTimerRecords.lastModifiedBy = this.authService.getCurrentUsername();
    
                this.stopTimerRecords();
                this.time1 = 0;
                this.timerRecordStart = 0;
                this.showIconPause = false;
                this.showIconStart = true;
    
                this.removeDataTimerInLocalStorage();
                this.removeDataTimerInFirebase();
    
                this.timerRecordsService.endTimer(stopTimerRecords).subscribe((res: any) => {
                    if (res.status === 'SUCCESS') {
                        this.messageService.add({ 
                            severity: 'success', 
                            summary: this.translatePipe.transform('Success'), 
                            detail: this.translatePipe.transform('End timer success') 
                        });
                    } else {
                        this.messageService.add({ 
                            severity: 'error', 
                            summary: this.translatePipe.transform('Error'), 
                            detail: this.translatePipe.transform('End timer fail!') 
                        });
                    }
                }, (error: any) => {
                    this.messageService.add({ 
                        severity: 'error', 
                        summary: this.translatePipe.transform('Error'), 
                        detail: this.translatePipe.transform('End timer fail!') 
                    });
                });
            }
        }
    }

    getFormattedTime(): string {
        const minutes = Math.floor(this.time / 60);
        const seconds = this.time % 60;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    getDataTimerInFirebase() {
        const key = this.authService.getPaymentUserType() + '_' + this.authService.getCurrentLoggedInId() + '_' + this.datePipe.transform(Date.now(), 'ddMMyyyy');
        this.subscriptions.add(this.db.list(`/timerRecordStart/${key}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            console.log('getDataTimerInFirebase timerRecordStart event: ', event);
        }));

        this.subscriptions.add(this.db.list(`/timerForTimeRecording/${key}`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
            console.log('getDataTimerInFirebase timerForTimeRecording event: ', event);
        }));
    }

    removeDataTimerInLocalStorage() {
        localStorage.removeItem('timerForTimeRecording'); 
        localStorage.removeItem('timerRecordStart');
    }
    removeDataTimerInFirebase() {
        this.saveTimerRecordStartInFirebase(null);
        this.saveTimerStateInFirebase(null);
    }

    clearLicensorSign() {
        this.showSignaturePadConfirm = true;
        this.isLicensorSignConfirm = null;
        // this.contract.licensorSignature = null;
        if (this.signaturePadConfirm) {
            this.signaturePadConfirm.clear();
        }
    }

    drawStartSignaturePad() {

    }

    drawCompleteSignaturePad() {
        // console.log("2 Licensor Draw Complete.")
    }

    closePunchDialog() {
        this.showPunchDialog = false;
        this.monitoringDetailsService.monitorAction(
            `Weekly hours popup closed`,
            this.timeSpent,
            {weekly_hours_popup_close_by : this.authService.getCurrentLoggedInName()},
            'complete',
            'Weekly hours popup closed',
            0
        );
    }

    getRouterLink() {
        // this.router.navigate(['app/admin/platform-admin/view/1']);
        const userId = this.authService.getCurrentLoggedInId();
        const roleLevel = this.authService.getRoleLevel();
        switch (roleLevel) {
            case 1:
                return 'app/admin/platform-admin/view/' + userId;
            case 3:
            case 5:
                return 'app/admin/agency-admin/edit/' + userId;
            case 4:
            case 6:
                return 'app/admin/company-admin/view/' + userId;
            case 7:
                return 'app/employee/view/' + userId;
            case 9:
                return 'app/crm/client/edit/' + userId;
            case 10:
                return 'app/guest/view/' + userId;
                // this.router.navigate(['app/guest', 'view',  userId]);
            case 13:
                return 'app/crm/freelancer/edit/' + userId;
            default:
                break;
        }
    }

}

