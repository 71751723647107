<p-toast position="bottom-right">{{msgs}}</p-toast>
<p-panel header="{{'Work Time Confirmation' | translate}}">
    <p-panel [toggleable]="true">
        <p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
            <span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Work Time Confirmation' | translate}}</span>
        </p-header>
        <div class="ui-g">
            <div *ngIf="isPlatformAdmin" class="ui-g-6 ui-sm-12">
                <label>{{'company.name' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-building"></i>
                        </button>
                    </div>
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="companies" [(ngModel)]="selectedCompany" (ngModelChange)="getAllEmployeesByCompany()" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'employee.name' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-user"></i>
                        </button>
                    </div>
<!--                    <p-multiSelect defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}" [options]="employeeList" [(ngModel)]="employeeIds" >-->
<!--                        <ng-template pTemplate="item" let-item>-->
<!--                            <span class="one-liner" style="width: 90% !important">{{item.label | translate}}</span>-->
<!--                        </ng-template>-->
<!--                    </p-multiSelect>-->
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="employeeList" [(ngModel)]="selectEmployee" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'form.dateRange' | translate}}</label>
                <div class="input-group">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-calendar-week"></i>
                        </button>
                    </div>
                    <mat-form-field >
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate matInput  [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
                            <input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" >
                        </mat-date-range-input>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'Application' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-user"></i>
                        </button>
                    </div>
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="optionsApplication" [(ngModel)]="selectApplication" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'Week/Period' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-user"></i>
                        </button>
                    </div>
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="periodList" [(ngModel)]="selectPeriod" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-12 pb-4 pt-3 text-center">
                <button type="button" class="btn btn-danger mx-1" (click)="onReset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
                <button type="button" class="btn btn-primary mx-1" (click)="onSearch()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
            </div>
        </div>
    </p-panel>
    <br>
    <p-panel id="punch-data-table" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Work Time Confirmation' | translate}} ({{dt?._totalRecords || 0 | number}})</span>
        </p-header>
        <p-table #dt [value]="employeeConfirmation" dataKey="id" [customSort]="true" [lazy]="false"
            [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,20]" [totalRecords]="totalRecords" [responsive]="true"
                 [globalFilterFields]="['fullName', 'period', 'createdAt', 'application', 'price', 'totalTicket', 'totalAmount', 'createdByUsr']">
            <ng-template pTemplate="caption">
                <div class="d-flex align-items-end justify-content-end">
                    <div class="input-group w-100 mr-2" style="max-width: 450px; float:right;">
                        <div class="input-group-prepend p-0">
                            <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                                <i class="fa-solid fa-filter"></i>
                            </button>
                        </div>
                        <input (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}" pInputText type="text">
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>{{'#' | translate}}
                    </th>
                    <th>{{'Employee Name' | translate}}
                        <span (click)="op1.toggle($event); selectedField = 'fullName';">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </th>
                    <th>{{'Period' | translate}}
                        <span (click)="op1.toggle($event); selectedField = 'period';">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </th>
                    <th>{{'Views' | translate}}
                        <span (click)="op1.toggle($event); selectedField = 'viewConfirm';">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </th>

                    <th>{{'Confirmation Date' | translate}}
                        <span (click)="op1.toggle($event); selectedField = 'createdAt';">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </th>
                    <th>{{'Signature' | translate}}
                        <span (click)="op2.toggle($event); selectedField = 'signature';">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </th>
                    <th>{{'Application' | translate}}
                        <span (click)="op2.toggle($event); selectedField = 'application';">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="header" let-columns let-i="index">
                <tr id="header-row">
                    <th>{{'#' | translate}}</th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Employee Name' | translate}}">{{'Employee Name' | translate}}</span>
                            <span appNoDblClick (click)="op1.toggle($event); selectedField = 'fullName';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Period' | translate}}">{{'Period' | translate}}</span>
                            <span appNoDblClick (click)="op1.toggle($event); selectedField = 'period';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Views' | translate}}">{{'Views' | translate}}</span>
                            <span appNoDblClick (click)="op1.toggle($event); selectedField = 'viewConfirm';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Confirmation Date' | translate}}">{{'Confirmation Date' | translate}}</span>
                            <span (click)="op4.toggle($event); selectedField = 'createdAt';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>

                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Signature' | translate}}">{{'Signature' | translate}}</span>
                            <span appNoDblClick (click)="op2.toggle($event); selectedField = 'signature';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Application' | translate}}">{{'Application' | translate}}</span>
                            <span appNoDblClick (click)="op2.toggle($event); selectedField = 'application';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
                    <td>{{i+1}}</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'FullName' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.fullName" pTooltip="{{rowData.fullName}}">{{rowData.fullName}}</span>
							<span *ngIf="!rowData.fullName" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Period' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.period" pTooltip="{{rowData.period}}">{{rowData.period}}</span>
							<span *ngIf="!rowData.period" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Views' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.viewConfirm" pTooltip="{{rowData.viewConfirm}}">{{rowData.viewConfirm}}</span>
							<span *ngIf="!rowData.period" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Confirmation Date' | translate}}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy HH:ss'}}">{{rowData.createdAt | date: 'MM/dd/yyyy HH:ss'}}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td class="p-0 px-2">
						<span class="text-truncate">
							<span class="mobile-table-label">{{'Signature' | translate}}: </span>
							<img [src]="rowData?.signatureUrl" *ngIf="rowData.signatureUrl" class="ml-2 align-self-center profile-picture m-auto cursor-pointer" (click)="viewSignatureDialog(rowData)" style="max-height:50px; max-width: 50px;border-radius: 50%"/>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Application' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.application" pTooltip="{{rowData.application}}">{{rowData.application}}</span>
							<span *ngIf="!rowData.application" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!loading && dt?._totalRecords == 0" class="text-center">
                  <td [attr.colspan]="6">
                      {{'noData' | translate}}
                  </td>
                </tr>
                <tr *ngIf="loading" class="text-center">
                  <td [attr.colspan]="5">
                      {{'.....loading.....' | translate}}
                  </td>
                </tr>
              </ng-template>
        </p-table>
    </p-panel>
<p-overlayPanel #op1 appendTo="body">
    <p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
    <p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>

<p-overlayPanel #op4 appendTo="body">
    <p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>
</p-panel>

<p-dialog #viewDocument *ngIf="showViewFileDialog" [(visible)]="showViewFileDialog" [modal]="true" id="view-document" [style]="{'width':'90%','max-width':'600px','max-hegiht':'80vh'}">
    <p-header>
        <span><i class="fa-solid fa-image mr-2"></i>{{'Signature' | translate}}</span>
    </p-header>
    <img class="w-100" [src]="fileUrl" id="fullImage">
</p-dialog>
