<div class="d-flex flex-wrap justify-content-between align-items-center pt-4" style="row-gap: 20px">
	<h1 class="m-0 ml-4" style="font-size: 30px !important">{{'CEO Dashboard' | translate}}</h1>
	<div class="d-flex align-items-center pl-4" style="zoom: 85%">
		<button class="btn mr-1" (click)="selectedType = 'today';getAllData();" [ngClass]="selectedType == 'today' ? 'btn-primary' : 'btn-white'" style="border-radius: 24px;">{{'Today' | translate}}</button>
		<button class="btn mr-1" (click)="selectedType = 'yesterday';getAllData();" [ngClass]="selectedType == 'yesterday' ? 'btn-primary' : 'btn-white'" style="border-radius: 24px;">{{'Yesterday' | translate}}</button>
		<button class="btn mr-1" (click)="selectedType = 'weekly';getAllData();" [ngClass]="selectedType == 'weekly' ? 'btn-primary' : 'btn-white'" style="border-radius: 24px;">{{'Week' | translate}}</button>
		<button class="btn mr-1" (click)="selectedType = 'month';getAllData();" [ngClass]="selectedType == 'month' ? 'btn-primary' : 'btn-white'" style="border-radius: 24px;">{{'Month' | translate}}</button>
		<button class="btn" (click)="selectedType = 'year';getAllData();" [ngClass]="selectedType == 'year' ? 'btn-primary' : 'btn-white'" style="border-radius: 24px;">{{'Year' | translate}}</button>
	</div>
</div>
<hr>
<h2 class="ml-4">{{'Overview' | translate}}</h2>
<div class="ui-g">
	<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-clock text-primary mr-2"></i>{{'Punches' | translate}}</h5>
			</div>
			<div class="p-3" style="font-size: 16px">
				<div class="py-1" (click)="openOutForAbsentDialog()" style="cursor:pointer">
					<i class="fa-solid fa-calendar-xmark text-danger mr-2"></i>
					<span><b>{{'Absent' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalAbsent > 0 ? 'badge-danger' : 'badge-muted'">{{(totalAbsent | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalAbsent > 0 ? 'text-primary' : 'text-muted'"></i></span>
				</div>
				<div class="py-1 border-top" (click)="openLateness()" style="cursor:pointer">
					<i class="fa-solid fa-clock text-orange mr-2"></i>
					<span><b>{{'Late' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalLateness > 0 ? 'badge-orange' : 'badge-muted'">{{(totalLateness | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalLateness > 0 ? 'text-primary' : 'text-muted'"></i></span>
				</div>
				<div class="py-1 border-top" (click)="openOutForLunchDialog()" style="cursor:pointer">
					<i class="fa-solid fa-utensils text-primary mr-2"></i>
					<span><b>{{'Break' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalOutForLunch > 0 ? 'badge-primary' : 'badge-muted'">{{(totalOutForLunch | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalOutForLunch > 0 ? 'text-primary' : 'text-muted'"></i></span>
				</div>
				<div class="py-1 border-top" (click)="openNotLunchDialog()" style="cursor:pointer">
					<i class="fa-solid fa-triangle-exclamation text-danger mr-2"></i>
					<span><b>{{'Missed Break' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalNotLunch > 0 ? 'badge-danger' : 'badge-muted'">{{(totalNotLunch | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalNotLunch > 0 ? 'text-primary' : 'text-muted'"></i></span>
				</div>
				<div class="py-1 border-top" (click)="openOutForClockInDialog()" style="cursor:pointer">
					<i class="fa-solid fa-circle-check text-success mr-2"></i>
					<span><b>{{'Working' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalClockedIn > 0 ? 'badge-success' : 'badge-muted'">{{(totalClockedIn | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalClockedIn > 0 ? 'text-primary' : 'text-muted'"></i></span>
				</div>
			</div>
		</div>
	</div>

	<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-briefcase text-primary mr-2"></i>{{'Clients' | translate}}</h5>
			</div>
			<div class="p-3" style="font-size: 16px">
				<div class="py-1" [routerLink]="['/app/crm/client/list']" style="cursor:pointer">
					<i class="fa-solid fa-handshake text-warning mr-2"></i>
					<span><b>{{'Clients' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="clientsCount > 0 ? 'badge-primary' : 'badge-muted'">{{(clientsCount | number) || 0}}</span><i class="fa-solid fa-chevron-right text-primary ml-2"></i></span>
				</div>
				<div class="py-1 border-top" style="cursor:pointer">
					<i class="fa-solid fa-circle-info text-primary mr-2"></i>
					<span><b>{{'Leads' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="(totalUserLeads + totalPtClient) > 0 ? 'badge-primary' : 'badge-muted'">{{((totalUserLeads + totalPtClient) | number) || 0}}</span><i class="fa-solid fa-chevron-right text-muted ml-2"></i></span>
				</div>
				<div class="py-1 border-top" (click)="showContract()" style="cursor:pointer">
					<i class="fa-solid fa-file-pen text-purple mr-2"></i>
					<span><b>{{'Contracts' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalContractSigned > 0 ? 'badge-primary' : 'badge-muted'">{{(totalContractSigned | number) || 0}}</span><i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
				</div>
				<div class="py-1 border-top" (click)="openPaymentDetailPopup('cash_in')" style="cursor:pointer">
					<i class="fa-solid fa-circle-down text-success mr-2"></i>
					<span><b>{{'Credits' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalCashIn > 0 ? 'badge-success' : 'badge-muted'">{{(totalCashIn | currency : 'USD':'symbol':'1.2-2') || 0}}</span><i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
				</div>
				<div class="py-1 border-top" (click)="openPaymentDetailPopup('cash_out')" style="cursor:pointer">
					<i class="fa-solid fa-circle-up text-danger mr-2"></i>
					<span><b>{{'Debits' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalCashOut > 0 ? 'badge-danger' : 'badge-muted'">{{(totalCashOut | currency : 'USD':'symbol':'1.2-2') || 0}}</span><i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
				</div>
			</div>
		</div>
	</div>

	<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-clipboard-check text-primary mr-2"></i>{{'Task Management' | translate}}</h5>
			</div>
			<div class="p-3" style="font-size: 16px">
				<div class="py-1" [routerLink]="['/app/workorder/list']" style="cursor:pointer">
					<i class="fa-solid fa-archive text-warning mr-2"></i>
					<span><b>{{'Work Orders' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="taskManagementNumber > 0 ? 'badge-primary' : 'badge-muted'">{{(taskManagementNumber | number) || 0}}</span><i class="fa-solid fa-chevron-right text-primary ml-2"></i></span>
				</div>
				<div class="py-1 border-top" style="cursor:default">
					<i class="fa-solid fa-clipboard text-primary mr-2"></i>
					<span><b>{{'Current' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="numOfCurrentTask > 0 ? 'badge-primary' : 'badge-muted'">{{(numOfCurrentTask | number) || 0}}</span><i class="fa-solid fa-chevron-right text-muted ml-2"></i></span>
				</div>
				<div class="py-1 border-top" style="cursor:default">
					<i class="fa-solid fa-triangle-exclamation text-orange mr-2"></i>
					<span><b>{{'Overdue' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="numOfOverDueTask > 0 ? 'badge-orange' : 'badge-muted'">{{(numOfOverDueTask | number) || 0}}</span><i class="fa-solid fa-chevron-right text-muted ml-2"></i></span>
				</div>
				<div class="py-1 border-top" style="cursor:default">
					<i class="fa-solid fa-clipboard-check text-success mr-2"></i>
					<span><b>{{'Finished Work' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="numOfCompletedTaskToday > 0 ? 'badge-success' : 'badge-muted'">{{(numOfCompletedTaskToday | number) || 0}}</span><i class="fa-solid fa-chevron-right text-muted ml-2"></i></span>
				</div>
				<div class="py-1 border-top" [routerLink]="['/app/tm']" style="cursor:pointer">
					<i class="fa-solid fa-circle-check text-success mr-2"></i>
					<span><b>{{'Tickets Done' | translate}}</b> </span>
					<span class="pull-right"><span class="badge" [ngClass]="totalCompletedTicketCard > 0 ? 'badge-success' : 'badge-muted'">{{(totalCompletedTicketCard | number) || 0}}</span><i class="fa-solid fa-chevron-right text-primary ml-2"></i></span>
				</div>
		
			</div>
		</div>
	</div>

	<div class="ui-g-6 ui-sm-12" style="padding: 15px !important">
		<h2 class="mb-5 ml-2 mt-3">{{'Activity' | translate}}</h2>
		<div class="card add-shadow text-left p-0">
			<div *ngIf="!viewMonitoring" class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-chart-bar text-primary mr-2"></i>{{'Overtime Hours' | translate}}</h5>
			</div>
			<div *ngIf="viewMonitoring" class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-computer text-primary mr-2"></i>{{'User Acitivty' | translate}}</h5>
			</div>
			<div *ngIf="!viewMonitoring" class="p-3" style="font-size: 16px">
				<p-chart height="323" type="line" [data]="chartData" responsive="true" [options]="chartOptions" (onDataSelect)="selectData($event)"></p-chart>
			</div>
			<div *ngIf="viewMonitoring" class="p-0">
				<p-table *ngIf="viewMonitoring" #dt [value]="monitorDetails" dataKey="id" selectionMode="single" [rows]="7" [paginator]="true" id="workOrdersTable">
					<ng-template pTemplate="header">
						<tr>
<!--
							<th>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{'Started' | translate}}" tooltipPosition="left">{{'Started' | translate}}</span>
								</div>
							</th>
-->
							<th style="width: 75%  !important">
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{'Activity' | translate}}" tooltipPosition="left">{{'Details' | translate}}</span>
								</div>
							</th>
							<th>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{'Timestamp' | translate}}" tooltipPosition="left">{{'Timestamp' | translate}}</span>
								</div>
							</th>
<!--
							<th>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{'action_table' | translate}}" tooltipPosition="left">{{'action_table' | translate}}</span>
								</div>
							</th>
-->
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr [pSelectableRow]="rowData">
<!--
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'User' | translate}}: </span>
									<span *ngIf="rowData.startedByName" pTooltip="{{rowData.startedByName}}">{{rowData.startedByName}}</span>
									<span *ngIf="!rowData.startedByName" class="text-muted">{{'no data' | translate}}</span>
								</span>
							</td>
-->
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Activity' | translate}}: </span>
									<span *ngIf="rowData.argumentsForReport"><i class="fa-solid fa-circle-info text-primary mr-2" pTooltip="{{rowData.action}}"></i>{{rowData.argumentsForReport}}</span>
									<span *ngIf="!rowData.argumentsForReport" class="text-muted">{{'no data' | translate}}</span>
								</span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Timestamp' | translate}}: </span>
									<span *ngIf="rowData.openAt" pTooltip="{{rowData.openAt ? rowData.openAt : (rowData.requestStartTime | date:'MM/dd/yyyy HH:mm:ss')}}">{{rowData.openAt ? rowData.openAt : (rowData.requestStartTime | date:'MM/dd/yyyy HH:mm:ss')}}</span>
									<span *ngIf="!rowData.openAt" class="text-muted">{{'no data' | translate}}</span>
								</span>
							</td>
<!--
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Actions' | translate}}: </span>
									<span *ngIf="rowData.action" pTooltip="{{rowData.action}}" tooltipPosition="left">{{rowData.action}}</span>
									<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
								</span>
							</td>
-->
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage" let-columns>
						<tr *ngIf="!loading">
							<td [attr.colspan]="2" class="text-center py-5">
								<div class="text-muted my-5" style="margin: 60px auto !important;">{{'no data' | translate}}</div>
							</td>
						</tr>
						<tr *ngIf="loading">
							<td [attr.colspan]="2" class="text-center py-5">
								<div class="text-muted my-5" style="margin: 60px auto !important;"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-white btn-sm mx-1" (click)="this.viewOvertime = true; this.viewMonitoring = false;">
					<span class="text-nowrap">
						<i *ngIf="viewMonitoring" class="fa-solid fa-up-right-from-square text-primary mr-2"></i>
						<i *ngIf="!viewMonitoring" class="fa-solid fa-circle-check text-success mr-2"></i>
						{{'Overtime' | translate}}
					</span>
				</button>
				<button type="button" class="btn btn-white btn-sm mx-1"  (click)="this.viewMonitoring = true; this.viewOvertime = false;">
					<span class="text-nowrap">
						<i *ngIf="!viewMonitoring" class="fa-solid fa-up-right-from-square text-primary mr-2"></i>
						<i *ngIf="viewMonitoring" class="fa-solid fa-circle-check text-success mr-2"></i>
						{{'User Activity' | translate}}
					</span>
				</button>
			</div>
		</div>
	</div>
	<div class="ui-g-6 ui-sm-12" style="padding: 15px !important">
		<h2 class="mb-5 ml-2 mt-3">Work Overview</h2>
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<div *ngIf="jobReportsArea" class="d-flex align-items-center justify-content-between">
					<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="jobReports.length > 0 ? 'badge-primary' : 'badge-muted'">{{jobReports.length || 0 | number}}</span>{{'Latest Job Reports' | translate}} </h5>
				</div>
				<div *ngIf="workOrdersArea" class="d-flex align-items-center justify-content-between">
					<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="planAssigmentList.length > 0 ? 'badge-primary' : 'badge-muted'">{{planAssigmentList.length || 0 | number}}</span>{{'Work Orders' | translate}} </h5>
				</div>
			</div>
			<ng-container *ngIf="jobReportsArea">
				<div  *ngIf="(jobReports && (jobReports.length > 0))  && !loadingJobReports" class="p-0 bg-darkgray" id="jobReportsList" style="font-size: 16px;">
					<ng-container *ngFor="let report of jobReports">
						<div class="d-flex align-items-center justify-content-between bg-white px-3 py-2 border-bottom" (click)="gotoJobReport()" id="concernsItem" style="cursor:pointer">
							<span class="d-flex align-items-center flex-grow-1 justify-content-start text-truncate">
								<i class="fa-solid fa-file-pen text-primary m-2" style="width:30px;font-size: 26px"></i>
								<div class="flex-grow-1 text-truncate pl-2" style="line-height: 1.2">
									<div class="text-muted text-nowrap" style="font-size: 12px">{{report.fullName || 'no data'}}</div>
									<div class="m-0 text-truncate" id="jobReportItemText" [innerHTML]="report.latestNote" style="max-height: 1.15em"></div>
								</div>						
							</span>
							<div class="text-muted text-nowrap ml-2" style="font-size: 12px">{{report.time}}<i class="fa-solid fa-circle-info text-primary ml-2" pTooltip="{{report.createdAt | date: 'M/d/yyy h:mm a'}}" tooltipPosition="left"></i></div>
						</div>						
					</ng-container>
				</div>
				<div *ngIf="(!jobReports || (jobReports.length === 0)) && !loadingJobReports" class="d-flex align-items-center justify-content-center bg-darkgray py-5" style="font-size: 16px;min-height: 354px">
					<div class="text-muted my-5">{{'No reports found' | translate}}</div>
				</div>
				<div *ngIf="loadingJobReports" class="d-flex align-items-center justify-content-center bg-darkgray" style="height: 353px">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</div>
			</ng-container>
			<ng-container *ngIf="workOrdersArea">
				<div *ngIf="(planAssigmentList && (planAssigmentList.length > 0))  && !loadingJobReports" class="p-0 bg-darkgray" id="jobReportsList" style="font-size: 16px;">
					<ng-container *ngFor="let rowData of planAssigmentList">
						<div class="d-flex align-items-center justify-content-between bg-white px-3 py-2 border-bottom" id="concernsItem" style="cursor:pointer">
							<span class="d-flex align-items-center flex-grow-1 justify-content-start text-truncate">
								<i class="fa-solid fa-file-circle-plus m-2" [style.color]="rowData.color" style="width:30px;font-size: 26px"></i>
								<div class="flex-grow-1 text-truncate pl-2" style="line-height: 1.2">
									<div class="text-muted text-nowrap" style="font-size: 12px">{{rowData.scheduleTo || 'no data'}}</div>
									<div class="m-0 text-truncate" id="jobReportItemText" style="max-height: 1.15em">{{rowData.todo}}</div>
								</div>						
							</span>
							<div class="text-nowrap ml-2"><span class="badge" [style.background-color]="rowData.color" style="font-size: 14px">{{rowData.label}}</span><i class="fa-solid fa-circle-info text-primary ml-2" pTooltip="{{rowData.updatedAt | date: 'MM/dd/yyy hh:mm a'}}" tooltipPosition="left" style="font-size: 14px"></i></div>
						</div>						
					</ng-container>
				</div>
				<div *ngIf="(!planAssigmentList || (planAssigmentList.length === 0)) && !loadingJobReports" class="d-flex align-items-center justify-content-center bg-darkgray py-5" style="font-size: 16px;min-height: 354px">
					<div class="text-muted my-5">{{'No reports found' | translate}}</div>
				</div>
				<div *ngIf="loadingJobReports" class="d-flex align-items-center justify-content-center bg-darkgray" style="height: 353px">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</div>
			</ng-container>

			<div class="card-footer d-flex flex-wrap" style="row-gap: 10px">
				<button type="button" class="btn btn-white btn-sm mx-1" (click)="workOrdersArea = false;jobReportsArea = true;">
					<span class="text-nowrap">
						<i *ngIf="!jobReportsArea" class="fa-solid fa-up-right-from-square text-primary mr-2"></i>
						<i *ngIf="jobReportsArea" class="fa-solid fa-circle-check text-success mr-2"></i>
						Job Reports
					</span>
				</button>
				<button *ngIf="jobReportsArea" class="btn btn-white btn-sm mx-1" (click)="openJobReportSummaryDialog('miss')">
					<span class="text-nowrap">
						<span class="badge mr-2" [ngClass]="totalMissJobReport > 0 ? 'badge-orange' : 'badge-muted'">{{totalMissJobReport}}</span>{{'Missed' | translate}}
					</span>
				</button>
				<button *ngIf="jobReportsArea" class="btn btn-white btn-sm mx-1" (click)="openJobReportSummaryDialog('limit')">
					<span class="text-nowrap">
						<span class="badge mr-2" [ngClass]="totalMissJobReport > 0 ? 'badge-danger' : 'badge-muted'">{{totalMissThanLimit}}</span>{{'Over Limit' | translate}}
					</span>
				</button>
				<button type="button" class="btn btn-white btn-sm mx-1" (click)="workOrdersArea = true;jobReportsArea = false;">
					<span class="text-nowrap">
						<i *ngIf="!workOrdersArea" class="fa-solid fa-up-right-from-square text-primary mr-2"></i>
						<i *ngIf="workOrdersArea" class="fa-solid fa-circle-check text-success mr-2"></i>Work Orders
					</span>
				</button>
			</div>
		</div>
	</div>
	
	<div class="ui-g-12" style="padding: 15px !important">
		<h2 class="mb-3 ml-2 mt-3">{{'Invoices & Payments' | translate}}</h2>
	</div>
	<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="mb-0 text-truncate"><span class="badge m-0 mr-2" [ngClass]="invoices?.length > 0 ? 'badge-primary' : 'badge-muted'">{{(invoices?.length | number) || 0}}</span>{{'Invoices' | translate}} </h5>
				</div>
			</div>
			<div *ngIf="invoices" class="p-0" style="font-size: 16px;height: 273px;background: #f0f0f0;">
				<ng-container *ngFor="let invoice of displayedInvoices">
					<div class="d-flex align-items-center justify-content-between bg-white px-3 border-bottom" id="invoiceItem" (click)="exportPdf(rowData)" style="cursor: pointer">
						<span class="d-flex align-items-center justify-content-start text-truncate">
							<span style="min-width:80px">
								<div *ngIf="invoice.feeTxt && invoice.color == 'orange'" class="badge badge-orange text-truncate" pTooltip="{{invoice.invoiceNumber}}">{{invoice.invoiceNumber}}</div>
								<div *ngIf="invoice.feeTxt && invoice.color == 'red'" class="badge badge-danger text-truncate" pTooltip="{{invoice.invoiceNumber}}">{{invoice.invoiceNumber}}</div>
								<div *ngIf="invoice.feeTxt && invoice.color == 'green'" class="badge badge-success text-truncate" pTooltip="{{invoice.invoiceNumber}}">{{invoice.invoiceNumber}}</div>
							</span>
							<div class="flex-grow-1 text-truncate" style="line-height: 1.1">
								<div class="text-muted text-nowrap text-truncate ml-2" style="font-size: 10px">{{'Due' | translate}} {{invoice.dueDate | date: 'MM/dd/yyyy'}}</div>
								<div class="m-0 ml-2 text-truncate">{{invoice.clientName || 'Untitled'}}</div>
							</div>
						</span>
						<div class="d-flex">
							<b *ngIf="invoice.feeTxt">{{invoice.feeTxt | currency : 'USD':'symbol':'1.2-2'}}</b>
						</div>
					</div>
				</ng-container>
			</div>
			<div *ngIf="!invoices" class="p-0 d-flex align-items-center justify-content-center" style="font-size: 16px;height: 273px;background: #f0f0f0;">
				<span class="text-muted text-truncate">{{'No outstanding invoices' | translate}}</span>
			</div>
			<div class="card-footer">
				<button type="button" *ngIf="currentIndex > 0" (click)="previousPage()" class="btn btn-white btn-sm mr-2">
				  <span class="text-nowrap"><i class="fa-solid fa-chevron-left text-primary mr-2"></i>{{'Previous' | translate}}</span>
				</button>
				<button type="button" [disabled]="currentIndex + pageSize >= invoices.length" (click)="nextPage()" class="btn btn-white btn-sm">
				  <span class="text-nowrap"><i class="fa-solid fa-chevron-right text-primary mr-2"></i>{{'More' | translate}}</span>
				</button>
			  </div>
		</div>
	</div>
	<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-chart-simple text-primary mr-2"></i>{{'Outstanding' | translate}}</h5>
			</div>
			<div class="p-3" style="font-size: 16px">
				<p-chart height="296" type="bar" [data]="data" [options]="options"></p-chart>
			</div>
		</div>
	</div>
	<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
		<div class="card add-shadow text-left p-0">
			<div class="card-header">
				<h5 class="mb-0 text-truncate"><i class="fa-solid fa-chart-pie text-primary mr-2"></i>{{'Payments' | translate}}</h5>
			</div>
			<div class="p-3" style="font-size: 16px">
				<p-chart height="296" type="pie" [data]="dataPie"></p-chart>
			</div>
		</div>
	</div>
</div>


<p-dialog [(visible)]="isPopupOutForLunch" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}" id="employeeTotalsModal">
	<p-header>
		<span>
			<i *ngIf="modalHeaderTitle === 'Absent'" class="fa-solid fa-calendar-xmark mr-2"></i>

			<div *ngIf="modalHeaderTitle === 'Break' && totalOutForLunchCount > 0" class="badge badge-info mr-2">{{totalOutForLunchCount}}</div>
			<i *ngIf="modalHeaderTitle === 'Break'" class="fa-solid fa-utensils mr-2"></i>
			
			<div *ngIf="modalHeaderTitle === 'Working' && totalOutForLunchCount > 0" class="badge badge-success mr-2">{{totalOutForLunchCount}}</div>
			<i *ngIf="modalHeaderTitle === 'Working' && (!totalOutForLunchCount || totalOutForLunchCount === 0)" class="fa-solid fa-house-circle-check mr-2"></i>

			<div *ngIf="modalHeaderTitle === 'Missed Break' && totalOutForLunchCount > 0" class="badge badge-danger mr-2">{{totalOutForLunchCount}}</div>
			<i *ngIf="modalHeaderTitle === 'Missed Break' && (!totalOutForLunchCount || totalOutForLunchCount === 0)" class="fa-solid fa-triangle-exclamation mr-2"></i>

			<div *ngIf="modalHeaderTitle === 'Online' && totalOutForLunchCount > 0" class="badge badge-danger mr-2">{{totalOutForLunchCount}}</div>
			<i *ngIf="modalHeaderTitle === 'Online' && (!totalOutForLunchCount || totalOutForLunchCount === 0)" class="fa-solid fa-triangle-exclamation mr-2"></i>

			{{modalHeaderTitle | translate}} {{popupOutHeader}}
		</span>
	</p-header>
	<div *ngIf="(popupIndex === 3)" class="d-flex justify-content-between border-bottom w-100 px-1 py-2">
		<span class="d-flex">
			<button class="btn mx-1" [ngClass]="userTable ? 'btn-primary' : 'btn-white'" (click)="leaveTable = false;userTable = true" style="border-radius: 25px"><span class="badge mr-1" [ngClass]="userTable ? 'badge-danger' : 'badge-danger'">{{employeeDisplayData.length || 0}}</span>{{'Absent' | translate}}</button>
			<button class="btn mx-1" [ngClass]="leaveTable ? 'btn-primary' : 'btn-white'" (click)="leaveTable = true;userTable = false" style="border-radius: 25px"><span class="badge mr-1" [ngClass]="leaveTable ? 'badge-light' : 'badge-primary'">{{employeeAbsentDisplayData.length || 0}}</span>{{'Time-Off Requests' | translate}}</button>	
		</span>
		<button *ngIf="userTable || leaveTable" class="btn mx-1" [ngClass]="absentFilter ? 'btn-primary' : 'btn-white'" (click)="this.absentFilter = !this.absentFilter" style="border-radius: 25px"><i class="fa-solid fa-magnifying-glass" [ngClass]="absentFilter ? '' : 'text-primary'"></i></button>
	</div>
	<ng-container *ngIf="(popupIndex === 3) && userTable">
		<div *ngIf="absentFilter" class="p-2 border-bottom">
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-magnifying-glass"></i>
					</button>
				</div>
				<input type="text" size="35" pInputText [(ngModel)]="textSearch" (input)="onSearchChange($event?.target?.value)" placeholder="{{'Search by Name' | translate}}" style="height: 40px !important">
			</div>
		</div>

		<div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index" (click)="gotoEmployeePage(rowData?.employeeId)" class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white" id="concernsItem" style="cursor:pointer">
			<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
			<div style="line-height: 1" class="py-1 text-truncate">
				<h5 *ngIf="rowData.fullName" class="m-0 text-nowrap">{{rowData.fullName}}</h5>
				<h5 *ngIf="!rowData.fullName" class="m-0 text-nowrap text-muted">{{'no data' | translate}}</h5>
			</div>
			<span class="align-items-center flex-grow-1 text-right">
			  	<i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
			</span>
		</div>
		<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
			<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
			<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
		</div>
		
	</ng-container>
	<ng-container *ngIf="(popupIndex === 3) && leaveTable" >
		<div *ngIf="absentFilter" class="p-2 border-bottom">
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-magnifying-glass"></i>
					</button>
				</div>
				<input type="text" size="35" pInputText [(ngModel)]="textSearch" (input)="onSearchChange($event?.target?.value)" placeholder="{{'Search by Name' | translate}}" style="height: 40px !important">
			</div>
		</div>

		<div *ngFor="let rowData of employeeAbsentDisplayData; let i = index"  (click)="gotoEmployeePage(rowData?.employeeId)" class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white" id="concernsItem" style="cursor:pointer">
			<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
			<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
				<h5  class="m-0 text-nowrap">{{rowData.fullName || 'Untitled'}}</h5>
			</div>
			<span class=" align-items-center flex-grow-1 text-right">
				<i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
			</span>
		</div>
		<div *ngIf="employeeAbsentDisplayData.length === 0" class="text-center py-5 bg-light">
			<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
			<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
		</div>
	</ng-container>
	<ng-container *ngIf="popupIndex !== 3" >
		<div *ngFor="let rowData of employeeDisplayData; let i = index" (click)="gotoEmployeePage(rowData?.employeeId)" class="d-flex align-items-center justify-content-between p-2 border-bottom bg-white" id="concernsItem" style="cursor:pointer">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
				<div class="d-flex flex-column justify-column-start">				
					<h5 style="cursor: pointer" class="m-0 text-nowrap text-truncate">{{rowData.fullName}}</h5>
					<div class="d-flex" style="column-gap: 10px">
						<span *ngIf="rowData.timeIn"><b>{{'In' | translate}}: </b><span [ngClass]="!rowData.timeIn ? 'text-muted' :''">{{rowData.timeIn || 'no data'}}</span></span>
						<span *ngIf="rowData.timeOut"><b>{{'Out' | translate}}: </b><span [ngClass]="!rowData.timeOut ? 'text-muted' :''">{{rowData.timeOut || 'no data'}}</span></span>
					</div>
				</div>
			</div>
			<span class=" align-items-center flex-grow-1 text-right">
				<i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
			</span>
		</div>
		<div *ngIf="employeeDisplayData.length === 0" class="text-center py-5 bg-light">
			<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
			<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
		</div>
	</ng-container>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="isPopupOutForLunch = false;textSearch = null"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [showHeader]="true" *ngIf="showModalContract" [(visible)]="showModalContract" [modal]="true" [dismissableMask]="true" showEffect="fade" class="deparment-history" id="contractsModal" [style]="{'width':'90%', 'max-width':'900px','max-height':'80vh'}">
	<p-header>
		<span><i class="fa-solid fa-file-pen mr-2"></i>{{'Contracts' | translate}}</span>
	</p-header>
	<app-client-contract-list-dashboard [startDate]="startOfWeek" [endDate]="endOfWeek"></app-client-contract-list-dashboard>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showModalContract = false;"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showFullNote" [header]="'Note'" [responsive]="true" baseZIndex="99999" modal="modal">
	<div class="ui-g" style="width: fit-content; height: fit-content; max-width: 80vw; max-height: 80vh; min-width: 600px; min-height: 500px;">
		<div class="ui-g-12">
			<p style="overflow-wrap: break-word" [innerHTML]="latestNote"></p>
		</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="isPopupJobReportSummary" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}" id="jobReportsTableModal">
	<p-header>
		<span *ngIf="popupIndexSummary === 1">
			<i class="fa-solid fa-circle-exclamation mr-2"></i> {{'Missed Job Reports' | translate}} ({{popupJobReportSummaryData.length || 0 | number}})
		</span>
		<span *ngIf="popupIndexSummary === 2">
			<i class="fa-solid fa-circle-exclamation mr-2"></i> {{'Missed Too Many' | translate}} ({{popupJobReportSummaryData.length || 0 | number}})
		</span>
	</p-header>

	<div *ngFor="let rowData of popupJobReportSummaryData" class="d-flex align-items-center justify-content-between p-2 border-bottom bg-white px-3" style="cursor:pointer">
		<div class="d-flex align-items-center">
			<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
			<div class="py-1 text-truncate" style="line-height: 1">
				<h5 class="m-0 text-nowrap">{{rowData.name || 'Untitled'}}</h5>
			</div>
		</div>
		<span class="d-flex align-items-center">
			<span class="badge" [ngClass]="popupIndexSummary === 1 ? 'badge-orange' : 'badge-danger'" pTooltip="{{'Times Missed' | translate}}" tooltipPosition="left" style="font-size: 14px">{{rowData.missedReport || '0'}}</span>
			<i class="fa-solid fa-chevron-right text-muted ml-3" style="font-size: 20px"></i>
		</span>
	</div>
	  
	<div *ngIf="popupJobReportSummaryData.length === 0 && !loadingTable" class="text-center py-5">
		<div class="text-muted my-5">{{'no data' | translate}}</div>
	</div>
	<div *ngIf="loadingTable" class="text-center py-5">
		<div class="text-muted my-5">
		  <i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}
		</div>
	</div>
	
	<p-footer class="d-flex justify-content-between">
		<span>
			<button *ngIf="popupIndexSummary === 2" class="btn btn-white" (click)="changeTypeJobReport('miss');"><i  class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'View Missed' | translate}}</button>
			<button *ngIf="popupIndexSummary === 1" class="btn btn-white" (click)="changeTypeJobReport('limit');"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'Missed Too Many' | translate}}<span class="badge ml-2" [ngClass]="totalMissJobReport > 0 ? 'badge-danger' : 'badge-muted'">{{totalMissThanLimit}}</span></button>
		</span>
		<button class="btn btn-primary" (click)="isPopupJobReportSummary = false;"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="isPopupLateness" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}" id="lateNessTable">
	<p-header>
		<span *ngIf="popupLatenessData.length > 0" class="badge badge-orange mr-2">{{popupLatenessData.length || 0 | number}}</span>
		<i *ngIf="popupLatenessData.length === 0 || !popupLatenessData" class="fa-solid fa-triangle-exclamation mr-2"></i> {{'Late' | translate}}
	</p-header>
	<div *ngFor="let rowData of popupLatenessData; let i = index" (click)="gotoEmployeePage(rowData?.employeeId)" class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white" id="concernsItem" style="cursor: pointer">
		<div class="d-flex align-items-center">
			<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
			<h5 *ngIf="rowData.fullName" style="cursor: pointer" class="m-0 text-nowrap text-truncate py-1">{{rowData.fullName}}</h5>
		</div>
		<span class=" align-items-center flex-grow-1 text-right">
			<i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
		</span>
	</div>
	<div *ngIf="popupLatenessData.length === 0" class="text-center py-5 bg-light">
		<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="isPopupLateness = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>


<p-dialog [(visible)]="showPaymentDetailPopup" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'600px', 'max-height':'80vh'}">
	<p-header>
		<i class="fa-solid fa-dollar-sign mr-2"></i> {{'Payments' | translate}}
	</p-header>
	<p-chart type="pie" [data]="pieChartByPaymentMethodData" [options]="pieChartOption"></p-chart>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showPaymentDetailPopup = false"><i class="fa-solid fa-check mr-2"></i>{{'Close' | translate}}</button>
	</p-footer>
</p-dialog>
